import React, { useState, useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import SliderCompny from "../../components/SliderCompny";
import AddNewCompany from "../../components/AddNewCompany";
import "./style.css";
import { fetchUserCompanies } from "../../redux/actions/UserAction";
import { useDispatch, connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { IS_CLIENT_VIEW, SELECTED_COMPANY_SELECTION_SUCCESS } from "../../redux/actions/Types";
import { ADMIN, CLIENT, GROUPADMIN, OWNER } from "../../utils/Constants";
import { allowedBasedOnRole } from "../../common/GlobalFunctions";
import { logout } from "../../redux/actions/AuthAction";

const CompanySelection = (props) => {
  const { userCompanies, selectedCompany } = props;

  const [loading, setLoading] = useState(false);
  const [continu, setContinu] = useState(true);
  const [showAddCompany, setShowAddCompany] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    functionFetchUserCompanies();
  }, []);

  function functionFetchUserCompanies() {
    setLoading(true);
    dispatch(fetchUserCompanies())
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }

  function onCompanySelection(selectedCompanyFromChildComp) {
    setContinu(false);

    dispatch({
      type: SELECTED_COMPANY_SELECTION_SUCCESS,
      payload: { selectedCompany: selectedCompanyFromChildComp },
    });

    // dispatch({
    //   type: IS_CLIENT_VIEW,
    //   payload: { isEnabled: true },
    // });
  }

  const openModel = () => {
    setShowModel(!showModel);
  };

  const AddNewCompanyModel = () => {
    setShowAddCompany(true);
    setShowModel(true);
  };

  const onLogOut = () => {
    dispatch(logout());
  };

  return (
    <>
      <div className="home-page px-3">
        <Button
          className="btn-logout text-center"
          onClick={() => {
            onLogOut();
          }}
        >
          Sign out
        </Button>
        <img className="before" src={"images/login-before.png"} />
        <div className="center-box mt-4 mt-md-0">
          <div className="logo">
            <img src={"images/TimebugLogoOnly.svg"} />
          </div>

          {userCompanies.length > 0 ? (
            <div className="is-company">
              <div className="text">
                <h1 className=" m-0 mb-3">
                  Choose Your Organization to Access its TimeBee Dashboard
                </h1>
              </div>
              <Container>
                {loading ? (
                  <div className="text-center loader my-4 pt-1">
                    <span className="spinner-border spinner-border-sm white">
                      &nbsp;
                    </span>
                  </div>
                ) : (
                  <>
                    <SliderCompny
                      userCompanies={userCompanies}
                      onCompanySelection={onCompanySelection}
                    />
                  </>
                )}
                <p className="text-white mb-3">
                  By accessing the TimeBee dashboard, you accept TimeBee's Terms
                  of Use and Privacy Policy{" "}
                </p>

                {/* <p className='text-white mb-3'>By accessing the TimeBee dashboard, you agree to accept TimeBee's <a style={{ color: "white" }} href="https://timebee.app/terms-of-service/">Terms</a> of Use and <a style={{ color: "white" }} href="https://timebee.app/privacy-policy/">Privacy Policy.</a> </p> */}
                {/* allowedBasedOnRole(
                          [ADMIN, OWNER, GROUPADMIN, CLIENT],
                          selectedCompany.userRole
                        ) === true
                          ? "/team-dashboard"
                          : "/user-dashboard" */}
                {!continu && (
                  <>
                    <Link
                      to={"/"}
                      className="continue-btn text-black"
                    >
                      CONTINUE
                    </Link>
                  </>
                )}
                <button
                  className="continue-btn create-btn text-white mt-3 mb-5"
                  onClick={AddNewCompanyModel}
                >
                  <span className="me-2 gg">+</span> CREATE ORGANIZATION
                </button>
              </Container>
            </div>
          ) : (
            <div className="no-company">
              <div className="text">
                <h1 className=" m-0">Welcome to TimeBee!</h1>
                <p className="font-size-16 mb-5">
                  Select one of the following to get started.
                </p>
              </div>
              <div className="text">
                <h2 className="m-0 mb-2">Create your organization</h2>
                {/* <Link className='continue-btn text-black' >CONTINUE</Link> */}
                <button
                  className="continue-btn create-btn text-white mt-3"
                  onClick={AddNewCompanyModel}
                >
                  <span className="me-2">+</span> CREATE ORGANIZATION
                </button>
                <h3 className="my-4 text-white">OR</h3>
                {/* <p>Join an organization as an employee
                  <br />Cehck your email inbox for an invite from TimeBee Support to join your team.</p>
                <p>Remember to check your spam folder as well. Don't see the invite
                  <br />in  your inbox? Contact your administrator</p> */}
                <p>
                  Check your email inbox for an invite from your organization to
                  join your team.
                </p>
              </div>
            </div>
          )}
          <div className="linkss d-flex justify-content-center mt-5">
            <a
              target="_blank"
              style={{ color: "white" }}
              href="https://timebee.app/contact-us/"
            >
              Contact Support
            </a>
            <span style={{ color: "white" }} className="mx-2">
              |
            </span>
            <a
              target="_blank"
              style={{ color: "white" }}
              href="https://timebee.app/privacy-policy/"
            >
              Privacy Policy
            </a>
            <span style={{ color: "white" }} className="mx-2">
              |
            </span>
            <a
              target="_blank"
              style={{ color: "white" }}
              href="https://timebee.app/terms-of-service/"
            >
              Terms of Services
            </a>
          </div>
        </div>

        <img className="after" src={"images/login-after.png"} />
      </div>
      {showAddCompany && (
        <AddNewCompany
          show={showModel}
          handleClose={openModel}
          setShowAddCompany={setShowAddCompany}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  userCompanies: state.UserReducer.userCompanies,
  selectedCompany: state.UserReducer.selectedCompany,
});

export default connect(mapStateToProps)(CompanySelection);
