import React from 'react'
import { Button, Col, Row } from 'react-bootstrap';
import ProjectChart from '../ReCharts/ProjectChart';
import CircularProgressBar from '../CircularProgressBar';
import './style.css';
import { timeFormat } from '../../common/GlobalFunctions'
import CustomToolTip from '../CustomToolTip';
import Loading from '../loading';
import { Link, useNavigate } from 'react-router-dom';


const TopProjects = (props) => {

  const { loading, dashboardTopProjects, dashboardDateRange } = props;
  const navigate = useNavigate();

  function totalSeconds() {
    return Math.floor(dashboardTopProjects.projects.reduce((n, obj) => n + obj.timeTracked, 0));
  }

  const handleClickNavigate = () => {
    const params = new URLSearchParams({
      date: dashboardDateRange.dateRange,
      label: dashboardDateRange.label
    });

    navigate(`/project-hours?${params.toString()}`);
  };

  return (
    <>
      <div className={"top-projects-box toltip-right bg-white my-4 mb-0 py-4 px-4 box-shadow-light overflow-inherit more" + (loading ? " skeleton1 " : "")}>
        {loading ?
          <div className="text-center loader my-4 pt-1 height-min">
            <Loading />
          </div>
          : <>
            <div className='heading'>
              <h3 className='heading d-flex heading-font align-items-center'>Top 5 Projects
                <CustomToolTip iconColor="black" details="Projects with the most tracked hours." /></h3>
            </div>
            <Row className='m-0'>
              <Col className='box first p-0 mb-4 mb-0 mb-sm-0 mb-lg-0' md={12} lg={8}>
                <ProjectChart dataArray={dashboardTopProjects.projects} nameKey={"name"} key1={"timeTracked"} />
              </Col>
              <Col className='box p-0 d-sm-flex justify-content-center align-items-center position-relative' md={12} lg={4}>
                <div className='total-time'>
                  <h4 className='fs-16 fs-lg-22  text-black font-bold m-0 mb-xxl-2 text-center'>Tracked Hours for Top 5 Projects</h4>
                  <span className='fs-30 fs-md-30 fs-xl-40 fs-lg-50 text-black font-bold d-block mb-xxl-3 mb-2 text-center'>{timeFormat(totalSeconds())}</span>
                </div>
                <p className='more-btn mt-0' onClick={handleClickNavigate} >more</p>
              </Col>
            </Row>
          </>}
      </div>

    </>
  )
}
export default TopProjects