import {subDays} from 'date-fns'
import {
    ATTENDANCE_REPORT_DATE_SELECTION_SUCCESS,
    FETCH_ATTENDANCE_REPORT_USERS_DROPDOWN_SUCCESS,
    FETCH_ATTENDANCE_REPORT_USERS_DROPDOWN_FAILED,
    ATTENDANCE_REPORT_USER_ADDED_SUCCESS,
    ATTENDANCE_REPORT_USER_REMOVE_SUCCESS,
    ATTENDANCE_REPORT_USERS_CLEAR_SUCCESS,
    ATTENDANCE_REPORT_USER_SELECTION_SUCCESS,
    ATTENDANCE_REPORT_GROUP_SELECTION_UPDATE,
    ATTENDANCE_REPORT_GROUPS_CLEAR_SUCCESS,
    FETCH_ATTENDANCE_REPORT_RECORD_SUCCESS,
    FETCH_ATTENDANCE_REPORT_RECORD_FAILED,
    FETCH_MORE_ATTENDANCE_REPORT_RECORD_SUCCESS,
    FETCH_MORE_ATTENDANCE_REPORT_RECORD_FAILED,
    FETCH_COMANY_ATTENDANCE_SETTING_SUCCESS,
    FETCH_COMANY_ATTENDANCE_SETTING_FAILED,
    UPDATE_COMANY_ATTENDANCE_SETTING_SUCCESS,
} from "../actions/Types"
import { getLocalStorageValue, setLocalStorageValue } from '../../utils/PersistanceManager'

// let selectedDateRange = JSON.parse(getLocalStorageValue("Attendance_Report_Date_Selection"))
let selectedDateRange = {"dates":"2023-11-29T19:00:00.410Z","savedOn":17}
let selectedUsers = JSON.parse(getLocalStorageValue("Attendance_Report_User_Selection"))
let selectedGroups = JSON.parse(getLocalStorageValue("Attendance_Report_Group_Selection"))
let listUsers = []
let listGroups = []

let initialState = {
    attendanceReportDateRange: { dateRange: new Date(selectedDateRange.dates), label: "Select Date" },
    // attendanceReportDateRange: { dateRange: selectedDateRange},
    attendanceReportUsersDropDown: { users: [], groups: [] },
    attendanceReportSelectedUsers: selectedUsers ? selectedUsers : [],
    attendanceReportSelectedGroups: selectedGroups ? selectedGroups : [],
    attendanceReportData: { "data": [], "loadMore": false },
    companyAttendanceSettings:  
        {
            "organizationId": "",
            "workDays": [],
            "shift": {
                "startTime": "00:00:00",
                "endTime": "00:00:00",
                "minimumRequiredHours": "00:00:00",
                "lateArrivalMargin": "00:00:00",
                "earlyLeaveMargin": "00:00:00"
            }
        },
}


export default function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {

        case ATTENDANCE_REPORT_DATE_SELECTION_SUCCESS:
            let newDt = { dates: payload.selectedDate, "savedOn": (new Date()).getDate() }
            setLocalStorageValue("Attendance_Report_Date_Selection", JSON.stringify(newDt))
            return {
                ...state,
                attendanceReportDateRange: { dateRange: payload.selectedDate, label: "Select Date" }
            }

        case FETCH_ATTENDANCE_REPORT_USERS_DROPDOWN_SUCCESS:
            selectedUsers = JSON.parse(getLocalStorageValue("Attendance_Report_User_Selection"))
            selectedGroups = JSON.parse(getLocalStorageValue("Attendance_Report_Group_Selection"))

            payload.listUsersDropDown.users.forEach((user) => {
                user['checked'] = false
            })

            listUsers = payload.listUsersDropDown.users
            const filteredAddMembers = selectedUsers != null ? listUsers.filter(item => !selectedUsers.some(a => a.id === item.id)) : payload.listUsersDropDown.users

            listGroups = payload.listUsersDropDown.groups
            payload.listUsersDropDown.groups.forEach((group) => {
                group['checked'] = false
                if (selectedGroups) {
                    selectedGroups.forEach((selectedGroup) => {
                        if (selectedGroup.id === group.id) group.checked = true
                    })
                }
            })

            return {
                ...state,
                attendanceReportUsersDropDown: { users: filteredAddMembers, groups: payload.listUsersDropDown.groups }
            }

        case FETCH_ATTENDANCE_REPORT_USERS_DROPDOWN_FAILED:
            return {
                ...state,
                attendanceReportUsersDropDown: { users: [], groups: [] },
            }

        case ATTENDANCE_REPORT_USER_ADDED_SUCCESS:
            let users = state.attendanceReportSelectedUsers
            users = users.concat(payload.userAdded)
            setLocalStorageValue("Attendance_Report_User_Selection", JSON.stringify(users))
            let searched = state.attendanceReportUsersDropDown.users
            var filter = searched.filter(a => !(a === payload.userAdded))
            return {
                ...state,
                attendanceReportSelectedUsers: users,
                attendanceReportUsersDropDown: { users: filter, groups: state.attendanceReportUsersDropDown.groups }
            }

        case ATTENDANCE_REPORT_USER_REMOVE_SUCCESS:
            let uzers = state.attendanceReportUsersDropDown.users
            if (listUsers.some(obj => obj.id === payload.userRemoved.id)) {
                payload.userRemoved.checked = false
                uzers.push(payload.userRemoved)
                uzers.sort((a, b) => a.name.localeCompare(b.name))
            }

            let arr = state.attendanceReportSelectedUsers
            var filter1 = arr.filter(a => !(a === payload.userRemoved))
            setLocalStorageValue("Attendance_Report_User_Selection", JSON.stringify(filter1))
            return {
                ...state,
                attendanceReportSelectedUsers: filter1,
                attendanceReportUsersDropDown: { users: uzers, groups: state.attendanceReportUsersDropDown.groups }
            }

        case ATTENDANCE_REPORT_USERS_CLEAR_SUCCESS:
            listUsers.forEach((user) => {
                user['checked'] = false
            })

            setLocalStorageValue("Attendance_Report_User_Selection", JSON.stringify(null))
            return {
                ...state,
                attendanceReportSelectedUsers: [],
                attendanceReportUsersDropDown: { users: listUsers, groups: state.attendanceReportUsersDropDown.groups }
            }
            
        case ATTENDANCE_REPORT_USER_SELECTION_SUCCESS:
            setLocalStorageValue("Attendance_Report_User_Selection", JSON.stringify(payload.selectedUsers))
            return {
                ...state,
                attendanceReportSelectedUsers: payload.selectedUsers
            }
            
        case ATTENDANCE_REPORT_GROUP_SELECTION_UPDATE:

            let newUserListState_0 = state.attendanceReportUsersDropDown
            newUserListState_0.groups.forEach((group, index) => {
                if (payload.changedGroup.id === group.id) {
                    group.checked = payload.changedGroup.checked
                } else {
                    group.checked = false
                }
            })
            setLocalStorageValue("Attendance_Report_Group_Selection", JSON.stringify(newUserListState_0.groups.filter(obj => obj.checked === true)))
            return {
                ...state,
                attendanceReportUsersDropDown: { users: newUserListState_0.users, groups: newUserListState_0.groups }
            }

        case ATTENDANCE_REPORT_GROUPS_CLEAR_SUCCESS:
            console.log("Reducer, listGroups: ", listGroups)
            listGroups.forEach((group) => {
                group['checked'] = false
            })

            setLocalStorageValue("Attendance_Report_Group_Selection", JSON.stringify(null))
            return {
                ...state,
                attendanceReportUsersDropDown: { users: state.attendanceReportUsersDropDown.users, groups: listGroups }
            }

        case FETCH_ATTENDANCE_REPORT_RECORD_SUCCESS:
            return {
                ...state,
                attendanceReportData: { "data": payload.reportRecord.data, loadMore: payload.loadMore }
            }

        case FETCH_ATTENDANCE_REPORT_RECORD_FAILED:
            return {
                ...state,
                attendanceReportData: { "data": [], "loadMore": false },
            }
        case FETCH_MORE_ATTENDANCE_REPORT_RECORD_SUCCESS:
            let ss = state.attendanceReportData
            let con = ss.data.concat(payload.reportRecord.data)
            return {
                ...state,
                attendanceReportData: { "data": con, loadMore: payload.loadMore },
            }

        case FETCH_MORE_ATTENDANCE_REPORT_RECORD_FAILED:
            return {
                ...state,
                attendanceReportData: { "data": [], "loadMore": false },
            }

        case FETCH_COMANY_ATTENDANCE_SETTING_SUCCESS:
            if(Object.keys(payload.companyAttendance).length === 0){
                return {
                    ...state,
                    companyAttendanceSettings:  
                    {
                        "organizationId": "",
                        "workDays": [],
                        "shift": {
                            "startTime": "00:00:00",
                            "endTime": "00:00:00",
                            "minimumRequiredHours": "00:00:00",
                            "lateArrivalMargin": "00:00:00",
                            "earlyLeaveMargin": "00:00:00"
                        }
                    },
                }
            }else{
                return {
                    ...state,
                    companyAttendanceSettings: payload.companyAttendance,
                }
            }

        case FETCH_COMANY_ATTENDANCE_SETTING_FAILED:
            return {
                ...state,
                companyAttendanceSettings:  
                {
                    "organizationId": "",
                    "workDays": [],
                    "shift": {
                        "startTime": "00:00:00",
                        "endTime": "00:00:00",
                        "minimumRequiredHours": "00:00:00",
                        "lateArrivalMargin": "00:00:00",
                        "earlyLeaveMargin": "00:00:00"
                    }
                },
            }

        case UPDATE_COMANY_ATTENDANCE_SETTING_SUCCESS:
            return {
                ...state,
                companyAttendanceSettings:  
                {
                    "organizationId": payload.resdata.organizationId,
                    "workDays": payload.resdata.workDays,
                    "shift": {
                        "startTime": payload.resdata.startTime,
                        "endTime": payload.resdata.endTime,
                        "minimumRequiredHours": payload.resdata.minimumRequiredHours,
                        "lateArrivalMargin": payload.resdata.lateArrivalMargin,
                        "earlyLeaveMargin": payload.resdata.earlyLeaveMargin
                    }
                },
            }    

        default:
            return state
    }
}
