import { Tab } from 'bootstrap';
import React, { useState, useEffect } from 'react'
import { Tabs } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, connect } from "react-redux";
import { timeFormatInHMS, timeFormatInHMorMS, timeToMinutesConverter, toIsoString, noDataSimple, getcharacter, timePlusDateToLocalTimeConverter, isoToLocalTimeConverter } from '../../common/GlobalFunctions'
import { fetchMoreUserDashboardHourlyScreenshot, fetchUserDashboardHourlyCatalogTrack, fetchUserDashboardHourlyScreenshot, fetchCatalogTitle, fetchMoreCatalogTitle } from '../../redux/actions/UserDashboardAction'
import './style.css';
import { NoDataToDisplay } from '../../utils/Constants';
import { Link } from 'react-router-dom';
import CustomLightbox from '../CustomLightBox/CustomLightBox';
import Loading from '../loading';



const SingleUserHoursDetails = (props) => {
  const { selectedCompany, UserDashboardHourlyCatalogTrack, UserDashboardHourlyScreenshot,
    show, handleClose, userHourDetails, activity, date, userName, userId, catalogTitle, userProfile } = props

  const dispatch = useDispatch();
  const [userHourlyCatalogTrackLoading, setUserHourlyCatalogTrackLoading] = useState(false);
  const [userHourlyScreenshotLoading, setUserHourlyScreenshotLoading] = useState(false);
  const [moreUserHourlyScreenshotLoading, setMoreUserHourlyScreenshotLoading] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const [loadCatalogTitleDetails, setLoadCatalogTitleDetails] = useState(false);
  const [loadMoreCatalogTitleDetails, setLoadMoreCatalogTitleDetails] = useState(false);
  const [showSingelUserSecondDetailsModel, setshowSingelUserSecondDetailsModel] = useState(false)
  const [skipIndex, setSkipIndex] = useState(0);
  const [catalog_Id, setCatalogId] = useState(0);
  var limit = 10;

  const [isOpen, setIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const openLightbox = (index) => {
    setCurrentImageIndex(index);
    setIsOpen(true);
  };



  useEffect(() => {
    getUserDashboardHourlyCatalogTrack();
    getUserDashboardHourlyScreenshot(selectedCompany.id, [fncStartTime(date, userHourDetails.startTime), fncEndTime(date, userHourDetails.endTime)], userId, userHourDetails.projectId, userHourDetails.taskId, startIndex, limit, -1);
  }, []);

  // orgId, dateRange, user, projectId, taskId, startIndex, limit, sort



  function fncStartTime(date, time) {
    var aaa = date + "T" + time + ":00";
    var b = new Date(aaa);
    return b.toISOString()
      .replace(/\.\d{3}Z$/, '.000+00:00')
  }

  function fncEndTime(date, time) {
    var aaa = date + "T" + time + ":59";
    var b = new Date(aaa);

    // const [hours, minutes] = time.split(':').map(Number);
    // const [zoneHours, zoneMinutes] = userProfile.timeZoneOffset.split(':').map(Number);
    // return date + "T" + ((hours - zoneHours) < 10 ? "0" + hours - zoneHours : hours - zoneHours) + ":" + ((minutes - zoneMinutes) < 10 ? "0" + minutes - zoneMinutes : minutes - zoneMinutes) + ".59.000+00:00"
    return b.toISOString()
      .replace(/\.\d{3}Z$/, '.000+00:00')
  }


  function getUserDashboardHourlyCatalogTrack() {
    setUserHourlyCatalogTrackLoading(true);
    dispatch(fetchUserDashboardHourlyCatalogTrack(selectedCompany.id, [fncStartTime(date, userHourDetails.startTime), fncEndTime(date, userHourDetails.endTime)], userId, userHourDetails.timeOn.taskId))
      .then(() => {
        setUserHourlyCatalogTrackLoading(false);
      })
      .catch((error) => {
        console.log("error: ", error);
        setUserHourlyCatalogTrackLoading(false);
      });
  }

  function getUserDashboardHourlyScreenshot(orgId, dateRange, userId, projectId, taskId, startIndex, limit, sort) {
    setUserHourlyScreenshotLoading(true);
    dispatch(fetchUserDashboardHourlyScreenshot(orgId, dateRange, userId, projectId, taskId, startIndex, limit, sort))
      .then(() => {
        setUserHourlyScreenshotLoading(false);
      })
      .catch((error) => {
        console.log("error: ", error);
        setUserHourlyScreenshotLoading(false);
      });
    setStartIndex(startIndex + limit);
  }


  // Orgid, dateArray, userId, startIndex, limit
  function getMoreUserDashboardHourlyScreenshot(orgId, dateRange, userId, projectId, taskId, startIndex, limit, sort) {
    setMoreUserHourlyScreenshotLoading(true);
    dispatch(fetchMoreUserDashboardHourlyScreenshot(orgId, dateRange, userId, projectId, taskId, startIndex, limit, sort))
      .then(() => {
        setMoreUserHourlyScreenshotLoading(false);
      })
      .catch((error) => {
        console.log("error: ", error);
        setMoreUserHourlyScreenshotLoading(false);
      });
    setStartIndex(startIndex + limit);
  }

  function onLoadMore() {
    getMoreUserDashboardHourlyScreenshot(selectedCompany.id, [fncStartTime(date, userHourDetails.startTime), fncEndTime(date, userHourDetails.endTime)], userId, userHourDetails.projectId, userHourDetails.taskId, startIndex, limit, -1);
  }


  const activityBarFunction = () => {
    let activities = [];
    activity.forEach((s, i) => {
      activities.push(
        <div key={i} className='bg-green absolute-div' style={{ width: `${s.timeTracked * 0.001182}%`, height: "25px", left: `${timeToMinutesConverter(s.startTime, "+00:00") * 0.069444}%` }} >
          <span className='toolTip'>
            {s.timeOn.projectName}
            <br />{"(" + s.timeOn.taskName + ")"}
            <br />{timeFormatInHMS(s.timeTracked)}
          </span>
        </div>)
    })
    return activities;
  }

  function FindPercentange(obtained) {
    return userHourDetails.timeTracked <= 0 ? 0 : (obtained * 100) / userHourDetails.timeTracked;
  };

  function toLocal(date) {
    var d = new Date(date);
    return d.toLocaleTimeString();
  }
  const openSingelUserTimeTrackDetails = () => {
    setshowSingelUserSecondDetailsModel(!showSingelUserSecondDetailsModel)
    if (showSingelUserSecondDetailsModel === true) {
      setSkipIndex(0)
    }
  }

  const percentage = (total, obtain) => {
    return Math.round(total <= 0 ? 0 : (obtain * 100) / total);
  };

  function openSecondModel(catalogId) {
    setLoadCatalogTitleDetails(true)
    dispatch(fetchCatalogTitle(selectedCompany.id, userId, { 'dateRange': [fncStartTime(date, userHourDetails.startTime), fncEndTime(date, userHourDetails.endTime)] }, catalogId, 0, limit))
      .then(() => {
        setLoadCatalogTitleDetails(false)
      })
      .catch((error) => {
        console.log("error: ", error)
        setLoadCatalogTitleDetails(false)
      })
    setCatalogId(catalogId)
    setSkipIndex(skipIndex + limit)
    openSingelUserTimeTrackDetails()
    // setRatingTypeClass(ratingTypeClass)
  }
  // console.log('start time', fncStartTime(date, userHourDetails.startTime))
  // console.log('end time', fncEndTime(date, userHourDetails.endTime))

  function onLoadMoreTitle() {
    setLoadMoreCatalogTitleDetails(true)
    dispatch(fetchMoreCatalogTitle(selectedCompany.id, userId, { 'dateRange': [fncStartTime(date, userHourDetails.startTime), fncEndTime(date, userHourDetails.endTime)] }, catalog_Id, skipIndex, limit))
      .then(() => {
        setLoadMoreCatalogTitleDetails(false)
      })
      .catch((error) => {
        console.log("error: ", error)
        setLoadMoreCatalogTitleDetails(false)
      })
    setSkipIndex(skipIndex + limit)
  }

  function totalTimeSeconds() {
    return Math.floor(catalogTitle.catalog.reduce((n, obj) => n + obj.totalActiveTime, 0));
  }
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="overly">
        <Modal.Header closeButton>
          <Modal.Title>
            <div className='head-model single-user-hours-details d-flex align-items-center '>
              <span className='d-flex justify-content-center pt-1 align-items-center fs-12 font-bold me-2'>{getcharacter(userName)}</span>
              <p className='m-0 modal-heading'>{userName}</p>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-0'>
          <div className='overview-box'>
            <div className='time-am position-relative'>
              <div className='am-two d-flex justify-content-between align-items-center'>
                <p className='m-0'>
                  <div className='line'></div>
                  <span>0</span>
                </p>
                <p className='m-0'>
                  <div className='line'></div>
                  <span>1</span>
                </p>
                <p className='m-0'>
                  <div className='line'></div>
                  <span>2</span>
                </p>
                <p className='m-0'>
                  <div className='line'></div>
                  <span>3</span>
                </p>
                <p className='m-0'>
                  <div className='line'></div>
                  <span>4</span>
                </p>
                <p className='m-0'>
                  <div className='line'></div>
                  <span>5</span>
                </p>
                <p className='m-0'>
                  <div className='line'></div>
                  <span>6</span>
                </p>
                <p className='m-0'>
                  <div className='line'></div>
                  <span>7</span>
                </p>
                <p className='m-0'>
                  <div className='line'></div>
                  <span>8</span>
                </p>
                <p className='m-0'>
                  <div className='line'></div>
                  <span>9</span>
                </p>
                <p className='m-0'>
                  <div className='line'></div>
                  <span>10</span>
                </p>
                <p className='m-0'>
                  <div className='line'></div>
                  <span>11</span>
                </p>
                <p className='m-0'>
                  <div className='line'></div>
                  <span>12</span>
                </p>
                <p className='m-0'>
                  <div className='line'></div>
                  <span>13</span>
                </p>
                <p className='m-0'>
                  <div className='line'></div>
                  <span>14</span>
                </p>
                <p className='m-0'>
                  <div className='line'></div>
                  <span>15</span>
                </p>
                <p className='m-0'>
                  <div className='line'></div>
                  <span>16</span>
                </p>
                <p className='m-0'>
                  <div className='line'></div>
                  <span>17</span>
                </p>
                <p className='m-0'>
                  <div className='line'></div>
                  <span>18</span>
                </p>
                <p className='m-0'>
                  <div className='line'></div>
                  <span>19</span>
                </p>
                <p className='m-0'>
                  <div className='line'></div>
                  <span>20</span>
                </p>
                <p className='m-0'>
                  <div className='line'></div>
                  <span>21</span>
                </p>
                <p className='m-0'>
                  <div className='line'></div>
                  <span>22</span>
                </p>
                <p className='m-0'>
                  <div className='line'></div>
                  <span>23</span>
                </p>
                <p className='m-0'>
                  <div className='line'></div>
                  <span>24</span>
                </p>

              </div>
              <div className=' absolute-box d-flex justify-content-center align-items-center'>
                {activityBarFunction()}
              </div>
            </div>
            <div className='p-3 over-box d-flex flex-column flex-md-row justify-content-center align-items-center pt-4 pb-2'>
              <h3 className='heading m-0 mb-2 mb-md-0'>Overview</h3>
              <div className='clock  d-flex justify-content-center'>
                <p className=''>{new Date(date).toDateString()}</p>
                <p>
                  <img src={"images/clock.svg"} />
                  <span> Worked:</span>{timeFormatInHMS(userHourDetails.timeTracked)}
                </p>
                <p>
                  <img src={"images/arrow.svg"} />
                  <span>Time Start:</span>{timePlusDateToLocalTimeConverter(date, userHourDetails.startTime, "UTC")}
                </p>
                <p className='last'>
                  <span>Time End:</span>{timePlusDateToLocalTimeConverter(date, userHourDetails.endTime, "UTC")}
                </p>
              </div>
            </div>
          </div>
          <div className='tabs-box screenshot-padding-fix links-color-fix bg-white'>
            <Tabs
              defaultActiveKey="home"
              transition={false}
              id="noanim-tab-example"
            >
              <Tab eventKey="home" title="Websites & Apps">
                <div className={showSingelUserSecondDetailsModel === true ? 'travas-model show' : 'travas-model'}>
                  <div className='header-box d-flex align-items-center bg-show pe-2 border-bottom'>
                    <div className='website px-2 d-flex align-items-center'>
                      <button className='btn btn-default back-arrow' onClick={openSingelUserTimeTrackDetails}> <img src="images/arrow-svg.svg" alt="arrow-back" width={30} /></button>
                    </div>

                  </div>
                  {loadCatalogTitleDetails ?
                    <p className="text-center my-5 loader">
                      <Loading />
                    </p> :
                    <>
                      <div className='scroll-websites'>
                        {catalogTitle.catalog.map((v, index) => (
                          <div className='header-box d-flex align-items-center'>
                            <div div className='row-tr d-flex align-items-center w-100 pe-5' >
                              <div className='td'>{v.title}</div>
                              <div className='td'>{timeFormatInHMorMS(v.totalActiveTime)}</div>
                              <div className='td-last d-flex align-items-center w-100 pe-5'>
                                <div className='bg-green' style={{ width: `${FindPercentange(v.totalActiveTime)}%`, height: "25px" }}></div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      {
                        (() => {
                          if (loadMoreCatalogTitleDetails) {
                            return (
                              <p className="loader-blue d-flex justify-content-center align-items-center my-4">
                                <Loading />
                              </p>
                            )
                          }
                          else if (catalogTitle.loadMore) {
                            return (
                              <>
                                <div className='loader-blue d-flex justify-content-center align-items-center my-4' onClick={onLoadMoreTitle}>
                                  <span className='loading'>Show More</span>
                                </div>
                              </>
                            )
                          }
                          else {
                            return (
                              <></>
                            )
                          }
                        })()
                      }
                    </>
                  }
                </div>
                {/* { loadCatalogTitleDetails ?
                  <p className="text-center my-5 loader">
                    <Loading />
                  </p> :
                  <>
                  <div className='scroll-websites aaaaaaaa'>
                    {catalogTitle.catalog.map((v, index) => (
                      <div className='header-box d-flex align-items-center'>
                        <div div className='row-tr d-flex align-items-center' >
                          <div className='td'>{v.title}</div>
                          <div className='td'>{timeFormatInHMorMS(v.totalActiveTime)}</div>
                          <div className='td-last d-flex align-items-center'>
                            <div className='bg-green' style={{ width: `${FindPercentange(v.totalActiveTime)}%`, height: "25px" }}></div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  {
                    (() => {
                      if (loadMoreCatalogTitleDetails) {
                        return (
                          <p className="loader-blue d-flex justify-content-center align-items-center my-4">
                            <Loading />
                          </p>
                        )
                      }
                      else if (catalogTitle.loadMore) {
                        return (
                          <>
                            <div className='loader-blue d-flex justify-content-center align-items-center my-4' onClick={onLoadMoreTitle}>
                              <span className='loading'>Show More</span>
                            </div>
                          </>
                        )
                      }
                      else {
                        return (
                          <></>
                        )
                      }
                    })()
                  }
                    </>
                  } */}


                <div className='scroll-table style-1 mb-4 height table-paragraph'>
                  {userHourlyCatalogTrackLoading ?
                    <p className="text-center loader my-5">
                      <Loading />
                    </p>
                    : UserDashboardHourlyCatalogTrack.hourlycatalog.map((i, index) => (
                      <div div className='row-tr d-flex align-items-center cursor-pointer' >
                        <div className='td' onClick={() => openSecondModel(i.catalogId)}>{i.catalogName} </div>
                        <div className='td'>{timeFormatInHMorMS(i.activeTime)}</div>
                        <div className='td-last d-flex align-items-center'>
                          <div className='bg-green' style={{ width: `${FindPercentange(i.activeTime)}%`, height: "25px" }}></div>
                        </div>
                      </div>
                    ))}
                </div>

              </Tab>
              <Tab eventKey="profile" title="Screenshots">
                <div className='scroll-table style-1'>
                  <div className='screen-shot d-flex flex-wrap'>

                    {/* <SlideshowLightbox className="container grid grid-cols-3 gap-2 mx-auto"> */}

                    {userHourlyScreenshotLoading ?
                      <p className="text-center loader my-5 w-100">
                        <Loading />
                      </p>
                      : UserDashboardHourlyScreenshot.screenshots.map((i, index) => (
                        // <img className="w-full rounded" src={i.imageUrl} key={index} />

                        <div className='shot mb-3' key={index} >
                          {/* <Link to={i.imageUrl}
                            target="_blank"
                            rel="noreferrer">
                            <img className="w-full rounded" src={i.imageUrl} />
                          </Link> */}
                          <img className="w-full rounded" src={i.imageUrl} onClick={() => { openLightbox(index) }} />
                          <p className='fs-14 text-black font-medium m-0'>{isoToLocalTimeConverter(i.dateCaptured)}</p>
                        </div>
                      ))}

                    {UserDashboardHourlyScreenshot.screenshots.length <= 0 &&
                      noDataSimple()
                    }

                    <CustomLightbox isOpen={isOpen} setIsOpen={setIsOpen} currentImageIndex={currentImageIndex} setCurrentImageIndex={setCurrentImageIndex} screenShotList={UserDashboardHourlyScreenshot.screenshots} />

                  </div>
                  {(() => {
                    if (moreUserHourlyScreenshotLoading) {
                      return (
                        <p className="loader-blue d-flex justify-content-center align-items-center my-4 mt-0">
                          <Loading />
                        </p>
                      )
                    }
                    else if (userHourlyScreenshotLoading) {
                      return (<></>)
                    }
                    else if (UserDashboardHourlyScreenshot.loadMore) {
                      return (
                        <>
                          <div className='loader-blue d-flex justify-content-center align-items-center my-4 mt-0' onClick={onLoadMore}>
                            <span className="loading">Show More</span>
                          </div>
                        </>
                      )
                    }
                    else {
                      return (
                        <></>
                      )
                    }
                  })()}
                </div>
              </Tab>
            </Tabs>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
const mapStateToProps = state => ({
  selectedCompany: state.UserReducer.selectedCompany,
  userProfile: state.UserReducer.userProfile,
  UserDashboardHourlyCatalogTrack: state.UserDashboardReducer.UserDashboardHourlyCatalogTrack,
  UserDashboardHourlyScreenshot: state.UserDashboardReducer.UserDashboardHourlyScreenshot,
  catalogTitle: state.HoursTrackedReducer.catalogTitle,
});
export default connect(mapStateToProps)(SingleUserHoursDetails);
