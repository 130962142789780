import {
    SET_MESSAGE,
    FETCH_DASHBOARD_COMULATIVETIME_FAILED,
    FETCH_DASHBOARD_COMULATIVETIME_SUCCESS,
    FETCH_DASHBOARD_TOP_USED_WEBSITES_APPS_PRODUCTIVE_SUCCESS,
    FETCH_DASHBOARD_TOP_USED_WEBSITES_APPS_PRODUCTIVE_FAILED,
    FETCH_DASHBOARD_TOP_USED_WEBSITES_APPS_UNPRODUCTIVE_SUCCESS,
    FETCH_DASHBOARD_TOP_USED_WEBSITES_APPS_UNPRODUCTIVE_FAILED,
    FETCH_DASHBOARD_TRACKING_MOST_HOURS_SUCCESS,
    FETCH_DASHBOARD_TRACKING_MOST_HOURS_FAILED,
    FETCH_DASHBOARD_TRACKING_LEAST_HOURS_SUCCESS,
    FETCH_DASHBOARD_TRACKING_LEAST_HOURS_FAILED,
    FETCH_DASHBOARD_DASHBOARD_TOP_USERS_PRODUCTIVE_SUCCESS,
    FETCH_DASHBOARD_DASHBOARD_TOP_USERS_PRODUCTIVE_FAILED,
    FETCH_DASHBOARD_DASHBOARD_TOP_USERS_UNPRODUCTIVE_SUCCESS,
    FETCH_DASHBOARD_DASHBOARD_TOP_USERS_UNPRODUCTIVE_FAILED,
    FETCH_DASHBOARD_USERS_DROPDOWN_SUCCESS,
    FETCH_DASHBOARD_USERS_DROPDOWN_FAILED,
    FETCH_DASHBOARD_TOP_PROJECTS_SUCCESS,
    FETCH_DASHBOARD_TOP_PROJECTS_FAILED,
    INTERNET_AVAILABILITY,
} from "./Types";
import DashboardService from "../../services/DashboardService";

import { toIsoString, toIsoDate, getActiveTimezone } from '../../common/GlobalFunctions'

export const fetchDashboardComulativeTime = (orgId, dateRange, users, groups, basedOn, ratingScope, ratingScopeId) => (dispatch) => {

    var data = {
        "organizationId": orgId,
        "startTime": toIsoDate(dateRange.dateRange[0], 0),
        "endTime": toIsoDate(dateRange.dateRange[1], 1),
        "users": users,
        "groups": groups,
        "basedOn": basedOn,
        "ratingScope": ratingScope,
        "ratingScopeId": ratingScopeId,
        "timezone": getActiveTimezone().timeZoneOffset
    }
    return DashboardService.fetchDashboardComulativeTime(data).then(
        (data) => {
            if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
                dispatch({
                    type: FETCH_DASHBOARD_COMULATIVETIME_SUCCESS,
                    payload: { dashboardComulativeTime: data.users_stats },
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: true },
                });
                return Promise.resolve();
            }
            else if (data === "ERR_NETWORK") {
                dispatch({
                    type: FETCH_DASHBOARD_COMULATIVETIME_FAILED,
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: false },
                });
                return Promise.reject();
            }
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: FETCH_DASHBOARD_COMULATIVETIME_FAILED,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};

export const fetchDashboardWebsitesAppsProductive = (orgId, dateRange, users, groups, basedOn, ratingScope, ratingScopeId) => (dispatch) => {

    var data = {
        "organizationId": orgId,
        "startTime": toIsoDate(dateRange.dateRange[0], 0),
        "endTime": toIsoDate(dateRange.dateRange[1], 1),
        "users": users,
        "groups": groups,
        "basedOn": basedOn,
        "orderBy": "Productive",
        "ratingScope": ratingScope,
        "ratingScopeId": ratingScopeId,
        "timezone": getActiveTimezone().timeZoneOffset
    }
    return DashboardService.fetchDashboardWebsitesAppsProductive(data).then(
        (data) => {
            if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
                dispatch({
                    type: FETCH_DASHBOARD_TOP_USED_WEBSITES_APPS_PRODUCTIVE_SUCCESS,
                    payload: { dashboardTopUsedWebsitesAppsProductive: data },
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: true },
                });
                return Promise.resolve();
            }
            else if (data === "ERR_NETWORK") {
                dispatch({
                    type: FETCH_DASHBOARD_TOP_USED_WEBSITES_APPS_PRODUCTIVE_FAILED,
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: false },
                });
                return Promise.reject();
            }
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: FETCH_DASHBOARD_TOP_USED_WEBSITES_APPS_PRODUCTIVE_FAILED,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};

export const fetchDashboardWebsitesAppsUnProductive = (orgId, dateRange, users, groups, basedOn, ratingScope, ratingScopeId) => (dispatch) => {

    var data = {
        "organizationId": orgId,
        "startTime": toIsoDate(dateRange.dateRange[0], 0),
        "endTime": toIsoDate(dateRange.dateRange[1], 1),
        "users": users,
        "groups": groups,
        "basedOn": basedOn,
        "orderBy": "UnProductive",
        "ratingScope": ratingScope,
        "ratingScopeId": ratingScopeId,
        "timezone": getActiveTimezone().timeZoneOffset
    }
    return DashboardService.fetchDashboardWebsitesAppsUnProductive(data).then(
        (data) => {
            if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
                dispatch({
                    type: FETCH_DASHBOARD_TOP_USED_WEBSITES_APPS_UNPRODUCTIVE_SUCCESS,
                    payload: { dashboardTopUsedWebsitesAppsUnProductive: data },
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: true },
                });
                return Promise.resolve();
            }
            else if (data === "ERR_NETWORK") {
                dispatch({
                    type: FETCH_DASHBOARD_TOP_USED_WEBSITES_APPS_UNPRODUCTIVE_FAILED,
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: false },
                });
                return Promise.reject();
            }
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: FETCH_DASHBOARD_TOP_USED_WEBSITES_APPS_UNPRODUCTIVE_FAILED,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};

export const fetchDashboardTrackingMostHours = (orgId, dateRange, users, groups, basedOn) => (dispatch) => {
    var data = {
        "organizationId": orgId,
        "startTime": toIsoDate(dateRange.dateRange[0], 0),
        "endTime": toIsoDate(dateRange.dateRange[1], 1),
        "users": users,
        "groups": groups,
        "basedOn": basedOn,
        "orderBy": "mostTimeTracked",
        "timezone": getActiveTimezone().timeZoneOffset
    }
    return DashboardService.fetchDashboardTrackingMostHours(data).then(
        (data) => {
            if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
                dispatch({
                    type: FETCH_DASHBOARD_TRACKING_MOST_HOURS_SUCCESS,
                    payload: { dashboardTrackingMostHours: data },
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: true },
                });
                return Promise.resolve();
            }
            else if (data === "ERR_NETWORK") {
                dispatch({
                    type: FETCH_DASHBOARD_TRACKING_MOST_HOURS_FAILED,
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: false },
                });
                return Promise.reject();
            }
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: FETCH_DASHBOARD_TRACKING_MOST_HOURS_FAILED,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};

export const fetchDashboardTrackingLeastHours = (orgId, dateRange, users, groups, basedOn, trackZeroHrs) => (dispatch) => {
    var data = {
        "organizationId": orgId,
        "startTime": toIsoDate(dateRange.dateRange[0], 0),
        "endTime": toIsoDate(dateRange.dateRange[1], 1),
        "users": users,
        "groups": groups,
        "basedOn": basedOn,
        "orderBy": "leastTimeTracked",
        "trackZeroHrs": trackZeroHrs,
        "timezone": getActiveTimezone().timeZoneOffset
    }
    return DashboardService.fetchDashboardTrackingLeastHours(data).then(
        (data) => {
            if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
                dispatch({
                    type: FETCH_DASHBOARD_TRACKING_LEAST_HOURS_SUCCESS,
                    payload: { dashboardTrackingLeastHours: data },
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: true },
                });
                return Promise.resolve();
            }
            else if (data === "ERR_NETWORK") {
                dispatch({
                    type: FETCH_DASHBOARD_TRACKING_LEAST_HOURS_FAILED,
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: false },
                });
                return Promise.reject();
            }
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: FETCH_DASHBOARD_TRACKING_LEAST_HOURS_FAILED,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};

export const fetchDashboardTopUsersProductive = (orgId, dateRange, users, groups, basedOn, ratingScope, ratingScopeId) => (dispatch) => {
    var data = {
        "organizationId": orgId,
        "startTime": toIsoDate(dateRange.dateRange[0], 0),
        "endTime": toIsoDate(dateRange.dateRange[1], 1),
        "users": users,
        "groups": groups,
        "basedOn": basedOn,
        "orderBy": "Productive",
        "ratingScope": ratingScope,
        "ratingScopeId": ratingScopeId,
        "timezone": getActiveTimezone().timeZoneOffset
    }
    // console.log("Top Users Productive Payload: ", data);
    return DashboardService.fetchDashboardTopUsersProductive(data).then(
        (data) => {
            if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
                dispatch({
                    type: FETCH_DASHBOARD_DASHBOARD_TOP_USERS_PRODUCTIVE_SUCCESS,
                    payload: { dashboardTopUsersProductive: data },
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: true },
                });
                return Promise.resolve();
            }
            else if (data === "ERR_NETWORK") {
                dispatch({
                    type: FETCH_DASHBOARD_DASHBOARD_TOP_USERS_PRODUCTIVE_FAILED,
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: false },
                });
                return Promise.reject();
            }
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: FETCH_DASHBOARD_DASHBOARD_TOP_USERS_PRODUCTIVE_FAILED,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};

export const fetchDashboardTopUsersUnProductive = (orgId, dateRange, users, groups, basedOn, ratingScope, ratingScopeId) => (dispatch) => {
    var data = {
        "organizationId": orgId,
        "startTime": toIsoDate(dateRange.dateRange[0], 0),
        "endTime": toIsoDate(dateRange.dateRange[1], 1),
        "users": users,
        "groups": groups,
        "basedOn": basedOn,
        "orderBy": "UnProductive",
        "ratingScope": ratingScope,
        "ratingScopeId": ratingScopeId,
        "timezone": getActiveTimezone().timeZoneOffset
    }

    return DashboardService.fetchDashboardTopUsersUnProductive(data).then(
        (data) => {
            if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
                dispatch({
                    type: FETCH_DASHBOARD_DASHBOARD_TOP_USERS_UNPRODUCTIVE_SUCCESS,
                    payload: { dashboardTopUsersUnProductive: data },
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: true },
                });
                return Promise.resolve();
            }
            else if (data === "ERR_NETWORK") {
                dispatch({
                    type: FETCH_DASHBOARD_DASHBOARD_TOP_USERS_UNPRODUCTIVE_FAILED,
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: false },
                });
                return Promise.reject();
            }
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: FETCH_DASHBOARD_DASHBOARD_TOP_USERS_UNPRODUCTIVE_FAILED,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};

export const fetchDashboardUsersDropDown = (orgId) => (dispatch) => {

    var data = {
        "organizationId": orgId
    }
    return DashboardService.fetchDashboardUsersDropDown(data).then(
        (data) => {
            if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
                dispatch({
                    type: FETCH_DASHBOARD_USERS_DROPDOWN_SUCCESS,
                    payload: { dashboardUsersDropDown: data },
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: true },
                });
                return Promise.resolve();
            }
            else if (data === "ERR_NETWORK") {
                dispatch({
                    type: FETCH_DASHBOARD_USERS_DROPDOWN_FAILED,
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: false },
                });
                return Promise.reject();
            }
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: FETCH_DASHBOARD_USERS_DROPDOWN_FAILED,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};

export const fetchDashboardTopProjects = (orgId, dateRange, users, groups, basedOn) => (dispatch) => {

    var data = {
        "organizationId": orgId,
        "startTime": toIsoDate(dateRange.dateRange[0], 0),
        "endTime": toIsoDate(dateRange.dateRange[1], 1),
        "users": users,
        "groups": groups,
        "basedOn": basedOn,
        "orderBy": "mostTimeTracked",
        "timezone": getActiveTimezone().timeZoneOffset
    }
    return DashboardService.fetchDashboardTopProjects(data).then(
        (data) => {
            if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
                dispatch({
                    type: FETCH_DASHBOARD_TOP_PROJECTS_SUCCESS,
                    payload: { dashboardTopProjects: data },
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: true },
                });
                return Promise.resolve();
            }
            else if (data === "ERR_NETWORK") {
                dispatch({
                    type: FETCH_DASHBOARD_TOP_PROJECTS_FAILED,
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: false },
                });
                return Promise.reject();
            }
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: FETCH_DASHBOARD_TOP_PROJECTS_FAILED,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};

export const fetchSearchableUsersGroups = (orgId, searchKeyword, skip, limit) => (dispatch) => {
    var data = {
        "organizationId": orgId,
        "search": searchKeyword,
        "skip": skip,
        "limit": limit
    }
    return DashboardService.fetchDashboardUsersGroups(data).then(
        (data) => {
            if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
                dispatch({
                    type: FETCH_DASHBOARD_USERS_DROPDOWN_SUCCESS,
                    payload: { dashboardUsersDropDown: data },
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: true },
                });
                return Promise.resolve();
            }
            else if (data === "ERR_NETWORK") {
                dispatch({
                    type: FETCH_DASHBOARD_USERS_DROPDOWN_FAILED,
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: false },
                });
                return Promise.reject();
            }
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: FETCH_DASHBOARD_USERS_DROPDOWN_FAILED,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};
