import { subDays } from "date-fns"
import {
    FETCH_MONTHLY_PERFORMANCE_REPORT_SUCCESS,
    FETCH_MONTHLY_PERFORMANCE_REPORT_FAILED,
    FETCH_MORE_MONTHLY_PERFORMANCE_REPORT_SUCCESS,
    FETCH_MORE_MONTHLY_PERFORMANCE_REPORT_FAILED,

    FETCH_USERS_PERFORMANCE_DROPDOWN_SUCCESS,
    FETCH_USERS_PERFORMANCE_DROPDOWN_FAILED,

    REPORT_PERFORMANCE_USER_SELECTION_SUCCESS,
    PERFORMANCE_USER_DROPDOWN_SELECTION_UPDATE,
    PERFORMANCE_REPORT_DATE_SELECTION_SUCCESS,

    REPORT_PERFORMANCE_USERS_CLEAR_SUCCESS,
    REPORT_PERFORMANCE_GROUP_SELECTION_UPDATE,
    REPORT_PERFORMANCE_USER_ADDED_SUCCESS,
    REPORT_PERFORMANCE_USER_REMOVE_SUCCESS,
    REPORT_PERFORMANCE_GROUPS_CLEAR_SUCCESS
} from "../actions/Types"
import { getLocalStorageValue, setLocalStorageValue } from "../../utils/PersistanceManager"

let selectedDateRange = "2023-10-31T19:00:00.410Z"
let selectedUsers = JSON.parse(getLocalStorageValue("Performance_Report_User_Selection"))
let selectedGroups = JSON.parse(getLocalStorageValue("Performance_Report_Group_Selection"))
let listUsers = []
let listGroups = []

// Get the current date
const currentDate = new Date();
const previousMonth = new Date(currentDate);
previousMonth.setMonth(previousMonth.getMonth() - 1);

let initialState = {
    fetchPerformanceComparison: { list: [], loadMore: false },
    performanceReportMonth: {},
    performenceReportDateRange: { dateRange: selectedDateRange },
    performanceReportUsersDropDown: { users: [], groups: [] },
    performanceSelectedUsers: selectedUsers ? selectedUsers : [],
    performanceSelectedGroups: selectedGroups ? selectedGroups : [],
}

export default function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case REPORT_PERFORMANCE_USER_SELECTION_SUCCESS:
            setLocalStorageValue("Performance_Report_User_Selection", JSON.stringify(payload.selectedUsers))
            return {
                ...state,
                performanceSelectedUsers: payload.selectedUsers
            }
        case PERFORMANCE_USER_DROPDOWN_SELECTION_UPDATE:
            let newUserListState = state.performanceReportUsersDropDown
            newUserListState.users.forEach((user, index) => {
                if (payload.changedUser.id === user.id) {
                    user.checked = payload.changedUser.checked
                }
            })
            return {
                ...state,
                performanceReportUsersDropDown: { users: newUserListState.users, groups: [] }
            }

        case FETCH_MONTHLY_PERFORMANCE_REPORT_SUCCESS:
            let newPerformance = payload.fetchPerformanceReport;
            newPerformance.forEach((i) => {
                i["loading"] = false;
            });
            return {
                ...state,
                fetchPerformanceComparison: { list: newPerformance, loadMore: payload.loadMore }
            }

        case FETCH_MONTHLY_PERFORMANCE_REPORT_FAILED:
            return {
                ...state,
                fetchPerformanceComparison: { list: [], loadMore: false }
            }
        case FETCH_MORE_MONTHLY_PERFORMANCE_REPORT_SUCCESS:
            let us = state.fetchPerformanceComparison;
            let nlist = payload.fetchPerformanceReport;
            let blist = [];
            us.list.forEach((value, index) => {
                blist[index] = { 'month': value.month, 'users': value.users.concat(nlist[index].users) }
            })
            return {
                ...state,
                fetchPerformanceComparison: { list: blist, loadMore: payload.loadMore },
            };

        case FETCH_MORE_MONTHLY_PERFORMANCE_REPORT_FAILED:
            return {
                ...state,
                fetchPerformanceComparison: { list: [], loadMore: false }
            };
        case PERFORMANCE_REPORT_DATE_SELECTION_SUCCESS:
            let newDt = { dates: payload.selectedDate, "savedOn": (new Date()).getDate() }
            setLocalStorageValue("Performance_Report_Month_Selection", JSON.stringify(newDt))
            return {
                ...state,
                performenceReportDateRange: { dateRange: payload.selectedDate, label: "Select Month" }
            }

        // user and group dropdown code bellow
        case FETCH_USERS_PERFORMANCE_DROPDOWN_SUCCESS:
            selectedUsers = JSON.parse(getLocalStorageValue("Performance_Report_User_Selection"))
            selectedGroups = JSON.parse(getLocalStorageValue("Performance_Report_Group_Selection"))

            payload.listUsersDropDown.users.forEach((user) => {
                user['checked'] = false
            })

            listUsers = payload.listUsersDropDown.users
            const filteredAddMembers = selectedUsers != null ? listUsers.filter(item => !selectedUsers.some(a => a.id === item.id)) : payload.listUsersDropDown.users

            listGroups = payload.listUsersDropDown.groups
            payload.listUsersDropDown.groups.forEach((group) => {
                group['checked'] = false
                if (selectedGroups) {
                    selectedGroups.forEach((selectedGroup) => {
                        if (selectedGroup.id === group.id) group.checked = true
                    })
                }
            })

            return {
                ...state,
                performanceReportUsersDropDown: { users: filteredAddMembers, groups: payload.listUsersDropDown.groups }
            }

        case FETCH_USERS_PERFORMANCE_DROPDOWN_FAILED:
            return {
                ...state,
                performanceReportUsersDropDown: { users: [], groups: [] },
            }

        // tested
        case REPORT_PERFORMANCE_GROUP_SELECTION_UPDATE:

            let newUserListState_0 = state.performanceReportUsersDropDown
            newUserListState_0.groups.forEach((group, index) => {
                if (payload.changedGroup.id === group.id) {
                    group.checked = payload.changedGroup.checked
                } else {
                    group.checked = false
                }
            })
            setLocalStorageValue("Performance_Report_Group_Selection", JSON.stringify(newUserListState_0.groups.filter(obj => obj.checked === true)))
            return {
                ...state,
                performanceReportUsersDropDown: { users: newUserListState_0.users, groups: newUserListState_0.groups }
            }

        // tested
        case REPORT_PERFORMANCE_USER_ADDED_SUCCESS:
            let users = state.performanceSelectedUsers
            users = users.concat(payload.userAdded)
            setLocalStorageValue("Performance_Report_User_Selection", JSON.stringify(users))
            let searched = state.performanceReportUsersDropDown.users
            var filter_0 = searched.filter(a => !(a === payload.userAdded))
            return {
                ...state,
                performanceSelectedUsers: users,
                performanceReportUsersDropDown: { users: filter_0, groups: state.performanceReportUsersDropDown.groups }
            }

        // tested
        case REPORT_PERFORMANCE_USER_REMOVE_SUCCESS:
            let uzers = state.performanceReportUsersDropDown.users
            if (listUsers.some(obj => obj.id === payload.userRemoved.id)) {
                payload.userRemoved.checked = false
                uzers.push(payload.userRemoved)
                uzers.sort((a, b) => a.name.localeCompare(b.name))
            }

            let arr = state.performanceSelectedUsers
            var filter_1 = arr.filter(a => !(a === payload.userRemoved))
            setLocalStorageValue("Performance_Report_User_Selection", JSON.stringify(filter_1))
            return {
                ...state,
                performanceSelectedUsers: filter_1,
                performanceReportUsersDropDown: { users: uzers, groups: state.performanceReportUsersDropDown.groups }
            }

        // tested
        case REPORT_PERFORMANCE_USERS_CLEAR_SUCCESS:
            listUsers.forEach((user) => {
                user['checked'] = false
            })

            setLocalStorageValue("Performance_Report_User_Selection", JSON.stringify(null))
            return {
                ...state,
                performanceSelectedUsers: [],
                performanceReportUsersDropDown: { users: listUsers, groups: state.performanceReportUsersDropDown.groups }
            }

        // tested
        case REPORT_PERFORMANCE_GROUPS_CLEAR_SUCCESS:
            listGroups.forEach((group) => {
                group['checked'] = false
            })

            setLocalStorageValue("Performance_Report_Group_Selection", JSON.stringify(null))
            return {
                ...state,
                performanceReportUsersDropDown: { users: state.performanceReportUsersDropDown.users, groups: listGroups }
            }
        // end users dropdown


        default:
            return state
    }
}