import {
    FETCH_BILLING_DETAILS_SUCCESS,
    FETCH_BILLING_DETAILS_FAILED,
    FETCH_BILLING_UPGRADE_DETAILS_SUCCESS,
    FETCH_BILLING_UPGRADE_DETAILS_FAILED
} from "../actions/Types";

let initialState = {
    billingInformation: {
        "data": {
            "package": "",
            "duration": "",
            "allowedUsers": 0,
            "endDate": "0000-00-00 00:00:00"
        }
    },
    billingUpgrade: {
        "data": {
            "packagePricePerMonthPerUser": "0.00",
            "numberOfNewUsers": "0",
            "newUsersBasePrice": "00.00",
            "discountPercent": "0",
            "discountAmount": "0.00",
            "newUsersDiscountedPrice": "00.00",
            "numberOfOldUsers": "0",
            "oldUserBasePrice": "000.00",
            "balanceAmount": "000.00",
            "oldUsersPriceDiffrential": "000.00",
            "payableAmount": "000.00",
            "numberOfUsersNewQuota": "00",
            "nextBillingDate": "0000-00-00"
        }
    },
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {

        case FETCH_BILLING_DETAILS_SUCCESS:
            return {
                ...state,
                billingInformation: payload.billingDetails,
                billingUpgrade: {
                    "data": {
                        "packagePricePerMonthPerUser": "0.00",
                        "numberOfNewUsers": "0",
                        "newUsersBasePrice": "00.00",
                        "discountPercent": "0",
                        "discountAmount": "0.00",
                        "newUsersDiscountedPrice": "00.00",
                        "numberOfOldUsers": payload.billingDetails.data.allowedUsers,
                        "oldUserBasePrice": "000.00",
                        "balanceAmount": "000.00",
                        "oldUsersPriceDiffrential": "000.00",
                        "payableAmount": "000.00",
                        "numberOfUsersNewQuota": "00",
                        "nextBillingDate": "0000-00-00"
                    }
                }
            };

        case FETCH_BILLING_DETAILS_FAILED:
            return {
                ...state,
                billingInformation: {
                    "data": {
                        "package": "",
                        "duration": "",
                        "allowedUsers": 0,
                        "endDate": "0000-00-00 00:00:00"
                    }
                },
            }
        case FETCH_BILLING_UPGRADE_DETAILS_SUCCESS:
            return {
                ...state,
                billingUpgrade: payload.billingData,
            };

        case FETCH_BILLING_UPGRADE_DETAILS_FAILED:
            return {
                ...state,
                billingUpgrade: {
                    "data": {
                        "packagePricePerMonthPerUser": "0.00",
                        "numberOfNewUsers": "0",
                        "newUsersBasePrice": "00.00",
                        "discountPercent": "0",
                        "discountAmount": "0.00",
                        "newUsersDiscountedPrice": "00.00",
                        "numberOfOldUsers": "0",
                        "oldUserBasePrice": "000.00",
                        "balanceAmount": "000.00",
                        "oldUsersPriceDiffrential": "000.00",
                        "payableAmount": "000.00",
                        "numberOfUsersNewQuota": "00",
                        "nextBillingDate": "0000-00-00"
                    }
                },
            }

        default:
            return state;
    }
};