import React, { useState } from 'react'
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import './style.css';
import { useLocation } from 'react-router-dom';

const SearchableSingleUser = (props) => {
	const { loading, onChangeSearchWord, userDashboardUsersDropDown, onUserSelectionChange, onBlurHandle, selectedUser, userProfile, leftNavExpand } = props;

	const location = useLocation();
	const isUserDashboard = location.pathname === "/user-dashboard"; // Check if current page is "/user-dashboard"
	// If user-dashboard, do not add 'loader-fix'
	const loaderClass = isUserDashboard ? "" : "loader-fix";
	const [show, setShow] = useState(false);
	const handleShow = () => {
		setShow(true);
	}
	const handleClose = () => {
		setShow(false)
		onBlurHandle(checkSelectedUser());
	};

	function checkSelectedUser() {
		let b = userDashboardUsersDropDown.users.find(x => x.checked) || null;
		if (b !== null && b !== undefined) {
			return { id: b.id, name: b.name };
		}
		// else if ((b === undefined || b === null) && selectedUser !== null) {
		else if (selectedUser !== null) {
			return { id: selectedUser.id, name: selectedUser.name };
		}
		else {
			return { id: userProfile.id, name: userProfile.name };
		}
	}

	const [keyword, setKeyword] = useState("");
	const onChangeKeyword = (e) => {
		setKeyword(e.target.value);
		onChangeSearchWord(e.target.value);
	};
	return (
		<>
			<div className="all-user wwww d-flex align-items-center my-md-1 py-2 mt-0 ">
				<div className='user-drop'>
					<Button variant="primary" onClick={handleShow}> {checkSelectedUser() ? checkSelectedUser().name : "Select User"} </Button>
					<Modal show={show} onHide={handleClose} className={"user-dropdown " + leftNavExpand}>
						<div className='tabs-form mt-3'>
							<Form>
								<Form.Group controlId="formBasicEmail" className='input-wrap'>
									{loading &&
										<p className={`height-loder dropdown-lodar-start text-center text-black mb-0 ${loaderClass}`}>
											<span className="spinner-border spinner-border-sm"> </span>
										</p>}
									<Form.Control type="text" autoComplete='off' placeholder="Search" value={keyword} onFocus={() => { setKeyword(""); }} onChange={onChangeKeyword} />
									<span className='search-icon'>
										<img src='images/search-icon-gray.svg' />
									</span>
								</Form.Group>
							</Form>
						</div>

						<div className='m-0 mb-3'>
							<InputGroup>
								{userDashboardUsersDropDown.users.map((s, index) => (
									<Form.Check
										inline
										label={s.name}
										name="group1"
										type="radio"
										id={`uids_${s.id}`}
										key={index}
										checked={s.checked}
										onChange={(e) => {
											if (s.checked) {
												s.checked = false;
											}
											else {
												s.checked = true;
											}
											onUserSelectionChange({ id: s.id, name: s.name });
											if (keyword.length > 0) onChangeSearchWord("");
											handleClose();
										}
										}
										className="d-flex align-items-center w-100 padding uxc"
									/>
								))}
							</InputGroup>
						</div>

					</Modal>
				</div>
			</div>
		</>
	)
}
export default SearchableSingleUser