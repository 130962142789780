import React, { useState, useRef, useEffect } from "react";
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector, connect } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import './style.css';
import { signup, SocialLogin } from "../../redux/actions/AuthAction";
import { fetchLogedInUserProfile } from "../../redux/actions/UserAction";
import { CLEAR_MESSAGE } from "../../redux/actions/Types";
import { fetchUserCompanies } from "../../redux/actions/UserAction";
import { allowedBasedOnRole } from "../../common/GlobalFunctions";
import { ADMIN, GROUPADMIN, OWNER } from '../../utils/Constants';


const SignUp = (props) => {
  const { selectedCompany } = props;
  const formRef = useRef(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [compChk, setcompChk] = useState(false);
  const { isLoggedIn } = useSelector(state => state.AuthReducer);
  const { message } = useSelector(state => state.MessageReducer);
  const [msg, setMsg] = useState(message);
  const [singIn, setSignIn] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [showMessage, setShowMessage] = useState(false);



  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const [isRePasswordVisible, setIsRePasswordVisible] = useState(false);
  const toggleRePasswordVisibility = () => {
    setIsRePasswordVisible(!isRePasswordVisible);
  };


  useEffect(() => {
    setShowMessage(false);
    dispatch({
      type: CLEAR_MESSAGE,
    });
    if (isLoggedIn) {
      navigate(allowedBasedOnRole([ADMIN, OWNER, GROUPADMIN], selectedCompany.userRole) === true ? "/team-dashboard" : "/");
    }
  }, [])

  useEffect(() => {
    setMsg(message);
    setShowMessage(true);

  }, [message])

  const searchParams = new URLSearchParams(window.location.search);
  useEffect(() => {
    const status = searchParams.get("status");
    if (status === 'success') {
      const token = searchParams.get("token");
      handleSocialLogin(token)
    }
  }, [searchParams.get('status')])


  const onChangeName = (e) => {
    setName(e.target.value);
  };
  const onChangeEmail = (e) => {
    const username_inp = e.target.value;
    setEmail(username_inp.toLowerCase());
  };
  const onChangePassword = (e) => {
    const password_inp = e.target.value;
    setPassword(password_inp);
  };
  const onChangeRePassword = (e) => {
    const password_inp = e.target.value;
    setRePassword(password_inp);
  };
  const handleLogin = (e) => {
    e.preventDefault();

    if (password !== rePassword) {
      setMsg("The passwords do not match.")
      return;
    }

    if (password.length < 6 || rePassword.length < 6) {
      setMsg("The password must be at least 6 characters.")
      return;
    }



    setMsg("");
    setLoading(true);
    setShowMessage(false);
    dispatch({
      type: CLEAR_MESSAGE,
    });

    dispatch(signup(name, email, password, rePassword))
      .then(() => {
        dispatch(fetchLogedInUserProfile()).then(() => {
          dispatch(fetchUserCompanies())
            .then(() => {
              setLoading(false);
              setSignIn(true);
              setcompChk(true);
              setShowMessage(false);
            })
        })
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const handleSocialLogin = (eToken) => {
    setMsg("");
    setLoading(true);
    setShowMessage(false);
    dispatch({
      type: CLEAR_MESSAGE,
    });

    dispatch(SocialLogin(eToken))
      .then(() => {
        // console.log('then')
        dispatch(fetchLogedInUserProfile()).then(() => {
          dispatch(fetchUserCompanies())
            .then(() => {
              setLoading(false);
              setSignIn(true);
              setcompChk(true);
              setShowMessage(false);
            })
        })
      })
      .catch(() => {
        // setLoading(false);
      });
  };
  if (isLoggedIn && singIn && compChk) {
    navigate("/company-selection")
  }

  return (
    <>
      <div className='login login-pagee signup-page'>
        <Row className='m-0'>
          <Col xs={12} md={6} className='box padding-left pb-5 d-flex align-items-center'>
            <div className="max-width-login px-1">

            <h2>Sign Up</h2>
            <h3 className="mt-2 text-center mb-3">TimeBee - Begin your journey towards productivity! </h3>
            {/* <p className='free-trial mb-3'>One click and countless business
              advantages await you!</p> */}
            <div className="mt-2">
              <div className=" d-flex justify-content-center google-facebook-icons">
                <Link className="btn btn-primary" to={'https://wapi.timebee.app/auth/google'}><img src={"images/google-icon.svg"} /></Link>
                <Link className="btn btn-primary" to={'https://wapi.timebee.app/auth/facebook'}><img src={"images/facebook-icon.svg"} /></Link>
              </div>
            </div>
            <div className="or d-flex align-items-center justify-content-center my-2 mb-0"><span className="line"></span><p className="m-0 mx-3">or</p><span className="line"></span></div>

            <Form onSubmit={handleLogin} ref={formRef}>
              <Form.Group className="mb-2 mb-xxl-3" controlId="formBasicName">
                <Form.Label className='fs-16 font-medium m-0 mb-2'>Name</Form.Label>
                <Form.Control type="text" placeholder="Enter your full name" onChange={onChangeName} autoComplete="off" required />
              </Form.Group>
              <Form.Group className="mb-2 mb-xxl-3" controlId="formBasicEmail">
                <Form.Label className='fs-16 font-medium m-0 mb-2'>Email</Form.Label>
                <Form.Control type="email" placeholder="Enter your email" onChange={onChangeEmail} autoComplete="off" required />
              </Form.Group>

              <Form.Group className="mb-2 mb-xxl-3" controlId="formBasicPassword">
                <Form.Label className='fs-16 font-medium m-0 mb-2'>Password</Form.Label>

                <div className="password-box">
                  <Form.Control type={isPasswordVisible ? 'text' : 'password'} onChange={onChangePassword} autoComplete="off" placeholder="Enter your password" required />

                  <span
                    className="show-hide-password"
                    onClick={togglePasswordVisibility}
                  >
                    {isPasswordVisible ? <img src="images/openEye.svg" alte="Show" /> : <img src="images/closeEye.svg" alte="Hide" />}
                  </span>
                </div>
              </Form.Group>
              <Form.Group className="mb-2 mb-xxl-3" controlId="formBasicRePassword">
                <Form.Label className='fs-16 font-medium m-0 mb-2'>Re-enter Password</Form.Label>

                <div className="password-box">
                  <Form.Control type={isRePasswordVisible ? 'text' : 'password'} onChange={onChangeRePassword} autoComplete="off" placeholder="Confirm your password" required />

                  <span
                    className="show-hide-password"
                    onClick={toggleRePasswordVisibility}
                  >
                    {isRePasswordVisible ? <img src="images/openEye.svg" alte="Show" /> : <img src="images/closeEye.svg" alte="Hide" />}
                  </span>
                </div>
              </Form.Group>


              {showMessage && <p className="text-center text-danger m-0">{msg} </p>}

              <Button className='fs-16 text-white font-regular py-1 my-2 create-act ' type="submit" disabled={loading}>
                {loading && (
                  <span className="spinner-border spinner-border-sm">&nbsp;</span>
                )}
                <span>Create My Account</span>
              </Button>
              <p className='text-center'>Already have an account?<Link to="/login" className='ms-2 sign-in'>Login</Link></p>
            </Form>

            <div className="loginlinks d-flex justify-content-center footer-btns mt-1">
              <a target="_blank" href="https://timebee.app/contact-us/">Contact Support</a>
              <span className="mx-2">|</span>
              <a target="_blank" href="https://timebee.app/privacy-policy/" className="b2">Privacy Policy</a>
              <span className="mx-2 hide-border">|</span>
              <a target="_blank" href="https://timebee.app/terms-of-service/">Terms of Services</a>
            </div>
            </div>
          </Col>
          <Col xs={12} md={6} className='bg-colore box d-none d-md-block d-flex align-items-center'>
            <div className='img'>
              <img src={"images/signup.png"} />
            </div>
            <div className="mt-1">
              <h2 className='mb-3 text-white text-center'>Step into Tomorrow and Join the TimeBee Revolution!</h2>

            </div>
            <div className='img'>
              <img src={"images/signup-logos.png"} />
            </div>

          </Col>
        </Row>
      </div>
    </>

  )
}
const mapStateToProps = state => ({
  userCompanies: state.UserReducer.userCompanies,
  selectedCompany: state.UserReducer.selectedCompany,
  isLoggedIn: state.AuthReducer.isLoggedIn,
  userProfile: state.UserReducer.userProfile,
});

export default connect(mapStateToProps)(SignUp);