import subDays from 'date-fns/subDays';
import {
  FETCH_DASHBOARD_COMULATIVETIME_SUCCESS,
  FETCH_DASHBOARD_COMULATIVETIME_FAILED,
  FETCH_DASHBOARD_TRACKING_MOST_HOURS_SUCCESS,
  FETCH_DASHBOARD_TRACKING_MOST_HOURS_FAILED,
  FETCH_DASHBOARD_TRACKING_LEAST_HOURS_SUCCESS,
  FETCH_DASHBOARD_TRACKING_LEAST_HOURS_FAILED,
  FETCH_DASHBOARD_TOP_USED_WEBSITES_APPS_PRODUCTIVE_SUCCESS,
  FETCH_DASHBOARD_TOP_USED_WEBSITES_APPS_PRODUCTIVE_FAILED,
  FETCH_DASHBOARD_TOP_USED_WEBSITES_APPS_UNPRODUCTIVE_SUCCESS,
  FETCH_DASHBOARD_TOP_USED_WEBSITES_APPS_UNPRODUCTIVE_FAILED,
  FETCH_DASHBOARD_DASHBOARD_TOP_USERS_PRODUCTIVE_SUCCESS,
  FETCH_DASHBOARD_DASHBOARD_TOP_USERS_PRODUCTIVE_FAILED,
  FETCH_DASHBOARD_DASHBOARD_TOP_USERS_UNPRODUCTIVE_SUCCESS,
  FETCH_DASHBOARD_DASHBOARD_TOP_USERS_UNPRODUCTIVE_FAILED,
  FETCH_DASHBOARD_USERS_DROPDOWN_SUCCESS,
  FETCH_DASHBOARD_USERS_DROPDOWN_FAILED,
  FETCH_DASHBOARD_TOP_PROJECTS_SUCCESS,
  FETCH_DASHBOARD_TOP_PROJECTS_FAILED,
  DASHBOARD_DATE_SELECTION_SUCCESS,
  DASHBOARD_USER_SELECTION_SUCCESS,
  DASHBOARD_GROUP_SELECTION_UPDATE,
  TEAMDASHBOARD_USER_REMOVE_SUCCESS,
  TEAMDASHBOARD_USER_ADDED_SUCCESS,
  TEAMDASHBOARD_USERS_CLEAR_SUCCESS,
  TEAMDASHBOARD_GROUPS_CLEAR_SUCCESS,
} from "../actions/Types";
import { getLocalStorageValue, setLocalStorageValue } from '../../utils/PersistanceManager';

let selectedUsers = JSON.parse(getLocalStorageValue("Dashboard_User_Selection"));
let selectedGroups = JSON.parse(getLocalStorageValue("Dashboard_Group_Selection"));
let selectedDateRange = ["2023-10-31T19:00:00.410Z", "2023-11-29T19:00:00.410Z"];
let listUsers = [];
let listGroups = [];

let initialState = {
  dashboardComulativeTime: { activeUser: 0, manualTime: 0, neutralTime: 0, productiveTime: 0, totalTime: 0, unProductiveTime: 0, unRatedTime: 0 },
  dashboardTopProjects: { projects: [], },
  dashboardTopUsersProductive: [],
  dashboardTopUsersUnProductive: [],
  dashboardTopUsedWebsitesAppsProductive: [],
  dashboardTopUsedWebsitesAppsUnProductive: [],
  dashboardTrackingMostHours: [],
  dashboardTrackingLeastHours: [],
  dashboardUsersDropDown: { users: [], groups: [] },
  dashboardDateRange: { dateRange: [new Date(selectedDateRange[0]), new Date(selectedDateRange[1])] },
  dashboardSelectedUsers: selectedUsers ? selectedUsers : [],
};



export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_DASHBOARD_COMULATIVETIME_SUCCESS:
      return {
        ...state,
        dashboardComulativeTime: payload.dashboardComulativeTime,
      };

    case FETCH_DASHBOARD_COMULATIVETIME_FAILED:
      return {
        ...state,
        dashboardComulativeTime: { activeUser: 0, manualTime: 0, neutralTime: 0, productiveTime: 0, timeTracked: 0, unProductiveTime: 0, unRatedTime: 0 },
      };

    case FETCH_DASHBOARD_TOP_USED_WEBSITES_APPS_PRODUCTIVE_SUCCESS:
      let productiveWebnApps = payload.dashboardTopUsedWebsitesAppsProductive.catalog.sort((a, b) => b.timeTracked - a.timeTracked);
      return {
        ...state,
        dashboardTopUsedWebsitesAppsProductive: productiveWebnApps.slice(0, 6),
      };

    case FETCH_DASHBOARD_TOP_USED_WEBSITES_APPS_PRODUCTIVE_FAILED:
      return {
        ...state,
        dashboardTopUsedWebsitesAppsProductive: [],
      };

    case FETCH_DASHBOARD_TOP_USED_WEBSITES_APPS_UNPRODUCTIVE_SUCCESS:
      let unProductiveWebnApps = payload.dashboardTopUsedWebsitesAppsUnProductive.catalog.sort((a, b) => b.timeTracked - a.timeTracked);
      return {
        ...state,
        dashboardTopUsedWebsitesAppsUnProductive: unProductiveWebnApps.slice(0, 6),
      };

    case FETCH_DASHBOARD_TOP_USED_WEBSITES_APPS_UNPRODUCTIVE_FAILED:
      return {
        ...state,
        dashboardTopUsedWebsitesAppsUnProductive: [],
      };

    case FETCH_DASHBOARD_TRACKING_MOST_HOURS_SUCCESS:
      let mostHours = payload.dashboardTrackingMostHours.users.sort((a, b) => b.timeTracked - a.timeTracked);
      return {
        ...state,
        dashboardTrackingMostHours: mostHours.slice(0, 6),
      };

    case FETCH_DASHBOARD_TRACKING_MOST_HOURS_FAILED:
      return {
        ...state,
        dashboardTrackingMostHours: [],
      };

    case FETCH_DASHBOARD_TRACKING_LEAST_HOURS_SUCCESS:
      let lestHours = payload.dashboardTrackingLeastHours.users.sort((a, b) => a.timeTracked - b.timeTracked);
      return {
        ...state,
        dashboardTrackingLeastHours: lestHours.slice(0, 6),
      };

    case FETCH_DASHBOARD_TRACKING_LEAST_HOURS_FAILED:
      return {
        ...state,
        dashboardTrackingLeastHours: [],
      };

    case FETCH_DASHBOARD_DASHBOARD_TOP_USERS_PRODUCTIVE_SUCCESS:
      let topUsersProductive = payload.dashboardTopUsersProductive.users.sort((a, b) => b.percentage - a.percentage);
      return {
        ...state,
        dashboardTopUsersProductive: topUsersProductive.slice(0, 8),
      };

    case FETCH_DASHBOARD_DASHBOARD_TOP_USERS_PRODUCTIVE_FAILED:
      return {
        ...state,
        dashboardTopUsersProductive: [],
      };

    case FETCH_DASHBOARD_DASHBOARD_TOP_USERS_UNPRODUCTIVE_SUCCESS:
      let topUsersUnProductive = payload.dashboardTopUsersUnProductive.users.sort((a, b) => b.percentage - a.percentage);
      return {
        ...state,
        dashboardTopUsersUnProductive: topUsersUnProductive.slice(0, 8),
      };

    case FETCH_DASHBOARD_DASHBOARD_TOP_USERS_UNPRODUCTIVE_FAILED:
      return {
        ...state,
        dashboardTopUsersUnProductive: [],
      };

    case FETCH_DASHBOARD_USERS_DROPDOWN_SUCCESS:
      selectedUsers = JSON.parse(getLocalStorageValue("Dashboard_User_Selection"));
      selectedGroups = JSON.parse(getLocalStorageValue("Dashboard_Group_Selection"));

      payload.dashboardUsersDropDown.users.forEach((user) => {
        user['checked'] = false;
      });

      listUsers = payload.dashboardUsersDropDown.users;
      const filteredAddMembers = selectedUsers != null ? listUsers.filter(item => !selectedUsers.some(a => a.id === item.id)) : payload.dashboardUsersDropDown.users;

      listGroups = payload.dashboardUsersDropDown.groups;
      payload.dashboardUsersDropDown.groups.forEach((group) => {
        group['checked'] = false;
        if (selectedGroups) {
          selectedGroups.forEach((selectedGroup) => {
            if (selectedGroup.id === group.id) group.checked = true;
          })
        }
      });

      return {
        ...state,
        dashboardUsersDropDown: { users: filteredAddMembers, groups: payload.dashboardUsersDropDown.groups }
      };

    case FETCH_DASHBOARD_USERS_DROPDOWN_FAILED:
      return {
        ...state,
        dashboardUsersDropDown: { users: [], groups: [] },
      };

    case FETCH_DASHBOARD_TOP_PROJECTS_SUCCESS:
      const firstFiveValues = (payload.dashboardTopProjects.projects).slice(0, 5);
      return {
        ...state,
        dashboardTopProjects: { "projects": firstFiveValues },
      };

    case FETCH_DASHBOARD_TOP_PROJECTS_FAILED:
      return {
        ...state,
        dashboardTopProjects: { projects: [] },
      };

    case DASHBOARD_DATE_SELECTION_SUCCESS:
      let newDt = { dates: payload.selectedDate, "savedOn": (new Date()).getDate() }
      setLocalStorageValue("Team_Dashboard_Date_Selection", JSON.stringify(newDt));
      return {
        ...state,
        dashboardDateRange: { dateRange: payload.selectedDate, label: "Select Date" }
      };

    case DASHBOARD_USER_SELECTION_SUCCESS:
      setLocalStorageValue("Dashboard_User_Selection", JSON.stringify(payload.selectedUsers));
      return {
        ...state,
        dashboardSelectedUsers: payload.selectedUsers
      };

    case DASHBOARD_GROUP_SELECTION_UPDATE:

      let newUserListState = state.dashboardUsersDropDown
      newUserListState.groups.forEach((group, index) => {
        if (payload.changedGroup.id === group.id) {
          group.checked = payload.changedGroup.checked;
        } else {
          group.checked = false
        }
      });
      setLocalStorageValue("Dashboard_Group_Selection", JSON.stringify(newUserListState.groups.filter(obj => obj.checked === true)));
      return {
        ...state,
        dashboardUsersDropDown: { users: newUserListState.users, groups: newUserListState.groups }
      };

    case TEAMDASHBOARD_USER_ADDED_SUCCESS:
      let users = state.dashboardSelectedUsers;
      users = users.concat(payload.userAdded);
      setLocalStorageValue("Dashboard_User_Selection", JSON.stringify(users));
      let searched = state.dashboardUsersDropDown.users;
      var filter = searched.filter(a => !(a === payload.userAdded));
      return {
        ...state,
        dashboardSelectedUsers: users,
        dashboardUsersDropDown: { users: filter, groups: state.dashboardUsersDropDown.groups }
      };

    case TEAMDASHBOARD_USER_REMOVE_SUCCESS:
      let uzers = state.dashboardUsersDropDown.users;
      if (listUsers.some(obj => obj.id === payload.userRemoved.id)) {
        payload.userRemoved.checked = false;
        uzers.push(payload.userRemoved);
        uzers.sort((a, b) => a.name.localeCompare(b.name));
      }

      let arr = state.dashboardSelectedUsers;
      var filter1 = arr.filter(a => !(a === payload.userRemoved));
      setLocalStorageValue("Dashboard_User_Selection", JSON.stringify(filter1));
      return {
        ...state,
        dashboardSelectedUsers: filter1,
        dashboardUsersDropDown: { users: uzers, groups: state.dashboardUsersDropDown.groups }
      };
    case TEAMDASHBOARD_USERS_CLEAR_SUCCESS:
      listUsers.forEach((user) => {
        user['checked'] = false;
      });

      setLocalStorageValue("Dashboard_User_Selection", JSON.stringify(null));
      return {
        ...state,
        dashboardSelectedUsers: [],
        dashboardUsersDropDown: { users: listUsers, groups: state.dashboardUsersDropDown.groups }
      };
    case TEAMDASHBOARD_GROUPS_CLEAR_SUCCESS:
      // console.log("listGroups: ", listGroups);
      listGroups.forEach((group) => {
        group['checked'] = false;
      });

      setLocalStorageValue("Dashboard_Group_Selection", JSON.stringify(null));
      return {
        ...state,
        dashboardUsersDropDown: { users: state.dashboardUsersDropDown.users, groups: listGroups }
      };

    default:
      return state;
  }
}
