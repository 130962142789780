import {
    SET_MESSAGE,
    FETCH_WEBS_AND_APPS_USERS_DROPDOWN_SUCCESS,
    FETCH_WEBS_AND_APPS_USERS_DROPDOWN_FAILED,
    FETCH_WEBS_AND_APPS_USAGE_DETAILS_FAILED,
    FETCH_WEBS_AND_APPS_USAGE_DETAILS_SUCCESS,
    FETCH_MORE_WEBS_AND_APPS_USAGE_DETAILS_FAILED,
    FETCH_MORE_WEBS_AND_APPS_USAGE_DETAILS_SUCCESS,
    FETCH_WEBS_AND_APPS_USERS_DROPDOWN_LIST_SUCCESS,
    FETCH_WEBS_AND_APPS_USERS_DROPDOWN_LIST_FAILED,
    INTERNET_AVAILABILITY,
    FETCH_WEBS_AND_APPS_USERS_USAGE,
    FETCH_WEBS_AND_APPS_USERS_USAGE_FAILED,
    FETCH_WEBS_AND_APPS_MORE_USERS_USAGE,
    FETCH_MORE_CATALOG_TITLE_DETAILS_FAILED,
    FETCH_MORE_CATALOG_TITLE_DETAILS_SUCCESS,
    FETCH_CATALOG_TITLE_DETAILS_FAILED,
    FETCH_CATALOG_TITLE_DETAILS_SUCCESS
} from "./Types";
import WebnAppsService from "../../services/WebnAppsService";
import UserDashboardService from "../../services/UserDashboardService";

import { getActiveTimezone, toIsoDate } from '../../common/GlobalFunctions'


export const fetchWebnAppsUsersDropDown = (orgId) => (dispatch) => {

    var data = {
        "organizationId": orgId
    }
    return WebnAppsService.fetchWebnAppsUsersDropDown(data).then(
        (data) => {
            if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
                dispatch({
                    type: FETCH_WEBS_AND_APPS_USERS_DROPDOWN_SUCCESS,
                    payload: { webnAppsUsersDropDown: data },
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: true },
                });
                return Promise.resolve();
            }
            else if (data === "ERR_NETWORK") {
                dispatch({
                    type: FETCH_WEBS_AND_APPS_USERS_DROPDOWN_FAILED,
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: false },
                });
                return Promise.reject();
            }
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: FETCH_WEBS_AND_APPS_USERS_DROPDOWN_FAILED,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};

export const fetchWebnAppsUsageDetails = (orgId, dateRange, user, groups, basedOn, sortBy, scope, ratingScopeId, ratingType, skip, limit) => (dispatch) => {

    var data = {
        "organizationId": orgId,
        "startTime": toIsoDate(dateRange.dateRange[0], 0),
        "endTime": toIsoDate(dateRange.dateRange[1], 1),
        "users": user,
        "groups": groups,
        "basedOn": basedOn,
        "timetrack_sort": sortBy,
        "ratingScope": scope,
        "ratingScopeId": ratingScopeId,
        "ratingType": ratingType,
        "skip": skip,
        "limit": limit,
        "timezone": getActiveTimezone().timeZoneOffset
    }

    return WebnAppsService.fetchWebnAppsUsageDetails(data).then(
        (data) => {
            if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
                let loadMore = true;
                if (data.catalogUsage.length >= limit) {
                    loadMore = true;
                } else {
                    loadMore = false;
                }
                dispatch({
                    type: FETCH_WEBS_AND_APPS_USAGE_DETAILS_SUCCESS,
                    payload: { webnAppsUsageDetails: data, loadMore: loadMore },
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: true },
                });
                return Promise.resolve();
            }
            else if (data === "ERR_NETWORK") {
                dispatch({
                    type: FETCH_WEBS_AND_APPS_USAGE_DETAILS_FAILED,
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: false },
                });
                return Promise.reject();
            }
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: FETCH_WEBS_AND_APPS_USAGE_DETAILS_FAILED,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};
export const fetchMoreWebnAppsUsageDetails = (orgId, dateRange, user, groups, basedOn, sortBy, scope, ratingScopeId, ratingType, skip, limit) => (dispatch) => {

    var data = {
        "organizationId": orgId,
        "startTime": toIsoDate(dateRange.dateRange[0], 0),
        "endTime": toIsoDate(dateRange.dateRange[1], 1),
        "users": user,
        "groups": groups,
        "basedOn": basedOn,
        "timetrack_sort": sortBy,
        "ratingScope": scope,
        "ratingScopeId": ratingScopeId,
        "ratingType": ratingType,
        "skip": skip,
        "limit": limit,
        "timezone": getActiveTimezone().timeZoneOffset
    }
    return WebnAppsService.fetchWebnAppsUsageDetails(data).then(
        (data) => {
            if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
                let loadMore = true;
                if (data.catalogUsage.length >= limit) {
                    loadMore = true;
                } else {
                    loadMore = false;
                }
                dispatch({
                    type: FETCH_MORE_WEBS_AND_APPS_USAGE_DETAILS_SUCCESS,
                    payload: { webnAppsUsageDetails: data, loadMore: loadMore },
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: true },
                });
                return Promise.resolve();
            }
            else if (data === "ERR_NETWORK") {
                dispatch({
                    type: FETCH_MORE_WEBS_AND_APPS_USAGE_DETAILS_FAILED,
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: false },
                });
                return Promise.reject();
            }
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: FETCH_MORE_WEBS_AND_APPS_USAGE_DETAILS_FAILED,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};

// user and group dropdown function
export const fetchSearchableUsersGroups = (orgId, searchKeyword, skip, limit) => (dispatch) => {
    var data = {
        "organizationId": orgId,
        "search": searchKeyword,
        "skip": skip,
        "limit": limit
    }
    return WebnAppsService.fetchWebnAppsUsersGroups(data).then(
        (data) => {
            if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
                dispatch({
                    type: FETCH_WEBS_AND_APPS_USERS_DROPDOWN_LIST_SUCCESS,
                    payload: { listUsersDropDown: data },
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: true },
                });
                return Promise.resolve();
            }
            else if (data === "ERR_NETWORK") {
                dispatch({
                    type: FETCH_WEBS_AND_APPS_USERS_DROPDOWN_LIST_FAILED,
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: false },
                });
                return Promise.reject();
            }
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: FETCH_WEBS_AND_APPS_USERS_DROPDOWN_LIST_FAILED,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};

// user list of catalog users
export const fetchCatalogTimeTrackUsers = (orgId, dateRange, catalogId, skip, limit) => (dispatch) => {
    var data = {
        "organizationId": orgId,
        "startTime": toIsoDate(dateRange.dateRange[0], 0),
        "endTime": toIsoDate(dateRange.dateRange[1], 1),
        "catalogId": catalogId,
        "skip": skip,
        "limit": limit,
        "timezone": getActiveTimezone().timeZoneOffset
    }
    return WebnAppsService.fetchWebnAppsCatalogUsers(data).then(
        (data) => {
            if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
                let loadMore = true;
                if (data.catalogUser.length >= limit) {
                    loadMore = true;
                } else {
                    loadMore = false;
                }
                dispatch({
                    type: FETCH_WEBS_AND_APPS_USERS_USAGE,
                    payload: { listUsers: data, loadMore: loadMore },
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: true },
                });
                return Promise.resolve();
            }
            else if (data === "ERR_NETWORK") {
                dispatch({
                    type: FETCH_WEBS_AND_APPS_USERS_USAGE_FAILED,
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: false },
                });
                return Promise.reject();
            }
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};

export const fetchMoreCatalogTimeTrackUsers = (orgId, dateRange, catalogId, skip, limit) => (dispatch) => {
    var data = {
        "organizationId": orgId,
        "startTime": toIsoDate(dateRange.dateRange[0], 0),
        "endTime": toIsoDate(dateRange.dateRange[1], 1),
        "catalogId": catalogId,
        "skip": skip,
        "limit": limit,
        "timezone": getActiveTimezone().timeZoneOffset
    }
    return WebnAppsService.fetchWebnAppsCatalogUsers(data).then(
        (data) => {
            if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
                let loadMore = true;
                if (data.catalogUser.length >= limit) {
                    loadMore = true;
                } else {
                    loadMore = false;
                }
                dispatch({
                    type: FETCH_WEBS_AND_APPS_MORE_USERS_USAGE,
                    payload: { listUsers: data, loadMore: loadMore },
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: true },
                });
                return Promise.resolve();
            }
            else if (data === "ERR_NETWORK") {
                dispatch({
                    type: FETCH_WEBS_AND_APPS_USERS_USAGE_FAILED,
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: false },
                });
                return Promise.reject();
            }
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};

export const fetchCatalogTitleDetails = (orgId, user, dateRange, catalogId, skip, limit) => (dispatch) => {

    var data = {
        "organizationId": orgId,
        "startTime": dateRange.dateRange[0],
        "endTime": dateRange.dateRange[1],
        "users": user,
        "catalogId": catalogId,
        "skip": skip,
        "limit": limit,
        "timezone": getActiveTimezone().timeZoneOffset

    }
    return UserDashboardService.fetchSingleCatalogTitle(data).then(
        (data) => {
            if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
                let loadMore = true;
                if (data.catalog.length >= limit) {
                    loadMore = true;
                } else {
                    loadMore = false;
                }

                dispatch({
                    type: FETCH_CATALOG_TITLE_DETAILS_SUCCESS,
                    payload: { catalogTitle: data.catalog, loadMore: loadMore },
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: true },
                });
                return Promise.resolve();
            }
            else if (data === "ERR_NETWORK") {
                dispatch({
                    type: FETCH_CATALOG_TITLE_DETAILS_FAILED,
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: false },
                });
                return Promise.reject();
            }
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: FETCH_CATALOG_TITLE_DETAILS_FAILED,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};

export const fetchMoreCatalogTitleDetails = (orgId, user, dateRange, catalogId, skip, limit) => (dispatch) => {

    var data = {
        "organizationId": orgId,
        "startTime": dateRange.dateRange[0],
        "endTime": dateRange.dateRange[1],
        "users": user,
        "catalogId": catalogId,
        "skip": skip,
        "limit": limit,
        "timezone": getActiveTimezone().timeZoneOffset

    }
    return UserDashboardService.fetchSingleCatalogTitle(data).then(
        (data) => {
            if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
                let loadMore = true;
                if (data.catalog.length >= limit) {
                    loadMore = true;
                } else {
                    loadMore = false;
                }

                dispatch({
                    type: FETCH_MORE_CATALOG_TITLE_DETAILS_SUCCESS,
                    payload: { moreCatalogTitle: data.catalog, loadMore: loadMore },
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: true },
                });
                return Promise.resolve();
            }
            else if (data === "ERR_NETWORK") {
                dispatch({
                    type: FETCH_MORE_CATALOG_TITLE_DETAILS_FAILED,
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: false },
                });
                return Promise.reject();
            }
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: FETCH_MORE_CATALOG_TITLE_DETAILS_FAILED,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};
