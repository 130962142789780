import subDays from 'date-fns/subDays';

import {
    HOURS_TRACKED_DATE_SELECTION_SUCCESS,
    FETCH_HOURS_TRACKED_FAILED,
    FETCH_HOURS_TRACKED_SUCCESS,
    FETCH_MORE_HOURS_TRACKED_SUCCESS,
    FETCH_MORE_HOURS_TRACKED_FAILED,
    FETCH_HOURS_TRACKED_USERS_DROPDOWN_SUCCESS,
    FETCH_HOURS_TRACKED_USERS_DROPDOWN_FAILED,
    HOURS_TRACKED_USER_ADDED_SUCCESS,
    HOURS_TRACKED_USER_REMOVE_SUCCESS,
    HOURS_TRACKED_USERS_CLEAR_SUCCESS,
    HOURS_TRACKED_GROUP_SELECTION_UPDATE,
    HOURS_TRACKED_GROUPS_CLEAR_SUCCESS,
    HOURS_TRACKED_USER_SELECTION_SUCCESS,
    FETCH_CATALOG_TITLE_DETAIL_SUCCESS,
    FETCH_CATALOG_TITLE_DETAIL_FAILED,
    FETCH_MORE_CATALOG_TITLE_DETAIL_SUCCESS,
    // FETCH_MORE_CATALOG_TITLE_DETAIL_FAILED
} from "../actions/Types";
import { getLocalStorageValue, setLocalStorageValue } from '../../utils/PersistanceManager';

let selectedDateRange = ["2023-10-31T19:00:00.410Z", "2023-11-29T19:00:00.410Z"];
let selectedUsers = JSON.parse(getLocalStorageValue("Hours_Tracked_User_Selection"));
let selectedGroups = JSON.parse(getLocalStorageValue("Hours_Tracked_Group_Selection"));
let listUsers = [];
let listGroups = [];

let initialState = {

    hoursTrackedDateRange: { dateRange: [new Date(selectedDateRange[0]), new Date(selectedDateRange[1])] },
    hoursTracked: { "userHoursTracks": [] },
    hoursTrackedUsersDropDown: { users: [], groups: [] },
    hoursTrackedSelectedUsers: selectedUsers ? selectedUsers : [],
    hoursTrackedSelectedGroups: selectedGroups ? selectedGroups : [],
    catalogTitle: {"catalog": []}
};


export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {

        case HOURS_TRACKED_DATE_SELECTION_SUCCESS:
            let newDt = { dates: payload.selectedDate, "savedOn": (new Date()).getDate() }
            setLocalStorageValue("Hours_Tracked_Date_Selection", JSON.stringify(newDt));
            return {
                ...state,
                hoursTrackedDateRange: { dateRange: payload.selectedDate, label: "Select Date" }
            };


        case FETCH_HOURS_TRACKED_SUCCESS:
            return {
                ...state,
                hoursTracked: { "userHoursTracks": payload.hoursTracked.userHoursTracks, loadMore: payload.loadMore, "allUserTotaltime": payload.hoursTracked.allUserTotaltime },
            };

        case FETCH_HOURS_TRACKED_FAILED:
            return {
                ...state,
                hoursTracked: { "userHoursTracks": [] },
            };

        case FETCH_MORE_HOURS_TRACKED_SUCCESS:
            let ss = state.hoursTracked.userHoursTracks;
            let con = ss.concat(payload.hoursTracked.userHoursTracks);
            return {
                ...state,
                hoursTracked: { "userHoursTracks": con, loadMore: payload.loadMore, "allUserTotaltime": payload.hoursTracked.allUserTotaltime },
            };

        case FETCH_MORE_HOURS_TRACKED_FAILED:
            return {
                ...state,
                hoursTracked: { "userHoursTracks": [] },
            };
            
        // user and group dropdown code bellow
        case FETCH_HOURS_TRACKED_USERS_DROPDOWN_SUCCESS:
            selectedUsers = JSON.parse(getLocalStorageValue("Hours_Tracked_User_Selection"));
            selectedGroups = JSON.parse(getLocalStorageValue("Hours_Tracked_Group_Selection"));
        
            payload.listUsersDropDown.users.forEach((user) => {
                user['checked'] = false;
            });
        
            listUsers = payload.listUsersDropDown.users;
            const filteredAddMembers = selectedUsers != null ? listUsers.filter(item => !selectedUsers.some(a => a.id === item.id)) : payload.listUsersDropDown.users;
        
            listGroups = payload.listUsersDropDown.groups;
            payload.listUsersDropDown.groups.forEach((group) => {
                group['checked'] = false;
                if (selectedGroups) {
                selectedGroups.forEach((selectedGroup) => {
                    if (selectedGroup.id === group.id) group.checked = true;
                })
                }
            });
        
            return {
                ...state,
                hoursTrackedUsersDropDown: { users: filteredAddMembers, groups: payload.listUsersDropDown.groups }
            };
        
        case FETCH_HOURS_TRACKED_USERS_DROPDOWN_FAILED:
            return {
                ...state,
                hoursTrackedUsersDropDown: { users: [], groups: [] },
            };

        // tested
        case HOURS_TRACKED_GROUP_SELECTION_UPDATE:

            let newUserListState_0 = state.hoursTrackedUsersDropDown
            newUserListState_0.groups.forEach((group, index) => {
            if (payload.changedGroup.id === group.id) {
                group.checked = payload.changedGroup.checked;
            }else{
                group.checked = false
            }
            });
            setLocalStorageValue("Hours_Tracked_Group_Selection", JSON.stringify(newUserListState_0.groups.filter(obj => obj.checked === true)));
            return {
            ...state,
            hoursTrackedUsersDropDown: { users: newUserListState_0.users, groups: newUserListState_0.groups }
            };

        // tested
        case HOURS_TRACKED_USER_ADDED_SUCCESS:
            let users = state.hoursTrackedSelectedUsers;
            users = users.concat(payload.userAdded);
            setLocalStorageValue("Hours_Tracked_User_Selection", JSON.stringify(users));
            let searched = state.hoursTrackedUsersDropDown.users;
            var filter_0 = searched.filter(a => !(a === payload.userAdded));
            return {
                ...state,
                hoursTrackedSelectedUsers: users,
                hoursTrackedUsersDropDown: { users: filter_0, groups: state.hoursTrackedUsersDropDown.groups }
            };
        
            // tested
        case HOURS_TRACKED_USER_REMOVE_SUCCESS:
            let uzers = state.hoursTrackedUsersDropDown.users;
            if (listUsers.some(obj => obj.id === payload.userRemoved.id)) {
                payload.userRemoved.checked = false;
                uzers.push(payload.userRemoved);
                uzers.sort((a, b) => a.name.localeCompare(b.name));
            }
        
            let arr = state.hoursTrackedSelectedUsers;
            var filter_1 = arr.filter(a => !(a === payload.userRemoved));
            setLocalStorageValue("Hours_Tracked_User_Selection", JSON.stringify(filter_1));
            return {
                ...state,
                hoursTrackedSelectedUsers: filter_1,
                hoursTrackedUsersDropDown: { users: uzers, groups: state.hoursTrackedUsersDropDown.groups }
            };

            // tested
        case HOURS_TRACKED_USERS_CLEAR_SUCCESS:
            listUsers.forEach((user) => {
                user['checked'] = false;
            });
        
            setLocalStorageValue("Hours_Tracked_User_Selection", JSON.stringify(null));
            return {
                ...state,
                hoursTrackedSelectedUsers: [],
                hoursTrackedUsersDropDown: { users: listUsers, groups: state.hoursTrackedUsersDropDown.groups }
            };

            // tested
        case HOURS_TRACKED_GROUPS_CLEAR_SUCCESS:
            // console.log("Reducer, listGroups: ", listGroups);
            listGroups.forEach((group) => {
                group['checked'] = false;
            });
        
            setLocalStorageValue("Screenshot_Group_Selection", JSON.stringify(null));
            return {
                ...state,
                hoursTrackedUsersDropDown: { users: state.hoursTrackedUsersDropDown.users, groups: listGroups }
            };

        case HOURS_TRACKED_USER_SELECTION_SUCCESS:
            setLocalStorageValue("Hours_Tracked_User_Selection", JSON.stringify(payload.selectedUsers));
            return {
                ...state,
                hoursTrackedSelectedUsers: payload.selectedUsers
            };
        // end users dropdown

        case FETCH_CATALOG_TITLE_DETAIL_SUCCESS:
            return {
                ...state,
                catalogTitle: {"catalog": payload.catalogTitle, loadMore: payload.loadMore},
            };

        case FETCH_CATALOG_TITLE_DETAIL_FAILED:
            return {
                ...state,
                catalogTitle: {"catalog": []}
            };

        case FETCH_MORE_CATALOG_TITLE_DETAIL_SUCCESS:
            let kino = state.catalogTitle.catalog;
            let cino = kino.concat(payload.moreCatalogTitle);
            return {
                ...state,
                catalogTitle: { "catalog": cino, loadMore: payload.loadMore },
            };

        // case FETCH_MORE_CATALOG_TITLE_DETAIL_FAILED:
        //     return {
        //         ...state,
        //         hoursTracked: { "userHoursTracks": [] },
        //     };

        default:
            return state;
    };
}
