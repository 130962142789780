import { subDays } from "date-fns";
import { timeFormatInHMorMS } from '../../common/GlobalFunctions';
import {
    USER_PAY_ROLE__DATE_SELECTION_SUCCESS,
    FETCH_USER_PAY_ROLE_DETAILS_SUCCESS,
    FETCH_USER_PAY_ROLE_DETAILS_FAILED,
    FETCH_MORE_USER_PAY_ROLE_SUCCESS,
    FETCH_MORE_USER_PAY_ROLE_FAILED,
    UPDATE_USER_PAYROLE_CURRENCY,
    UPDATE_USER_ADJUSTMENT_PRICE,
    UPDATE_USER_HOUR_RATE,
    FETCH_PAYROLL_CLIENT_FAILED,
    FETCH_MORE_PAYROLL_CLIENT_SUCCESS,
    FETCH_MORE_PAYROLL_CLIENT_FAILED,
    FETCH_PAYROLL_CLIENT_SUCCESS
} from "../actions/Types";

import { getLocalStorageValue, setLocalStorageValue } from "../../utils/PersistanceManager";

// let selectedDateRange = JSON.parse(getLocalStorageValue("Pay_Role_Date_Selection"));
let selectedDateRange = ["2023-10-31T19:00:00.410Z", "2023-11-29T19:00:00.410Z"];

let initialState = {
    userPayRoleDateRange: { dateRange: [new Date(selectedDateRange[0]), new Date(selectedDateRange[1])] },
    // userPayRoleDateRange: { dateRange: selectedDateRange?.savedOn === (new Date()).getDate() ? [new Date(selectedDateRange.dates[0]), new Date(selectedDateRange.dates[1])] : [subDays(new Date(), 6), new Date()], label: "Last 7 days" },

    payRoleData: { userPayRole: [], loadMore: false },
    payrollClients: { list: [], loadMore: false },
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {

        case FETCH_PAYROLL_CLIENT_SUCCESS:
            return {
                ...state,
                payrollClients: { list: payload.payrollClients, loadMore: payload.loadMore }
            };

        case FETCH_PAYROLL_CLIENT_FAILED:
            return {
                ...state,
                payrollClients: { list: [], loadMore: false }
            };

        case FETCH_MORE_PAYROLL_CLIENT_SUCCESS:
            let us = state.payrollClients;
            let con = us.list.concat(payload.payrollClients);
            con.forEach((i) => {
                i["loading"] = false;
            });
            return {
                ...state,
                payrollClients: { list: con, loadMore: payload.loadMore }
            };

        case FETCH_MORE_PAYROLL_CLIENT_FAILED:

            return {
                ...state,
                payrollClients: { list: [], loadMore: false }
            };

        case USER_PAY_ROLE__DATE_SELECTION_SUCCESS:
            let newDt = { dates: payload.selectedDate, "savedOn": (new Date()).getDate() }
            setLocalStorageValue("Pay_Role_Date_Selection", JSON.stringify(newDt));
            return {
                ...state,
                userPayRoleDateRange: { dateRange: payload.selectedDate, label: "Select Date" }
            };

        case FETCH_USER_PAY_ROLE_DETAILS_SUCCESS:
            let n_arr = payload.fetchPayRoleData;
            let alt_arr = [];
            if (n_arr.length > 0) {
                n_arr.map((s, index) => (
                    alt_arr.push({
                        'name': s.name,
                        'totalTime': timeFormatInHMorMS(s.totalActiveTime),
                        'realTime': s.totalActiveTime,
                        'currency': 'USD',
                        'adjustmentAmount': 0,
                        'hourRate': s.hourRate,
                        'totalPay': (s.hourRate) === null ? '0.00' : (((s.totalActiveTime) / 3600) * s.hourRate).toFixed(2)
                    })
                ));
            }
            return {
                ...state,
                payRoleData: { userPayRole: alt_arr, loadMore: payload.loadMore },
            };

        case FETCH_USER_PAY_ROLE_DETAILS_FAILED:
            return {
                ...state,
                payRoleData: { userPayRole: [], loadMore: false },
            };

        case FETCH_MORE_USER_PAY_ROLE_SUCCESS:
            let o_arr = payload.fetchPayRoleData;
            let flt_arr = [];
            if (o_arr.length > 0) {
                o_arr.map((s, index) => (
                    flt_arr.push({
                        'name': s.name,
                        'totalTime': timeFormatInHMorMS(s.totalActiveTime),
                        'realTime': s.totalActiveTime,
                        'currency': 'USD',
                        'adjustmentAmount': 0,
                        'hourRate': s.hourRate,
                        'totalPay': (s.hourRate) === null ? '0.00' : (((s.totalActiveTime) / 3600) * s.hourRate).toFixed(2)
                    })
                ));
            }

            let old_pay_role = state.payRoleData.userPayRole;
            let combine_array = old_pay_role.concat(flt_arr);
            return {
                ...state,
                payRoleData: { userPayRole: combine_array, loadMore: payload.loadMore },
            };

        case FETCH_MORE_USER_PAY_ROLE_FAILED:
            return {
                ...state,
                payRoleData: { userPayRole: [], loadMore: false },
            };
        case UPDATE_USER_PAYROLE_CURRENCY:
            let old_loadMore = state.payRoleData.loadMore;
            let up_ind = payload.index;
            let up_curr = payload.currency;
            let old_currency_arr = state.payRoleData.userPayRole;
            old_currency_arr[up_ind].currency = up_curr;
            return {
                ...state,
                payRoleData: { userPayRole: old_currency_arr, loadMore: old_loadMore },
            };

        case UPDATE_USER_ADJUSTMENT_PRICE:
            let ur_loadMore = state.payRoleData.loadMore;
            let old_arr = state.payRoleData.userPayRole;
            let ur_ind = payload.index;
            let ur_pri = payload.price;


            let old_price_rate = old_arr[ur_ind].hourRate;
            let old_real_time = old_arr[ur_ind].realTime;
            let value_price = (old_real_time) === null ? '0.00' : (((old_real_time) / 3600) * old_price_rate).toFixed(2)

            // console.log('value_price', value_price)
            let old_cst = parseFloat(value_price) + parseFloat(ur_pri);
            old_arr[ur_ind].totalPay = old_cst.toFixed(2);
            old_arr[ur_ind].adjustmentAmount = ur_pri;
            return {
                ...state,
                payRoleData: { userPayRole: old_arr, loadMore: ur_loadMore },
            };

        case UPDATE_USER_HOUR_RATE:

            let oldloadMore = state.payRoleData.loadMore;
            let oldPayRole = state.payRoleData.userPayRole;
            let changedIndex = payload.index;
            let changedRate = payload.hourRate;

            let oldAdjAmount = oldPayRole[changedIndex].adjustmentAmount;
            let oldreal_time = oldPayRole[changedIndex].realTime;
            let timeXrate = (oldreal_time) === null ? '0.00' : (((oldreal_time) / 3600) * changedRate).toFixed(2)

            let newCost = parseFloat(timeXrate) + parseFloat(oldAdjAmount);
            oldPayRole[changedIndex].totalPay = newCost.toFixed(2);
            oldPayRole[changedIndex].hourRate = changedRate;
            return {
                ...state,
                payRoleData: { userPayRole: oldPayRole, loadMore: oldloadMore },
            };



        default:
            return state;
    }
};