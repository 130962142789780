import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownButton, Form } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { fetchAddNewUser } from '../../redux/actions/UserAction';
import { useDispatch, connect } from "react-redux";
import ResultMessage from '../ResultMessage';
import './style.css';
import {
  ADD_NEW_USER_SUCCUCCESS_RESET,
  CLEAR_MESSAGE,
} from '../../redux/actions/Types';
import Loading from '../loading';
import AnimatedSaveButton from '../AnimtedSaveButton';

function AddUserModal(props) {
  const { openedFor, show, handleClose, onSubmit, addNewUsers, selectedCompany, message } = props;
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch({
      type: CLEAR_MESSAGE,
    });
  }, [])

  const [emailList, setEmailList] = useState([{ email: "", role: openedFor === "Client" ? "Client" : "Member", isError: false }]);
  const [addNewUsersLoading, setAddNewUsersLoading] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [showTick, setShowTick] = useState(false);
  const [msg, setMsg] = useState(addNewUsers.status);

  const handleEmailChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...emailList];
    list[index][name] = value;
    setEmailList(list);
  };

  const handleEmailRemove = (index) => {
    const list = [...emailList];
    list.splice(index, 1);
    setEmailList(list);
  };

  const handleEmailAdd = () => {
    if (validate(emailList[emailList.length - 1].email)) {
      setEmailList([...emailList, { email: "", role: openedFor === "Client" ? "Client" : "Member", isError: false }]);
    }
  };

  const handleRoleChange = (e, index) => {
    const list = [...emailList];
    list[index].role = e;
    setEmailList(list);
  };

  useEffect(() => {
    if (addNewUsers.status === 403 && addNewUsers.status !== 'undefined') {
      setShowTick(false);
      setMsg(addNewUsers.data.message);
    }
    else if (addNewUsers.status === 200) {
      if (addNewUsers.data.subCode === 1009) {
        setMsg("");
        setShowTick(true);
      } else {
        setShowTick(false);
        setMsg(addNewUsers.data.message);
      }
    }
    // console.log(addNewUsers)

  }, [addNewUsers.status])

  // console.log('addNewUsers', addNewUsers)
  function handleAddNewUser(users) {
    setAddNewUsersLoading(true);
    dispatch(fetchAddNewUser(users, selectedCompany.id))
      .then(() => {
        // console.log(response, "response")
        setIsAlertVisible(true);
        setEmailList([{ email: "", role: openedFor === "Client" ? "Client" : "Member", isError: false }]);
        setAddNewUsersLoading(false);

        setTimeout(() => {
          setIsAlertVisible(false);
          setShowTick(false);
          setMsg("")
          dispatch({
            type: ADD_NEW_USER_SUCCUCCESS_RESET,
          });
        }, 5000);
      })
      .catch((error) => {
        console.log("error: ", error);
        setAddNewUsersLoading(false);
      });
  }

  function onAddNewUsers(emails) {
    if (emails.length == 1 && emails[0] !== "") {
      handleAddNewUser(emails);
    }
    else if (emails.length > 1) {
      handleAddNewUser(emails);
    }
  }

  const handleSubmit = () => {
    let newList = [...emailList];
    emailList.forEach((element, index) => {
      if (validate(element.email)) {
        newList[index].isError = false;
      }
      else {
        newList[index].isError = true;
      }
    });
    setEmailList(newList);

    let newEmailsList = [];
    var valueArr = emailList.map(function (item) { return { email: item.email, userRole: item.role } });

    valueArr.some(function (item, idx) {
      if (valueArr.indexOf(item) === idx) {
        newEmailsList.push(item);
      }
    });
    var hasError = false;
    emailList.forEach((mail) => {
      if (mail.isError === true) hasError = true
    })

    if (hasError === false) onAddNewUsers(newEmailsList);
  }

  function validate(email) {
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(email) === false) {
      // set error here
      return false;
    }
    return true;
  }

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  return (
    <>
      <div className='addusermodal' onKeyDown={handleKeyDown}>
        <Modal className='adduserpopap' show={show} onHide={handleClose} backdrop="static" keyboard={false}>
          <Modal.Header closeButton>
            <h4>Invite New {openedFor === "Client" ? "Client" : "Member"}</h4>
          </Modal.Header>

          <div>
            {openedFor === "Client" ? <Modal.Body>
              <form className="App" autoComplete="off">
                {emailList.map((element, i) => {
                  return (
                    <div className='row-box-add pb-3' key={i}>
                      <Form.Label>Email address</Form.Label>
                      <div className='input-add d-flex align-items-center mb-3' key={i}>

                        <input className={(element.isError ? " borderred " : "")} name="email" id="email" type="email" placeholder="Enter email" required value={element.email} onChange={(e) => handleEmailChange(e, i)} />
                        {(() => {
                          if (i + 1 === emailList.length) {
                            if (emailList[emailList.length - 1] != "") {
                              return (
                                <>
                                  <button className='add mb-1' onClick={() => { handleEmailAdd() }}> <img src='images/add.svg' alt='' /></button>
                                  {(() => {
                                    if (emailList.length > 1) {
                                      return (<button className='delete' onClick={() => { handleEmailRemove(i) }}> <img src='images/delete.svg' alt='' /></button>)
                                    }
                                  })()}
                                </>
                              )
                            } else {
                              return (
                                <>
                                  <button className='add opacity' > <img src='images/add.svg' alt='' /></button></>
                              )
                            }
                          } else {
                            return (<button className='delete' onClick={() => { handleEmailRemove(i) }}> <img src='images/delete.svg' alt='' /></button>)
                          }
                        })()}
                      </div>
                    </div>
                  )
                })}
                {/* <div className='m-2'>
                  {addNewUsers.status === 403 ?
                    isAlertVisible && <ResultMessage messageType={'error'} message={addNewUsers.data.message} /> :
                    isAlertVisible &&
                    <div className="multilineResultMsg text-center mt-2 p-3">
                      <h5 className="mb-2"> Email invitation sent successfully!</h5>
                      {openedFor === "Client" ?
                        <p className="mb-2">We've just sent your invitation – ask your client to check their inbox or junk folders for the invite.</p> :
                        <p className="mb-2">Ask your team member to check thier inbox or junk folder.</p>
                      }
                    </div>
                  }
                </div> */}


                <div className='d-flex  justify-content-end'>
                  <AnimatedSaveButton
                    text={"Send Invite"}
                    // isDisabled={!groupName.length}
                    callingFunction={() => { handleSubmit() }}
                    displayErrorMsg={msg}
                    loaderLoading={addNewUsersLoading}
                    showTick={showTick} />
                </div>

              </form>
            </Modal.Body>
              :
              <Modal.Body>
                <form className="App pb-3" autoComplete="off">
                  {emailList.map((element, i) => {
                    return (
                      <div className='row-box-add pb-3' key={i}>

                        <div className='input-add d-flex mb-3 align-items-end' key={i}>
                          <Col md={"7"} className='pe-md-0 mb-md-0'>
                            <Form.Label>Email address</Form.Label>
                            <input className={(element.isError ? " borderred " : "")} name="email" id="email" type="email" placeholder="Enter email" required value={element.email} onChange={(e) => handleEmailChange(e, i)} />
                          </Col>
                          <div className='ms-2 membar-dropdown'>
                            <Form.Label>Member</Form.Label>

                            <DropdownButton className="project-filter" title={element.role} onSelect={(e) => {
                              handleRoleChange(e, i);
                            }}>
                              <Dropdown.Item eventKey="Member">Member</Dropdown.Item>
                              <Dropdown.Item eventKey="Admin">Admin</Dropdown.Item>
                            </DropdownButton>
                          </div>
                          {(() => {
                            if (i + 1 === emailList.length) {
                              if (emailList[emailList.length - 1] != "") {
                                return (
                                  <>
                                    {(() => {
                                      if (emailList.length > 1) {
                                        return (<button className='delete' onClick={() => { handleEmailRemove(i) }}> <img src='images/delete.svg' alt='' /></button>)
                                      }
                                    })()}
                                    <button className='add mb-1' onClick={() => { handleEmailAdd() }}> <img src='images/add.svg' alt='' /></button>
                                  </>
                                )
                              } else {
                                return (
                                  <button className='add opacity' > <img src='images/add.svg' alt='' /></button>
                                )
                              }
                            }
                            else {
                              return (<button className='delete' onClick={() => { handleEmailRemove(i) }}> <img src='images/delete.svg' alt='' /></button>)
                            }
                          })()}
                        </div>
                      </div>
                    )
                  })}
                  {/* <div className='m-2'>
                    {addNewUsers.status === 403 ?
                      isAlertVisible && <ResultMessage messageType={'error'} message={addNewUsers.data.message} /> :
                      isAlertVisible &&
                      <div className="multilineResultMsg text-center mt-2 p-3">
                        <h5 className="mb-2"> Email invitation sent successfully!</h5>
                        <p className="mb-2">Ask your team member to check thier inbox or junk folder.</p>
                      </div>
                      // <ResultMessage messageType={'success'} message={addNewUsers.data.message} />
                    }
                  </div>
                 */}
                  <div className='d-flex  justify-content-end'>
                    <AnimatedSaveButton
                      text={"Send Invite"}
                      // isDisabled={!groupName.length}
                      callingFunction={() => { handleSubmit() }}
                      displayErrorMsg={msg}
                      loaderLoading={addNewUsersLoading}
                      showTick={showTick} />
                  </div>

                </form>
              </Modal.Body>}
          </div >
        </Modal>
      </div>
    </>
  );
}

const mapStateToProps = state => ({
  message: state.MessageReducer.message,
  selectedCompany: state.UserReducer.selectedCompany,
  addNewUsers: state.UserReducer.addNewUsers
});

export default connect(mapStateToProps)(AddUserModal);