import React, { useState, useEffect } from 'react'
import './style.css';
// import { LEFT_NAV_EXPANDED_TRUE_FALSE, SET_LEFTNAV_SUCCESS } from '../../redux/actions/Types';
import Enumerable from 'linq';
import { Button, Form, InputGroup, Modal, Tab, Tabs } from 'react-bootstrap';
import { Link } from 'react-router-dom';
// import { useDispatch, connect } from "react-redux";

const SearchableUsersGroups = (props) => {
	const { loading, onChangeSearchWord, searchedUsersGroups, slectedTab, selectedUsers, onRemoveUser, onSelectUser, onGroupSelectionChange, onBlur, onClearUsers, onClearGroups, getUsersDropDown, leftNavExpand } = props;

	const [title, setTitle] = useState("Loading...");
	const [selectedTab, setSelectedTab] = useState(slectedTab || "user");
	const [show, setShow] = useState(false);

	const handleShow = () => {
		setShow(true);
	}
	const handleClose = () => {
		setShow(false)
	};

	function checkSelected(array) {
		return Enumerable.from(array)
			.where(x => x.checked === true)
			.select(y => y.id)
			.toArray();
	}

	function createTitle(by) {
		if (by === "user") {
			return checkSelected(selectedUsers).length > 0 ? checkSelected(selectedUsers).length === 1 ? checkSelected(selectedUsers).length + " User Selected" : checkSelected(selectedUsers).length + " Users Selected" : "All Users"
		}
		else {
			return checkSelected(searchedUsersGroups.groups).length > 0 ?
				checkSelected(searchedUsersGroups.groups).length === 1 ?
					checkSelected(searchedUsersGroups.groups).length + " Group Selected" : checkSelected(searchedUsersGroups.groups).length + " Groups Selected" : "All Groups"
		}
	}

	const [searchedUsers, setSearchedUsers] = useState(searchedUsersGroups.users);
	const [userSkip, setUserSkip] = useState(0)
	let limit = 8
	useEffect(() => {
		setSearchedUsers(searchedUsersGroups.users);
		setTitle(createTitle(selectedTab));
	}, [searchedUsersGroups])

	const onApply = (by) => {
		setSelectedTab(by);
		setTitle(createTitle(by));
		onBlur(by);
		handleClose();
	}

	const [keyword, setKeyword] = useState("");
	const onChangeKeyword = (e) => {
		setKeyword(e.target.value);
		onChangeSearchWord(e.target.value);
	};

	const onUzerSelection = (user) => {
		let arr = searchedUsers;
		var filter = arr.filter(a => !(a === user));
		if (filter.length === 0) {
			setKeyword("");
			getUsersDropDown("", userSkip, limit);
			setUserSkip(userSkip + limit)
		}
		setSearchedUsers(filter);
		onSelectUser(user);
	}

	const onDeSelectUser = (e, user) => {
		e.preventDefault();
		setUserSkip(0);
		onRemoveUser(user);
	}


	return (
		<>
			<div className={"all-user d-flex align-items-center my-md-1 py-2 mt-0 classToRemove "
				}>
				<div className='user-drop'>
					<Button variant="primary" onClick={handleShow}> {title} </Button>
					<Modal show={show} onHide={handleClose} className={"user-dropdown user-modal-dropdown " +  leftNavExpand}>
						<Tabs
							defaultActiveKey={selectedTab}
							id="uncontrolled-tab-example"
							className="">
							<Tab eventKey="user" title="Users">
								{loading &&
									<p className="height-loder dropdown-lodar-start text-center text-black mb-0 loader-fix">
										<span className="spinner-border spinner-border-sm "> </span>
									</p>}
								<div className='input-wrap'>
									<Form.Control type="text" placeholder="Search here" value={keyword} onFocus={() => { setKeyword(""); }} onChange={onChangeKeyword} />

									<span className='search-icon'>
										<img src='images/search-icon-gray.svg' />
									</span>
								</div>

								{selectedUsers.length > 0 && <div className='selectedUsers'>
									{selectedUsers.map((user, index) =>
										<Form.Check
											inline
											label={user.name}
											name="group1"
											type="checkbox"
											id={`uids_${user.id}`}
											key={index}
											checked={user.checked}
											onChange={(e) => {
												if (user.checked) user.checked = false;
												else user.checked = true;
												onDeSelectUser(e, user)
											}
											}
											className="d-flex align-items-center w-100 padding uxc"
										/>

									)}
								</div>}

								{searchedUsers.length > 0 && <div className='selectableUsers'>
									{searchedUsers.map((user, index) =>

										<Form.Check
											inline
											label={user.name}
											name="group1"
											type="checkbox"
											id={`uids_${user.id}`}
											key={index}
											checked={user.checked}
											onChange={(e) => {
												if (user.checked) user.checked = false;
												else user.checked = true;
												onUzerSelection(user);
											}
											}
											className="d-flex align-items-center w-100 padding uxc"
										/>
									)}
								</div>}
								<div className='d-flex m-0 p-0 pt-2 apply-btn'>
									<Link className="me-2 clear-btn" onClick={() => { onChangeSearchWord(""); setKeyword(""); onClearUsers(); handleClose(); }}>Clear</Link>
									<Button onClick={() => { onApply("user") }}>Apply</Button>
								</div>
							</Tab>
							<Tab eventKey="group" title="Groups">
								<div className='m-0'>
									{['checkbox'].map((type) => (
										<div key={`inline-${type}`}>
											<InputGroup>
												{searchedUsersGroups.groups.map((s, index) => (
													<Form.Check
														inline
														label={s.name}
														name="group1"
														type="radio"
														id={`inline-${type}-${index}`}
														key={s.id}
														checked={s.checked}
														className="d-flex align-items-center w-100 padding"
														onChange={(e) => {
															if (s.checked) s.checked = false;
															else s.checked = true;
															onGroupSelectionChange(s)
														}}
													/>
												))}
											</InputGroup>
										</div>
									))}
								</div>
								<div className='d-flex m-0 p-0 pt-2 apply-btn ps-2'>
									{/* <Link className="me-2 clear-btn" onClick={() => { onClearGroups(); handleClose(); }}>Clear</Link> */}
									<Button className='me-0' onClick={() => { onApply("group") }}>Apply</Button>
								</div>
							</Tab>

						</Tabs>

					</ Modal >
				</div>
			</div >
		</>
	)
}

export default SearchableUsersGroups;


