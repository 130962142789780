import React from "react";
import { Col, Row } from "react-bootstrap";
import "./style.css";
import {
  catalogNameFormater,
  noDataSimple,
  timeFormat,
  webOrApp,
} from "../../common/GlobalFunctions";
import { Link, useNavigate } from "react-router-dom";
import CustomToolTip from "../CustomToolTip";
import Loading from "../loading";

const UnProductiveWebsitesApps = (props) => {
  const { loading, dashboardTopUsedWebsitesAppsUnProductive, dashboardDateRange } = props;
  
  const navigate = useNavigate();

  function FindMax(array) {
    return Math.max(...array.map((x) => x.timeTracked));
  }

  function FindPercentange(array, element) {
    return FindMax(dashboardTopUsedWebsitesAppsUnProductive) <= 0
      ? 0
      : (element * 100) / FindMax(array);
  }

  const dummySkeletonFunction = () => {
    let dummySkeleton = [];

    for (let i = 0; i < 6; i++) {
      dummySkeleton.push(
        <div
          className="line-hours d-flex justify-content-between align-items-center mb-4"
          key={i}
        >
          <div className="names d-flex align-items-center">
            <span className="d-flex justify-content-center align-items-center icon">
              <img src={"images/browser-icon.svg"} alt="--" />
            </span>
            <p className="m-0 ms-2">--</p>
          </div>
          <div className="line">
            <div className="red" style={{ width: "100px" }}>
              {" "}
            </div>
          </div>
          <span className="time">--</span>
        </div>
      );
    }
    return dummySkeleton;
  };

  const handleClickNavigate = () => {
    const params = new URLSearchParams({
      rating: "UnProductive",
      date: dashboardDateRange.dateRange,
      label: dashboardDateRange.label
    });

    navigate(`/webs-apps?${params.toString()}`);
  };

  return (
    <>
      <Col className="box m-0 mt-lg-0 mb-xl-0" md={12} lg={12} xl={6}>
        <div
          className={
            "top-projects-box  my-sm-1 my-md-0  mb-xl-2 box-shadow-light websitesapps overflow-inherit mb-5" +
            (loading ? " skeleton1 " : "")
          }
        >
          <div className="bg-white p-0  py-3 py-md-4 pb-lg-5 py-lg-3 px-4 h-100 border-radius">
            {loading ? (
              <div className="text-center loader my-4 pt-1 height-min">
                <Loading />
              </div>
            ) : (
              <>
                <div className="heading">
                    <h3 className="heading heading-font mb-4 most-unproductive-tooltip align-items-center transform-tooltip team-dashboard-tooltips">
                    Most Used Unproductive Websites & Apps
                    <CustomToolTip
                      iconColor="black"
                      details="Unproductive apps and websites Users spent the most time in."
                    />
                  </h3>
                </div>
                <Row>
                  <Col sm={12}>
                    {!loading &&
                      dashboardTopUsedWebsitesAppsUnProductive.length > 0
                      ?
                      <>
                        {dashboardTopUsedWebsitesAppsUnProductive.map(
                          (s, index) => (
                            <div key={index}>
                              <div
                                className="line-hours d-flex justify-content-between align-items-center mb-4"
                              >
                                <div className="names d-flex align-items-center">
                                  <span className="d-flex justify-content-center align-items-center icon">
                                    <img
                                      src={
                                        webOrApp(s.name) === "web"
                                          ? "images/browser-icon.svg"
                                          : "images/appsicon.svg"
                                      }
                                      alt=""
                                    />
                                  </span>
                                  <p className="m-0 ms-2">
                                    {catalogNameFormater(s.name)}
                                  </p>
                                </div>
                                <div className="line">
                                  <div
                                    className="red"
                                    style={{
                                      width: `${FindPercentange(
                                        dashboardTopUsedWebsitesAppsUnProductive,
                                        s.timeTracked
                                      )}%`,
                                    }}
                                  />


                                </div>
                                <span className="time">
                                  {timeFormat(s.timeTracked)}
                                </span>
                              </div>
                            </div>
                          ))}</>
                      : noDataSimple()}
                  </Col>
                </Row>
                <p
                  className="more-btn mt-0"
                  onClick={handleClickNavigate}
                >
                  more
                </p>
              </>
            )}
          </div>
        </div>
      </Col>
    </>
  );
};
export default UnProductiveWebsitesApps;
