import {
    SET_MESSAGE,
    FETCH_USER_SETTINGS_SUCCESS,
    FETCH_USER_SETTINGS_FAILED,
    FETCH_SINGLE_USER_SETTINGS_SUCCESS,
    FETCH_SINGLE_USER_SETTINGS_FAILED,
    FETCH_MORE_USER_SETTINGS_SUCCESS,
    FETCH_MORE_USER_SETTINGS_FAILED,
    UPDATE_USER_SETTINGS_SUCCESS,
    UPDATE_USER_SETTINGS_FAILED,
    UPDATE_USER_ROLE_SUCCESS,
    UPDATE_USER_ROLE_FAILED,
    UPDATE_USER_STATUS_SUCCESS,
    UPDATE_USER_STATUS_FAILED,
    UPDATE_SINGLE_USER_HOUR_RATE_SUCCESS,
    UPDATE_SINGLE_USER_HOUR_RATE_FAILED,
    UPDATE_SINGLE_USER_FULL_NAME_SUCCESS,
    UPDATE_SINGLE_USER_FULL_NAME_FAILED,
    UPDATE_PROFILE_IMAGE_SUCCESS,
    UPDATE_PROFILE_IMAGE_FAILED,
    INTERNET_AVAILABILITY
} from "./Types";
import UserManagementService from "../../services/UserManagementService";

export const fetchUserSettings = (orgId, users, skip, limit, userRole, clientId, activeType) => (dispatch) => {

    var data = {
        "organizationId": orgId,
        "clientId": clientId,
        "userIds": users,
        "skip": skip,
        "limit": limit,
        "userRole": userRole,
        "userType": activeType
    }
    return UserManagementService.fetchUserSettings(data).then(
        (data) => {
            if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
                let loadMore = true;
                if (data.length >= limit) {
                    loadMore = true;
                } else {
                    loadMore = false;
                }
                dispatch({
                    type: FETCH_USER_SETTINGS_SUCCESS,
                    payload: { userSettings: data, loadMore: loadMore },
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: true },
                });
                return Promise.resolve();
            }
            else if (data === "ERR_NETWORK") {
                dispatch({
                    type: FETCH_USER_SETTINGS_FAILED,
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: false },
                });
                return Promise.reject();
            }

        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: FETCH_USER_SETTINGS_FAILED,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};

export const fetchMoreUserSettings = (orgId, users, skip, limit, userRole, clientId, activeType) => (dispatch) => {

    var data = {
        "organizationId": orgId,
        "clientId": clientId,
        "userIds": users,
        "skip": skip,
        "limit": limit,
        "userRole": userRole,
        "userType": activeType
    }
    return UserManagementService.fetchUserSettings(data).then(
        (data) => {
            if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
                let loadMore = true;
                if (data.length >= limit) {
                    loadMore = true;
                } else {
                    loadMore = false;
                }
                dispatch({
                    type: FETCH_MORE_USER_SETTINGS_SUCCESS,
                    payload: { userSettings: data, loadMore: loadMore },
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: true },
                });
                return Promise.resolve();
            }
            else if (data === "ERR_NETWORK") {
                dispatch({
                    type: FETCH_MORE_USER_SETTINGS_FAILED,
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: false },
                });
                return Promise.reject();
            }
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: FETCH_MORE_USER_SETTINGS_FAILED,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};

export const fetchSingleUserSettings = (orgId, userId) => (dispatch) => {

    var data = {
        "organizationId": orgId,
        "userId": userId,
    }
    return UserManagementService.fetchSingleUserSettingdata(data).then(
        (data) => {
            if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
                dispatch({
                    type: FETCH_SINGLE_USER_SETTINGS_SUCCESS,
                    payload: { singleUserDD: data },
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: true },
                });
                return Promise.resolve();
            }
            else if (data === "ERR_NETWORK") {
                dispatch({
                    type: FETCH_SINGLE_USER_SETTINGS_FAILED,
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: false },
                });
                return Promise.reject();
            }
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: FETCH_SINGLE_USER_SETTINGS_FAILED,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};

export const updateUserSettings = (orgId, user, prefName, prefValue) => (dispatch) => {

    var data = {
        "organizationId": orgId,
        "userId": user,
        "prefName": prefName,
        "prefValue": prefValue,
    }
    return UserManagementService.updateUserSettings(data).then(
        (data) => {
            if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
                dispatch({
                    type: UPDATE_USER_SETTINGS_SUCCESS,
                    payload: { settingsUpdate: data, userId: user, prefName: prefName, prefValue: prefValue },
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: true },
                });
                return Promise.resolve();
            }
            else if (data === "ERR_NETWORK") {
                dispatch({
                    type: UPDATE_USER_SETTINGS_FAILED,
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: false },
                });
                return Promise.reject();
            }
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: UPDATE_USER_SETTINGS_FAILED,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};

export const updateUserRole = (orgId, user, userRole) => (dispatch) => {

    var data = {
        "organizationId": orgId,
        "userId": user,
        "userRole": userRole,
    }
    return UserManagementService.updateUserRole(data).then(
        (data) => {
            if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
                dispatch({
                    type: UPDATE_USER_ROLE_SUCCESS,
                    payload: { roleUpdate: data, userId: user, userRole: userRole },
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: true },
                });
                return Promise.resolve();
            }
            else if (data === "ERR_NETWORK") {
                dispatch({
                    type: UPDATE_USER_ROLE_FAILED,
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: false },
                });
                return Promise.reject();
            }
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: UPDATE_USER_ROLE_FAILED,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};

export const updateUserStatus = (orgId, user, userStatus) => (dispatch) => {

    var data = {
        "organizationId": orgId,
        "userId": user,
        "status": userStatus,
    }
    return UserManagementService.updateUserStatus(data).then(
        (data) => {
            if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
                dispatch({
                    type: UPDATE_USER_STATUS_SUCCESS,
                    payload: { userStatus: data, userId: user, uzerStatus: userStatus },
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: true },
                });
                return Promise.resolve();
            }
            else if (data === "ERR_NETWORK") {
                dispatch({
                    type: UPDATE_USER_STATUS_FAILED,
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: false },
                });
                return Promise.reject();
            }
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: UPDATE_USER_STATUS_FAILED,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};

export const updateUserHourRateAction = (orgId, user, hourRate) => (dispatch) => {

    var data = {
        "organizationId": orgId,
        "userId": user,
        "hourRate": hourRate,
    }
    return UserManagementService.updateUserHoursRate(data).then(
        (data) => {
            if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
                dispatch({
                    type: UPDATE_SINGLE_USER_HOUR_RATE_SUCCESS,
                    payload: { resMessage: data.data, userId: user, hourRate: hourRate },
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: true },
                });
                return Promise.resolve();
            }
            else if (data === "ERR_NETWORK") {
                dispatch({
                    type: UPDATE_SINGLE_USER_HOUR_RATE_FAILED,
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: false },
                });
                return Promise.reject();
            }
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: UPDATE_SINGLE_USER_HOUR_RATE_FAILED,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};

export const updateUserNameAction = (user, userName) => (dispatch) => {

    var data = {
        "userId": user,
        "name": userName,
    }
    // return data;
    return UserManagementService.updateUserNameVal(data).then(
        (data) => {
            if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
                dispatch({
                    type: UPDATE_SINGLE_USER_FULL_NAME_SUCCESS,
                    payload: { resMessage: data.data, userId: user, userName: userName },
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: true },
                });
                return Promise.resolve();
            }
            else if (data === "ERR_NETWORK") {
                dispatch({
                    type: UPDATE_SINGLE_USER_FULL_NAME_FAILED,
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: false },
                });
                return Promise.reject();
            }
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: UPDATE_SINGLE_USER_FULL_NAME_FAILED,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};


export const updateProfileImage = (extension, base64) => (dispatch) => {
    var data = {
        "extension": extension,
        "base64EncodedImage": base64,
    }
    return UserManagementService.updateProfileImage(data).then(
        (data) => {
            if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
                dispatch({
                    type: UPDATE_PROFILE_IMAGE_SUCCESS,
                    payload: { profileImage: data },
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: true },
                });
                return Promise.resolve();
            }
            else if (data === "ERR_NETWORK") {
                dispatch({
                    type: UPDATE_PROFILE_IMAGE_FAILED,
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: false },
                });
                return Promise.reject();
            }
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: UPDATE_PROFILE_IMAGE_FAILED,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};
