import React from 'react'
import './style.css';
import 'rsuite/dist/rsuite.min.css';
import { DateRangePicker, Stack } from 'rsuite';


const DateArrangePicker = (props) => {
	const { dateRange, onSelectionOK, selectedDate, maxAllowedDays } = props;
	const { combine, allowedMaxDays, afterToday } = DateRangePicker;

	return (
		<div className='calendar-box d-flex justify-content-end'>
			<div className='calendar'>
				<div className='icon'>
					<Stack direction="column" spacing={8} alignItems="flex-start">
						<DateRangePicker
							character=" To "
							format='dd-MMM-yyyy'
							shouldDisableDate={combine(allowedMaxDays(maxAllowedDays), afterToday())}
							value={selectedDate.dateRange}
							ranges={dateRange}
							placeholder={selectedDate.label}
							onOk={onSelectionOK}
						/>
					</Stack>
				</div>
			</div>
		</div>
	);
}
export default DateArrangePicker