import React, { useState, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import Header from '../../components/Header'
import LeftNav from '../../components/LeftNav'
// import SingleUser from '../../components/SingleUser'
import SingleUserTimeTracked from '../../components/SingleUserTimeTracked'
import TimelinesTable from '../../components/TimelinesTable'
// import UserDashboardProjectTask from '../../components/UserDashboardProjectTask'
import { useDispatch, connect } from "react-redux";
import './style.css';
import DateArrangePicker from '../../components/DateArrangePicker'
import { USER_DASHBOARD_DATE_SELECTION_SUCCESS, USER_DASHBOARD_USER_SELECTION_UPDATE, USER_DASHBOARD_USER_SELECTION_SUCCESS } from '../../redux/actions/Types'
import { fetchUserDashboardUsersDropDown, fetchUserDashboardComulativeTime, fetchUserDashboardUsersTimeline, fetchUserDashboardHoursTrackedProductive, fetchUserDashboardHoursTrackedUnProductive, fetchUserDashboardProductivityPercentage, fetchMoreSingleUserCatalogDetails, fetchSingleUserCatalogDetails, fetchSingleUserProjectTask } from '../../redux/actions/UserDashboardAction'
import UserDashboardSingleUserTimeTrackDetails from '../../components/UserDashboardSingleUserTimeTrackDetails'
import { ADMIN, CLIENT, GROUPADMIN, OWNER } from '../../utils/Constants'
import HoursChartProductive from '../../components/ReCharts/HoursChartProductive'
import HoursChartUnProductive from '../../components/ReCharts/HoursChartUnProductive'
import ProductivityChart from '../../components/ReCharts/ProductivityChart'
////////////////
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import { allowedBasedOnRole } from '../../common/GlobalFunctions'
import SearchableSingleUser from '../../components/SearchableSingleUser'
import CustomToolTip from '../../components/CustomToolTip'
import TopTasks from '../../components/TopTasks'
///////////////
const UserDashboard = (props) => {
  const { userProfile,
    selectedCompany,
    userDashboardDateRange,
    userDashboardUsersDropDown,
    selectedUserId,
    userDashboardComulativeTime,
    userDashboardUserTimeline,
    UserDashboardHoursTrackedProductive,
    UserDashboardHoursTrackedUnProductive,
    UserDashboardProductivityPercentage,
    userWebAppUsage,
    userWorkProjectTask, leftNavExpand

  } = props;

  const dispatch = useDispatch();
  const [showUserDD, setShowUserDD] = useState(false);
  const [title, setTitle] = useState("User Dashboard");
  const [description, setDescription] = useState("Single user overview at a glance");
  const [selectedUser, setSelectedUser] = useState(selectedUserId);
  const [userDashboardUsersDropDownLoading, setUserDashboardUsersDropDownLoading] = useState(false);
  const [userComulativeTimeLoading, setUserComulativeTimeLoading] = useState(false);
  const [userDashboardUserTimelineLoading, setUserDashboardUserTimelineLoading] = useState(false);
  const [userDashboardHoursTrackedProductiveLoading, setUserDashboardHoursTrackedProductiveLoading] = useState(false);
  const [userDashboardHoursTrackedUnProductiveLoading, setUserDashboardHoursTrackedUnProductiveLoading] = useState(false);
  const [userDashboardProductivityPercentageLoading, setUserDashboardProductivityPercentageLoading] = useState(false);
  const [singleUserCatalogDetailsLoading, setSingleUserCatalogDetailsLoading] = useState(false);
  const [singleUserProjectTaskLoading, setSingleUserProjectTaskLoading] = useState(false);
  const [moreSingleUserCatalogDetailsLoading, setMoreSingleUserCatalogDetailsLoading] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const [orderByTime, setOrderByTime] = useState(-1);
  const [ratingType, setRatingType] = useState("");
  const [ratingScope, setRatingScope] = useState("user");
  var limit = 10;
  var days = 365;








  const dateRanges = [
    {
      label: 'Today',
      closeOverlay: false,
      value: [new Date(), new Date()],
      placement: 'left',
      appearance: 'subtle'
    },
    {
      label: 'Yesterday',
      closeOverlay: false,
      value: [addDays(new Date(), -1), addDays(new Date(), -1)],
      placement: 'left',
      appearance: 'subtle'
    },
    {
      label: 'This week',
      closeOverlay: false,
      value: [startOfWeek(new Date()), new Date()],
      placement: 'left',
      appearance: 'subtle'
    },
    {
      label: 'Last 7 days',
      closeOverlay: false,
      value: [subDays(new Date(), 6), new Date()],
      placement: 'left',
      appearance: 'subtle'
    },
    {
      label: 'Last 30 days',
      closeOverlay: false,
      value: [subDays(new Date(), 29), new Date()],
      placement: 'left',
      appearance: 'subtle'
    },
    {
      label: 'This month',
      closeOverlay: false,
      value: [startOfMonth(new Date()), new Date()],
      placement: 'left',
      appearance: 'subtle'
    },
    {
      label: 'Last month',
      closeOverlay: false,
      value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
      placement: 'left',
      appearance: 'subtle'
    },
    {
      label: 'Last six months',
      closeOverlay: false,
      value: [startOfMonth(addMonths(new Date(), -6)), endOfMonth(addMonths(new Date(), -1))],
      placement: 'left',
      appearance: 'subtle'
    },
    {
      label: 'This year',
      closeOverlay: false,
      value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
      placement: 'left',
      appearance: 'subtle'
    },
    {
      label: 'Last year',
      closeOverlay: false,
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
      placement: 'left',
      appearance: 'subtle'
    },
    {
      label: 'Last week',
      closeOverlay: false,
      value: value => {
        const [start = new Date()] = value || [];
        return [
          addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
          addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
        ];
      },
      appearance: 'default'
    },
    {
      label: 'Next week',
      closeOverlay: false,
      value: value => {
        const [start = new Date()] = value || [];
        return [
          addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
          addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
        ];
      },
      appearance: 'default'
    }
  ];

  useEffect(() => {
    chekUserRole();
  }, []);
  useEffect(() => {
    selectedUser && getUserDashboardComulativeTime();
    selectedUser && getUserDashboardUsersTimeline();

    selectedUser && getUserDashboardHoursTrackedProductive();
    selectedUser && getUserDashboardHoursTrackedUnProductive()

    selectedUser && getfetchUserDashboardProductivityPercentage()
    // selectedUser && getSingleUserCatalogDetails(selectedCompany.id, userDashboardDateRange, selectedUser.id, orderByTime, "user", ratingType, 0, limit)
    selectedUser && getSingleUserProjectTask();

  }, [selectedUser, userDashboardDateRange]);

  useEffect(() => {
    selectedUser && getSingleUserCatalogDetails(selectedCompany.id, userDashboardDateRange, selectedUser.id, orderByTime, "user", ratingType, 0, limit)
  }, [ratingType, orderByTime, selectedUser, userDashboardDateRange]);

  const chekUserRole = () => {
    if (allowedBasedOnRole([ADMIN, OWNER, GROUPADMIN, CLIENT], selectedCompany.userRole)) {
      let user = selectedUser === "" ? { id: userProfile.id, name: userProfile.name } : selectedUser;
      dispatch({
        type: USER_DASHBOARD_USER_SELECTION_SUCCESS,
        payload: { selectedUserId: { id: user.id, name: user.name } },
      });
      setShowUserDD(true);
      setTitle("User Dashboard");
      setDescription("Single user overview at a glance")
      setSelectedUser(user);
      getUserDashboardUsersDropDown("", 0, 20);

    }
    else {
      dispatch({
        type: USER_DASHBOARD_USER_SELECTION_SUCCESS,
        payload: { selectedUserId: { id: userProfile.id, name: userProfile.name } },
      });
      setSelectedUser({ id: userProfile.id, name: userProfile.name });
      setTitle("My Dashboard");
      setDescription("My overview at a glance")
    }
  };

  function getUserDashboardComulativeTime() {
    setUserComulativeTimeLoading(true);
    dispatch(fetchUserDashboardComulativeTime(selectedCompany.id, userDashboardDateRange, selectedUser.id))
      .then(() => {
        setUserComulativeTimeLoading(false);
      })
      .catch((error) => {
        console.log("error: ", error);
        setUserComulativeTimeLoading(false);
      });
  };

  function getUserDashboardUsersTimeline() {
    setUserDashboardUserTimelineLoading(true);
    dispatch(fetchUserDashboardUsersTimeline(selectedCompany.id, userDashboardDateRange, selectedUser.id))
      .then(() => {
        setUserDashboardUserTimelineLoading(false);
      })
      .catch((error) => {
        console.log("error: ", error);
        setUserDashboardUserTimelineLoading(false);
      });
  };

  function getUserDashboardHoursTrackedProductive() {
    setUserDashboardHoursTrackedProductiveLoading(true);
    dispatch(fetchUserDashboardHoursTrackedProductive(selectedCompany.id, userDashboardDateRange, ratingScope, selectedUser.id))
      .then(() => {
        setUserDashboardHoursTrackedProductiveLoading(false);
      })
      .catch((error) => {
        console.log("error: ", error);
        setUserDashboardHoursTrackedProductiveLoading(false);
      });
  };

  function getUserDashboardHoursTrackedUnProductive() {
    setUserDashboardHoursTrackedUnProductiveLoading(true);
    dispatch(fetchUserDashboardHoursTrackedUnProductive(selectedCompany.id, userDashboardDateRange, ratingScope, selectedUser.id))
      .then(() => {
        setUserDashboardHoursTrackedUnProductiveLoading(false);
      })
      .catch((error) => {
        console.log("error: ", error);
        setUserDashboardHoursTrackedUnProductiveLoading(false);
      });
  };

  function getfetchUserDashboardProductivityPercentage() {
    setUserDashboardProductivityPercentageLoading(true);
    dispatch(fetchUserDashboardProductivityPercentage(selectedCompany.id, userDashboardDateRange, ratingScope, selectedUser.id))
      .then(() => {
        setUserDashboardProductivityPercentageLoading(false);
      })
      .catch((error) => {
        console.log("error: ", error);
        setUserDashboardProductivityPercentageLoading(false);
      });
  };

  function getUserDashboardUsersDropDown(searchKeyword, skip, limit) {
    setUserDashboardUsersDropDownLoading(true);
    dispatch(fetchUserDashboardUsersDropDown(selectedCompany.id, searchKeyword, skip, limit))
      .then(() => {
        setUserDashboardUsersDropDownLoading(false);
      })
      .catch((error) => {
        console.log("error: ", error);
        setUserDashboardUsersDropDownLoading(false);
      });
  };

  function getSingleUserCatalogDetails(orgId, dateRange, user, sortBy, scope, ratingType, skip, limit) {
    setSingleUserCatalogDetailsLoading(true);
    dispatch(fetchSingleUserCatalogDetails(orgId, dateRange, user, sortBy, scope, ratingType, skip, limit))
      .then(() => {
        setStartIndex(startIndex + limit);
        setSingleUserCatalogDetailsLoading(false);
      })
      .catch((error) => {
        console.log("error: ", error);
        setSingleUserCatalogDetailsLoading(false);
      });
  }

  function getMoreSingleUserCatalogDetails(orgId, dateRange, user, sortBy, scope, ratingType, skip, limit) {
    setMoreSingleUserCatalogDetailsLoading(true);
    dispatch(fetchMoreSingleUserCatalogDetails(orgId, dateRange, user, sortBy, scope, ratingType, skip, limit))
      .then(() => {
        setStartIndex(startIndex + limit);
        setMoreSingleUserCatalogDetailsLoading(false);
      })
      .catch((error) => {
        console.log("error: ", error);
        setMoreSingleUserCatalogDetailsLoading(false);
      });
  }

  function onLoadMore() {
    getMoreSingleUserCatalogDetails(selectedCompany.id, userDashboardDateRange, selectedUser.id, orderByTime, "user", ratingType, startIndex, limit);
  }

  function onChangeRatingType(selectedRatingType) {
    setStartIndex(0);
    setRatingType(selectedRatingType)
  }

  function onOrderByTimeTracked() {
    if (orderByTime === -1) {
      setOrderByTime(1);
    } else {
      setOrderByTime(-1);
    }
  }

  function onDateSelection(newDateRange) {
    // dispatch({
    //   type: USER_DASHBOARD_DATE_SELECTION_SUCCESS,
    //   payload: { selectedDate: newDateRange },
    // });
  };

  function onDropDownClose(Id) {
    setSelectedUser(Id);
    dispatch({
      type: USER_DASHBOARD_USER_SELECTION_SUCCESS,
      payload: { selectedUserId: Id },
    });
  }

  function onUserSelectionChange(changedUser) {
    dispatch({
      type: USER_DASHBOARD_USER_SELECTION_UPDATE,
      payload: { changedUser: changedUser },
    });
  }

  const onChangeUserSearchWord = (e) => {
    getUserDashboardUsersDropDown(e, 0, 20)
  }

  function getSingleUserProjectTask() {
    setSingleUserProjectTaskLoading(true);
    dispatch(fetchSingleUserProjectTask(selectedCompany.id, userDashboardDateRange, selectedUser.id))
      .then(() => {
        setSingleUserProjectTaskLoading(false);
      })
      .catch((error) => {
        console.log("error: ", error);
        setSingleUserProjectTaskLoading(false);
      });
  }

  return (
    <>
      <Row className='m-0 inherit user-dashboard-loader'>
        <Col className='p-0 side-bar' sm={3}>
          <LeftNav />
        </Col>
        <Col className='bg-light-blue contant-area dashboard pb-4' sm={9}>
          <Header title={title} description="See how you’re spending your time." fullUrl="https://timebee.app/documentation/dashboards/how-to-use-timebee-user-dashboard-for-employees/" contentUrl="https://timebee.app/documentation/dashboards/how-to-use-timebee-user-dashboard-for-employees/?dashboard-guide-view=1" />
          <Row className={"flex mt-4 ddd mb-4" + (showUserDD ? " " : " justify-content-end")}>
            {showUserDD && <SearchableSingleUser loading={userDashboardUsersDropDownLoading} userDashboardUsersDropDown={userDashboardUsersDropDown} selectedUser={selectedUser} onBlurHandle={onDropDownClose} onUserSelectionChange={onUserSelectionChange} onChangeSearchWord={onChangeUserSearchWord} userProfile={userProfile} leftNavExpand={leftNavExpand} />}

            <DateArrangePicker selectedDate={userDashboardDateRange} onSelectionOK={onDateSelection} dateRange={dateRanges} maxAllowedDays={days} />
          </Row>
          <SingleUserTimeTracked loading={userComulativeTimeLoading} userDashboardComulativeTime={userDashboardComulativeTime} />

          <TimelinesTable userDashboardUserTimelineLoading={userDashboardUserTimelineLoading}
            userDashboardUserTimeline={userDashboardUserTimeline} timeZone={userProfile.timeZone} timeZoneOffset={userProfile.timeZoneOffset} />
          <Row className='my-4'>
            <Col md={12} className='mb-4 mb-md-0'>
              <div className="bg-white set p-0 box-shadow-light py-3 px-4 h-100 timelines-table pt-0">

                <HoursChartProductive loading={userDashboardHoursTrackedProductiveLoading} productiveDataArray={UserDashboardHoursTrackedProductive.timeline} unproductiveDataArray={UserDashboardHoursTrackedUnProductive.timeline} nameKey={"date"} key1={"totalTimeTracked"} />
              </div>
            </Col>

            {/* <Col md={6}>
              <div className="bg-white p-0 box-shadow-light py-3 px-4 h-100 timelines-table pt-0">

                <HoursChartUnProductive loading={userDashboardHoursTrackedUnProductiveLoading} dataArray={UserDashboardHoursTrackedUnProductive.timeline} nameKey={"date"} key1={"totalTimeTracked"} />
              </div>
            </Col> */}
          </Row>


          <div className="bg-white p-0 box-shadow-light py-3 px-4 timelines-table overflow-inherit mb-4">
            <h5 className='heading mb-3 font-bold-500 p-0'> Work Breakdown
              <CustomToolTip iconColor="black" details="Check each user's productive activity and time spent. Click each graph bar to get categorized time stamps for time spent on productive, unproductive, neutral, and unrated sites and apps." />
            </h5>
            <Col className='px-0 overflow-hidden' md={12}>
              <ProductivityChart loading={userDashboardProductivityPercentageLoading} dataArray={UserDashboardProductivityPercentage.activityTrack} nameKey={"date"} key1={"totalTimeTracked"} />
            </Col>
          </div>


          <UserDashboardSingleUserTimeTrackDetails user={selectedUser} userWebAppUsage={userWebAppUsage} loading={singleUserCatalogDetailsLoading} moreLoading={moreSingleUserCatalogDetailsLoading} onLoadMore={onLoadMore} onChangeRatingType={onChangeRatingType} OrderByTimeTracked={onOrderByTimeTracked} dateRange={userDashboardDateRange} />

          <Row className="">
            <TopTasks loading={singleUserProjectTaskLoading} workProjectTask={userWorkProjectTask} selectedUser={selectedUser} />
          </Row>


        </Col>
      </Row>

    </>
  )
}

const mapStateToProps = state => ({
  userProfile: state.UserReducer.userProfile,
  selectedCompany: state.UserReducer.selectedCompany,
  selectedUserId: state.UserDashboardReducer.selectedUserId,
  userDashboardDateRange: state.UserDashboardReducer.userDashboardDateRange,
  userDashboardComulativeTime: state.UserDashboardReducer.userDashboardComulativeTime,
  userDashboardUsersDropDown: state.UserDashboardReducer.userDashboardUsersDropDown,
  userDashboardUserTimeline: state.UserDashboardReducer.userDashboardUserTimeline,
  UserDashboardHoursTrackedProductive: state.UserDashboardReducer.UserDashboardHoursTrackedProductive,
  UserDashboardHoursTrackedUnProductive: state.UserDashboardReducer.UserDashboardHoursTrackedUnProductive,
  UserDashboardProductivityPercentage: state.UserDashboardReducer.UserDashboardProductivityPercentage,
  userWebAppUsage: state.UserDashboardReducer.userWebAppUsage,
  userWebnAppsUsageDetails: state.UserDashboardReducer.userWebnAppsUsageDetails,
  userWorkProjectTask: state.UserDashboardReducer.userWorkProjectTask,
  leftNavExpand: state.SupportReducer.leftNavExpand,
});

export default connect(mapStateToProps)(UserDashboard);