import React, { useState, useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import { Button, Col, Dropdown, Row, DropdownButton } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./style.css";
import { NoDataToDisplay } from "../../utils/Constants";
import { timeFormatInHMorMS } from "../../common/GlobalFunctions";
import PieChartProjectTask from "../../components/ReCharts/PieChartProjectTask";
import {
  USER_DASHBOARD_PROJECT_TASK_LIST,
  USER_DASHBOARD_PROJECT_CHANGE,
  PROJECT_PROGRESS_DASHBOARD_SELECTED_USER,
} from "../../redux/actions/Types";
import Loading from "../loading";

const TopTasks = (props) => {
  const { loading, workProjectTask, userDashProjtTask, selectedUser } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSelectedProject, setIsSelectedProject] = useState("Top Tasks");

  let total_project_time = 0;
  let total_task_time = 0;
  let project_chart_data = [];
  let task_chart_data = [];

  useEffect(() => {
    if (workProjectTask.length > 0) {
      dispatch({
        type: USER_DASHBOARD_PROJECT_TASK_LIST,
        payload: { project_task: workProjectTask },
      });
    }
  }, [workProjectTask]);

  const DetailsProjectTask = () => {
    dispatch({
      type: PROJECT_PROGRESS_DASHBOARD_SELECTED_USER,
      payload: { selectedUser: selectedUser },
    });
    navigate("/project-hours");
  };

  const changeProjectType = (value) => {
    if (value.length > 0) {
      var chk_typ = value.split("_");
      dispatch({
        type: USER_DASHBOARD_PROJECT_CHANGE,
        payload: { project_ids: chk_typ.shift() },
      });
      setIsSelectedProject(chk_typ.pop());
    } else {
      setIsSelectedProject("Top Tasks");
      dispatch({
        type: USER_DASHBOARD_PROJECT_TASK_LIST,
        payload: { project_task: workProjectTask },
      });
    }
  };

  return (
    <>
      <Col className="box m-0 mb-xl-0" md={12} lg={12}>
        <div className="top-projects-box my-4 mb-md-3 mb-0 box-shadow websitesapps box-shadow-light">
          <div className="bg-white p-0  py-4 px-4 h-100 border-radius">
            {loading ? (
              <div className="text-center loader my-4 pt-1">
                <Loading />
              </div>
            ) : (
              <Row>
                <Col
                  className="box m-0 mb-xl-0 border-right mb-3 mb-lg-0"
                  md={12}
                  lg={12}
                  xl={6}
                >
                  <div className="heading">
                    <h3 className="heading mb-4 d-flex align-items-center">
                      Top Projects
                    </h3>
                  </div>
                  <Row>
                    <Col sm={12}>
                      <Row className=" position-relative min-height">
                        {workProjectTask.length > 0 ? (
                          <>
                            <Col sm={7} className="box-general col-12">
                              <table className="projext-task-table">
                                <tbody>
                                  {workProjectTask.map((p, index) =>
                                    index < 4 ? (
                                      <tr key={index}>
                                        <td>
                                          <Button
                                            onClick={() => {
                                              changeProjectType(
                                                p.projectId +
                                                "_" +
                                                p.projectName
                                              );
                                            }}
                                          >
                                            {p.projectName}
                                          </Button>
                                        </td>
                                        <td>
                                          <p>
                                            {timeFormatInHMorMS(
                                              p.totalProjectTime
                                            )}
                                          </p>
                                        </td>
                                        {(() => {
                                          total_project_time +=
                                            p.totalProjectTime;
                                          project_chart_data.push({
                                            value: p.totalProjectTime,
                                          });
                                        })()}
                                      </tr>
                                    ) : (
                                      <tr>
                                        <td></td>
                                        <td></td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </Col>

                            <Col sm={4} className="progress-multi col-4">
                              <PieChartProjectTask
                                totalTime={timeFormatInHMorMS(
                                  total_project_time
                                )}
                                data={project_chart_data}
                              />
                            </Col>
                          </>
                        ) : (
                          <div className="no-data">
                            <img src={"images/NoData.svg"} />
                            <p> {NoDataToDisplay}</p>
                          </div>
                        )}
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col
                  className="box m-0 mb-xl-0 translate-box"
                  md={12}
                  lg={12}
                  xl={6}
                >
                  <div className="heading d-flex justify-content-between">
                    <h3 className="heading mb-4 d-flex align-items-center">
                      Top Tasks
                    </h3>
                    <DropdownButton
                      className="project-filter user-dashboard-top pe-2"
                      title={isSelectedProject}
                      onSelect={changeProjectType}
                    >
                      <Dropdown.Item eventKey="">Top Tasks</Dropdown.Item>
                      {workProjectTask.length > 0 &&
                        workProjectTask.map((p, index) => (
                          <Dropdown.Item
                            eventKey={p.projectId + "_" + p.projectName}
                            key={index}
                          >
                            {p.projectName}
                          </Dropdown.Item>
                        ))}
                    </DropdownButton>
                  </div>
                  <Row>
                    <Col sm={12}>
                      <Row className=" position-relative min-height mb-4 mb-lg-0">
                        {workProjectTask.length > 0 ? (
                          <>
                            <Col sm={6} className="box-general col-12">
                              {userDashProjtTask.tasks.map(
                                (m, indu) =>
                                  indu < 4 && (
                                    <div
                                      className="task-name my-2 ps-3 ms-lg-0"
                                      key={indu}
                                    >
                                      <div>
                                        <h5>{m.taskName}</h5>
                                        <span>{m.projectName}</span>
                                      </div>
                                      <p>
                                        {timeFormatInHMorMS(m.totalTaskTime)}
                                      </p>
                                      {(() => {
                                        total_task_time += m.totalTaskTime;
                                        task_chart_data.push({
                                          value: m.totalTaskTime,
                                        });
                                      })()}
                                    </div>
                                  )
                              )}
                            </Col>
                            <Col
                              sm={4}
                              className="progress-multi progress-multi-c offset-sm-1 offset-lg-3 col-4"
                            >
                              <PieChartProjectTask
                                totalTime={timeFormatInHMorMS(total_task_time)}
                                data={task_chart_data}
                              />
                            </Col>
                          </>
                        ) : (
                          <div className="no-data">
                            <img src={"images/NoData.svg"} />
                            <p> {NoDataToDisplay}</p>
                          </div>
                        )}
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <button
                  className="more-btn mt-0"
                  onClick={() => {
                    DetailsProjectTask();
                  }}
                >
                  more
                </button>
              </Row>
            )}
          </div>
        </div>
      </Col>
    </>
  );
};

const mapStateToProps = (state) => ({
  userDashProjtTask: state.UserDashboardReducer.userDashProjtTask,
});

export default connect(mapStateToProps)(TopTasks);
