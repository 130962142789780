import './style.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ADMIN, GROUPADMIN, MEMBER, OWNER, CLIENT, PREMIUM, ULTRA } from '../../utils/Constants';
import { useDispatch, connect } from "react-redux";
import { LEFT_NAV_EXPANDED_TRUE_FALSE, SET_LEFTNAV_SUCCESS } from '../../redux/actions/Types';
import { NavDropdown } from 'react-bootstrap';
import { allowedBasedOnPackage, allowedBasedOnRole } from '../../common/GlobalFunctions';
import NoInternet from '../NoIntenet';
import PackageExpire from '../PackageExpire';


const LeftNav = (props) => {
  const { selectedCompany, leftNavExpand, leftNavSelectedItem, internetAvailable } = props;
  const dispatch = useDispatch();

  const [isActive, setActive] = useState(leftNavExpand === "No" ? true : false);
  const [showAllowed, setShowAllowed] = useState(false);
  const [page, setPage] = useState("");
  const navigate = useNavigate();  //this
  const location = useLocation();

  const [showDashboard, setShowDashboard] = useState(leftNavSelectedItem.parentName === "Dashboard" ? true : false);
  const [showReports, setShowReports] = useState(leftNavSelectedItem.parentName === "Reports" ? true : false);
  const [showSettings, setShowSettings] = useState(leftNavSelectedItem.parentName === "Settings" ? true : false);
  const [showBillings, setShowBillings] = useState(leftNavSelectedItem.parentName === "Billings" ? true : false);


  useEffect(() => {
    chekUserRole();
  }, [])

  useEffect(() => {
    const url = window.location.href;
    const parts = url.split("/");
    const lastWord = parts[parts.length - 1];
    const pageUrl = lastWord.split("?")[0];
    setPage(pageUrl);
  }, [location])



  useEffect(() => {

    //condition for member
    if (selectedCompany.userRole[0] === MEMBER && selectedCompany.userRole.length === 1) {
      if (page === "") {
        dispatch({
          type: SET_LEFTNAV_SUCCESS,
          payload: { leftNavSelectedItem: { selectedItem: page, parentName: "" } },
        });
      }
      else if (page === "webs-apps") {
        dispatch({
          type: SET_LEFTNAV_SUCCESS,
          payload: { leftNavSelectedItem: { selectedItem: page, parentName: "" } },
        });
      }
    }

    //Condition for Dashboard parent
    if (page === "team-dashboard") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: page, parentName: "Dashboard" } },
      });
    }
    else if (page === "user-dashboard") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: page, parentName: "Dashboard" } },
      });
    }
    //Condition for Reports parent
    else if (page === "hours-track") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: page, parentName: "Reports" } },
      });
    }
    else if (page === "activity-summary") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: page, parentName: "Reports" } },
      });
    }
    else if (page === "quarterly-performance") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: page, parentName: "Reports" } },
      });
    }
    else if (page === "project-costing") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: page, parentName: "Reports" } },
      });
    }
    else if (page === "project-costing-new") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: page, parentName: "Reports" } },
      });
    }
    else if (page === "project-hours") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: page, parentName: "Reports" } },
      });
    }
    // else if (page === "profitability-report") {
    //   dispatch({
    //     type: SET_LEFTNAV_SUCCESS,
    //     payload: { leftNavSelectedItem: { selectedItem: page, parentName: "Reports" } },
    //   });
    // }
    //Condition for Web & Apps
    else if (page === "webs-apps") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: page, parentName: "Reports" } },
      });
    }
    else if (page === "attendance-report") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: page, parentName: "Reports" } },
      });
    }
    //Condition for Settings parent
    else if (page === "projects") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: page, parentName: "Settings" } },
      });
    }
    else if (page === "tasks") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: "projects", parentName: "Settings" } },
      });
    }
    else if (page === "groups") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: page, parentName: "Settings" } },
      });
    }
    else if (page === "webapp-rating") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: page, parentName: "Settings" } },
      });
    }
    else if (page === "user-management") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: page, parentName: "Settings" } },
      });
    }
    //Condition for Billings parent
    else if (page === "billing-details") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: page, parentName: "Billings" } },
      });
    }
    else if (page === "payroll") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: page, parentName: "Billings" } },
      });
    }

    //Condition for Client
    else if (page === "clients") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: page, parentName: "Settings" } },
      });
    }
    else if (page === "client-team") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: "clients", parentName: "Settings" } },
      });
    }
    else if (page === "my-team") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: page, parentName: "Settings" } },
      });
    }
    //Conditon for email notifications
    else if (page === "email-notification") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: page, parentName: "Settings" } },
      });
    }
    //Condition for Screenshots
    else if (page === "screenshots") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: page, parentName: "" } },
      });
    }
    //Condition for Downloads
    else if (page === "downloads") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: page, parentName: "" } },
      });
    }
    //Condition for organization
    else if (page === "organization") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: page, parentName: "" } },
      });
    }
    // Conditon for profile
    else if (page === "profile") {
      dispatch({
        type: SET_LEFTNAV_SUCCESS,
        payload: { leftNavSelectedItem: { selectedItem: page, parentName: "" } },
      });
    }

  }, [page])

  useEffect(() => {
    setShowDashboard(leftNavSelectedItem.parentName === "Dashboard" ? true : false);
    setShowReports(leftNavSelectedItem.parentName === "Reports" ? true : false);
    setShowSettings(leftNavSelectedItem.parentName === "Settings" ? true : false);
    setShowBillings(leftNavSelectedItem.parentName === "Billings" ? true : false);
  }, [leftNavSelectedItem])


  const ToggleClass = () => {
    setActive(!isActive);
    dispatch({
      type: LEFT_NAV_EXPANDED_TRUE_FALSE,
      payload: { leftNavExpand: isActive ? "Expanded" : "No" },
    });
  };

  const chekUserRole = () => {
    if (allowedBasedOnRole([ADMIN, OWNER], selectedCompany.userRole)) {
      setShowAllowed(true);
    }
  };

  return (
    <>
      {[false].map((expand) => (
        <Navbar collapseOnSelect expand="md" key={false}>
          <Container className='p-0' fluid>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
              </Offcanvas.Header>
              <div className={isActive ? "active" : "inactive"}>
                <button onClick={ToggleClass} className="menu-icon">
                  <img src={"images/menu-icon-black.svg"} /></button>
                <div className='left-nav'>
                  <Navbar.Brand className='py-md-4 mb-4 me-0 d-block px-4'>
                    <Link to="/">
                      <div className='logo-left-nav'>
                        <img src={"images/TimebugLogoOnly.svg"} />
                        <img className='time-bee-ico' src={"images/timebee-ico.svg"} />
                        {/* <p>
                          <span className="time-text">time</span>
                          <span className="bug-text">Bee</span>
                        </p> */}
                      </div>
                    </Link>
                  </Navbar.Brand>
                  <Nav defaultActiveKey="/" as="ul" className='d-block'>

                    {allowedBasedOnRole([ADMIN, OWNER, GROUPADMIN, CLIENT], selectedCompany.userRole) ?

                      <Nav.Item as="li" className='mb-2 arrow '>
                        <div className='dashbaord-li'>
                          <Nav.Link className='d-flex align-items-center'>
                            <img src={"images/dashbaord.svg"} />
                            <span> Dashbaord</span></Nav.Link>
                          <Nav as="ul" className='hover-menu'>
                            <Nav.Item as="li">
                              <Link to="/team-dashboard"> Team Dashboard </Link>
                            </Nav.Item>
                            <Nav.Item as="li">
                              <Link to="/user-dashboard"> User Dashboard </Link>
                            </Nav.Item>
                          </Nav>
                        </div>

                        <div className='d-flex align-items-center align-items-baseline dropdown-menuu'>
                          <img width={15} src={"images/dashbaord.svg"} />

                          <NavDropdown show={showDashboard} onClick={() => { setShowDashboard(!showDashboard); }} title="Dashbaord" id="collapsible-nav-dropdown" className='arrow'>

                            <ul className='p-0'>
                              <Nav.Item as="li">
                                <Link className={(leftNavSelectedItem.selectedItem === "team-dashboard" ? " selected " : "")} to="/team-dashboard"> Team Dashboard </Link>
                              </Nav.Item>
                              <Nav.Item as="li">
                                <Link className={(leftNavSelectedItem.selectedItem === "user-dashboard" ? " selected " : "")} to="/user-dashboard"> User Dashboard </Link>
                              </Nav.Item>
                            </ul>
                          </NavDropdown>
                        </div>
                      </Nav.Item>
                      :
                      <Nav.Item as="li" className='mb-2'>
                        <Link to="/" className={"d-flex align-items-center "}>
                          <img src={"images/dashbaord.svg"} />
                          <span>My Dashboard</span></Link>
                        <Nav className={"hover-menu " + (leftNavSelectedItem.selectedItem === "" ? " selected " : "")} as="ul" >
                          <p >My Dashboard</p>
                        </Nav>
                      </Nav.Item>
                    }

                    {allowedBasedOnRole([ADMIN, OWNER, GROUPADMIN, CLIENT], selectedCompany.userRole) ?
                      <Nav.Item as="li" className='mb-2 arrow'>
                        <div className='dashbaord-li'>
                          <Nav.Link className='d-flex align-items-center'>
                            <img src={"images/reports.svg"} />
                            <span>Reports</span></Nav.Link>
                          <Nav as="ul" className='hover-menu'>
                            <Nav.Item as="li">
                              <p onClick={() => { navigate('/hours-track', { state: "users" }); }} > Hours Tracked </p>
                            </Nav.Item>

                            <Nav.Item as="li">
                              <p onClick={() => { navigate('/activity-summary', { state: "users" }); }} > Activity Summary </p>
                            </Nav.Item>
                            {allowedBasedOnPackage([ULTRA, PREMIUM], selectedCompany.billing?.package).isAllowed ?
                              <Nav.Item as="li">
                                <Link className='' to="/webs-apps"> Web & Apps </Link>
                              </Nav.Item>
                              :
                              <Nav.Item as="li" className=''>
                                <p className='' onClick={() => { navigate('/upgrade-please', { state: { title: "Websites & Apps Usage", description: "Total time spent on websites and apps, classified by productivity.", imgSrc: "images/login-black.svg", minPackage: "Premium" } }) }} >  Web & Apps
                                  <img src='images/padlock.svg' className='ms-2' />
                                </p>
                              </Nav.Item>
                            }

                            {allowedBasedOnRole([ADMIN, OWNER, CLIENT], selectedCompany.userRole) && allowedBasedOnPackage([ULTRA, PREMIUM], selectedCompany.billing?.package).isAllowed ?
                              <Nav.Item as="li">
                                <Link className='' to="/project-costing"> Project Costing </Link>
                              </Nav.Item>
                              :
                              <Nav.Item as="li" className=''>
                                <p className='' onClick={() => { navigate('/upgrade-please', { state: { title: "Project Costing", description: "Project budget and costing", imgSrc: "images/login-black.svg", minPackage: "Ultra" } }) }} >  Project Costing
                                  <img src='images/padlock.svg' className='ms-2' />
                                </p>
                              </Nav.Item>
                            }
                            {/* {allowedBasedOnRole([ADMIN, OWNER, CLIENT], selectedCompany.userRole) && allowedBasedOnPackage([ULTRA, PREMIUM], selectedCompany.billing?.package).isAllowed ?
                              <Nav.Item as="li">
                                <Link className='' to="/project-costing-new"> Project Costing New</Link>
                              </Nav.Item>
                              :
                              <Nav.Item as="li" className=''>
                                <p className='' onClick={() => { navigate('/upgrade-please', { state: { title: "Project Costing", description: "Project budget and costing", imgSrc: "images/login-black.svg", minPackage: "Ultra" } }) }} >  Project Costing New
                                  <img src='images/padlock.svg' className='ms-2' />
                                </p>
                              </Nav.Item>
                            } */}
                            
                            {showAllowed && allowedBasedOnPackage([ULTRA, PREMIUM], selectedCompany.billing?.package).isAllowed ?
                              <Nav.Item as="li">
                                <Link className='' to="/project-hours"> Project Progress </Link>
                              </Nav.Item>
                              :
                              <Nav.Item as="li" className=''>
                                <p className='' onClick={() => { navigate('/upgrade-please', { state: { title: "Project Progress", description: "Progress Monitor all projects at a glance.", imgSrc: "images/login-black.svg", minPackage: "Ultra" } }) }} > Project Progress
                                  <img src='images/padlock.svg' className='ms-2' />
                                </p>
                              </Nav.Item>
                            }

                            {showAllowed && allowedBasedOnPackage([ULTRA, PREMIUM], selectedCompany.billing?.package).isAllowed ?
                              <Nav.Item as="li">
                                <Link className='' to="/quarterly-performance"> Quarterly Performance </Link>
                              </Nav.Item>
                              :
                              <Nav.Item as="li" className=''>
                                <p className='' onClick={() => { navigate('/upgrade-please', { state: { title: "Quarterly Performance", description: "Work hour trends over a quarter.", imgSrc: "images/login-black.svg", minPackage: "Premium" } }) }} >  Quarterly Performance
                                  <img src='images/padlock.svg' className='ms-2' />
                                </p>
                              </Nav.Item>}

                            {/* <Nav.Item as="li">
                              <p className={(leftNavSelectedItem.selectedItem === "attendance-report" ? " selected " : "")} onClick={() => { navigate('/attendance-report'); }} > Attendance Report </p>
                            </Nav.Item> */}
                            {showAllowed && allowedBasedOnPackage([ULTRA, PREMIUM], selectedCompany.billing?.package).isAllowed ?
                              <ul className='not-allowed p-0'>
                              <Nav.Item as="li">
                                <Link className='' to="/profitability-report"> Profitability Report </Link>
                              </Nav.Item>
                              </ul>
                              :
                              <Nav.Item as="li" className=''>
                                <p className='' onClick={() => { navigate('/upgrade-please', { state: { title: "Profitability", description: "Check Profit Of Your Organization", imgSrc: "images/login-black.svg", minPackage: "Premium" } }) }} >  Profitability Report
                                  <img src='images/padlock.svg' className='ms-2' />
                                </p>
                              </Nav.Item>}

                            {showAllowed && allowedBasedOnPackage([ULTRA, PREMIUM], selectedCompany.billing?.package).isAllowed ?
                              <Nav.Item as="li">
                                <Link className='' to="/attendance-report"> Attendance Report </Link>
                              </Nav.Item>
                              :
                              <Nav.Item as="li" className=''>
                                <p className='' onClick={() => { navigate('/upgrade-please', { state: { title: "Attendance Report", description: "Breakdown of activity, by productivity per user.", imgSrc: "images/login-black.svg", minPackage: "Premium" } }) }} >  Attendance Report
                                  <img src='images/padlock.svg' className='ms-2' />
                                </p>
                              </Nav.Item>}
                          </Nav>
                        </div>

                        <div className='d-flex align-items-center align-items-baseline dropdown-menuu'>
                          <img width={15} src={"images/reports.svg"} />
                          <NavDropdown title="Reports" id="collapsible-nav-dropdown" show={showReports} onClick={() => { setShowReports(!showReports); }} className='arrow'>
                            <ul className='p-0'>
                              <Nav.Item as="li">
                                <p className={(leftNavSelectedItem.selectedItem === "hours-track" ? " selected " : "")} onClick={() => { navigate('/hours-track', { state: "users" }); }} > Hours Tracked </p>
                              </Nav.Item>
                              <Nav.Item as="li">
                                <p className={(leftNavSelectedItem.selectedItem === "activity-summary" ? " selected " : "")} onClick={() => { navigate('/activity-summary', { state: "users" }); }} > Activity Summary </p>
                              </Nav.Item>


                              <>
                                {allowedBasedOnRole([ADMIN, OWNER, CLIENT, GROUPADMIN], selectedCompany.userRole) && allowedBasedOnPackage([ULTRA, PREMIUM], selectedCompany.billing?.package).isAllowed ?
                                  <Nav.Item as="li">
                                    <Link className={(leftNavSelectedItem.selectedItem === "webs-apps" ? " selected " : "")} to="/webs-apps"> Web & Apps </Link>
                                  </Nav.Item>
                                  :
                                  <Nav.Item as="li" className=''>
                                    <img src='images/padlock.svg' />
                                    <h6 className='ms-1' onClick={() => { navigate('/upgrade-please', { state: { title: "Websites & Apps Usage", description: "Total time spent on websites and apps, classified by productivity.", imgSrc: "images/login-black.svg", minPackage: "Premium" } }) }} >  Web & Apps  </h6>
                                  </Nav.Item>}
                              </>

                              {allowedBasedOnRole([ADMIN, OWNER, CLIENT], selectedCompany.userRole) && allowedBasedOnPackage([ULTRA], selectedCompany.billing?.package).isAllowed ?
                                <Nav.Item as="li">
                                  <Link className={(leftNavSelectedItem.selectedItem === "project-costing" ? " selected " : "")} to="/project-costing"> Project Costing </Link>
                                </Nav.Item>
                                :
                                <Nav.Item as="li" className=''>
                                  <img src='images/padlock.svg' />
                                  <h6 className='ms-1' onClick={() => { navigate('/upgrade-please', { state: { title: "Project Costing", description: "Project budget and costing", imgSrc: "images/login-black.svg", minPackage: "Ultra" } }) }} >  Project Costing  </h6>
                                </Nav.Item>}
                              {/* {allowedBasedOnRole([ADMIN, OWNER, CLIENT], selectedCompany.userRole) && allowedBasedOnPackage([ULTRA], selectedCompany.billing?.package).isAllowed ?
                                <Nav.Item as="li">
                                  <Link className={(leftNavSelectedItem.selectedItem === "project-costing-new" ? " selected " : "")} to="/project-costing-new"> Project Costing New </Link>
                                </Nav.Item>
                                :
                                <Nav.Item as="li" className=''>
                                  <img src='images/padlock.svg' />
                                  <h6 className='ms-1' onClick={() => { navigate('/upgrade-please', { state: { title: "Project Costing New", description: "Project budget and costing", imgSrc: "images/login-black.svg", minPackage: "Ultra" } }) }} >  Project Costing New </h6>
                                </Nav.Item>} */}

                              {showAllowed && allowedBasedOnPackage([ULTRA], selectedCompany.billing?.package).isAllowed ?
                                <Nav.Item as="li">
                                  <Link className={(leftNavSelectedItem.selectedItem === "project-hours" ? " selected " : "")} to="/project-hours"> Project Progress </Link>
                                </Nav.Item>
                                :
                                <Nav.Item as="li" className=''>
                                  <img src='images/padlock.svg' />
                                  <h6 className='ms-1' onClick={() => { navigate('/upgrade-please', { state: { title: "Project Progress", description: "Progress Monitor all projects at a glance.", imgSrc: "images/login-black.svg", minPackage: "Ultra" } }) }} >  Project Progress  </h6>
                                </Nav.Item>}

                              {showAllowed && allowedBasedOnPackage([ULTRA, PREMIUM], selectedCompany.billing?.package).isAllowed ?
                                <Nav.Item as="li">
                                  <Link className={(leftNavSelectedItem.selectedItem === "quarterly-performance" ? " selected " : "")} to="/quarterly-performance"> Quarterly Performance </Link>
                                </Nav.Item>
                                :
                                <Nav.Item as="li" className=''>
                                  <img src='images/padlock.svg' />
                                  <h6 className='ms-1' onClick={() => { navigate('/upgrade-please', { state: { title: "Quarterly Performance", description: "Work hour trends over a quarter.", imgSrc: "images/login-black.svg", minPackage: "Premium" } }) }} >  Quarterly Performance  </h6>
                                </Nav.Item>}

                              {showAllowed && allowedBasedOnPackage([ULTRA, PREMIUM], selectedCompany.billing?.package).isAllowed ?
                              <ul className='not-allowed p-0'>
                                <Nav.Item as="li">
                                  <Link className={(leftNavSelectedItem.selectedItem === "profitability-report" ? " selected " : "")} to='/profitability-report' >  Profitability Report </Link>
                                </Nav.Item>
                              </ul>
                                :
                                <Nav.Item as="li" className=''>
                                  <img src='images/padlock.svg' />
                                  <h6 className='ms-1' onClick={() => { navigate('/upgrade-please', { state: { title: "Profitability Report", description: "Check Profit Of Your Organization", imgSrc: "images/login-black.svg", minPackage: "Premium" } }) }} > Profitability Report </h6>
                                </Nav.Item>}

                              {showAllowed && allowedBasedOnPackage([ULTRA, PREMIUM], selectedCompany.billing?.package).isAllowed ?
                                <Nav.Item as="li">
                                  <Link className={(leftNavSelectedItem.selectedItem === "attendance-report" ? " selected " : "")} to='/attendance-report' >  Attendance Report </Link>
                                </Nav.Item>
                                :
                                <Nav.Item as="li" className=''>
                                  <img src='images/padlock.svg' />
                                  <h6 className='ms-1' onClick={() => { navigate('/upgrade-please', { state: { title: "Attendance Report", description: "Breakdown of activity, by productivity per user.", imgSrc: "images/login-black.svg", minPackage: "Premium" } }) }} > Attendance Report </h6>
                                </Nav.Item>}
                            </ul>
                          </NavDropdown>
                        </div>
                      </Nav.Item>
                      :
                      <>
                        {
                          allowedBasedOnPackage([ULTRA, PREMIUM], selectedCompany.billing?.package).isAllowed ?
                            <Nav.Item as="li" className='mb-2'>
                              <Link className={"d-flex align-items-center " + (leftNavSelectedItem.selectedItem === "webs-apps" ? " selected " : "")} to="/webs-apps" >
                                <img src={"images/web.svg"} />
                                <span>Web & Apps</span></Link>
                              <Nav as="ul" className='hover-menu'>
                                <p>Web & Apps</p>
                              </Nav>
                            </Nav.Item>
                            :
                            <Nav.Item as="li" className=''>
                              <img src='images/padlock.svg' />
                              <h6 className='ms-1' onClick={() => { navigate('/upgrade-please', { state: { title: "Websites & Apps Usage", description: "Total time spent on websites and apps, classified by productivity.", imgSrc: "images/login-black.svg", minPackage: "Premium" } }) }} >  Web & Apps  </h6>
                            </Nav.Item>
                        }
                      </>

                    }

                    {allowedBasedOnRole([ADMIN, OWNER, CLIENT], selectedCompany.userRole) &&
                      <Nav.Item as="li" className='mb-2 arrow'>
                        <div className='dashbaord-li'>
                          <Nav.Link className='d-flex align-items-center'>
                            <img src={"images/setting.svg"} />
                            <span>Settings</span></Nav.Link>
                          <Nav as="ul" className='hover-menu'>
                            {allowedBasedOnRole([ADMIN, OWNER, CLIENT], selectedCompany.userRole) && <Nav.Item as="li">
                              <p onClick={() => { navigate('/projects', { state: "users" }); }} > Projects &Tasks </p>
                            </Nav.Item>}
                            {allowedBasedOnRole([ADMIN, OWNER, CLIENT], selectedCompany.userRole) && allowedBasedOnPackage([ULTRA, PREMIUM], selectedCompany.billing?.package).isAllowed ?
                              <Nav.Item as="li">
                                <p onClick={() => { navigate('/groups', { state: "users" }); }} > Groups </p>
                              </Nav.Item>
                              :
                              <Nav.Item as="li" className='d-flex'>
                                <p className='' onClick={() => { navigate('/upgrade-please', { state: { title: "Groups", description: "Create, edit, or archive groups for your organization", imgSrc: "images/login-black.svg", minPackage: "Premium" } }) }} >  Groups
                                  <img src='images/padlock.svg' className='ms-2' />
                                </p>
                              </Nav.Item>
                            }
                            {showAllowed &&
                              allowedBasedOnPackage([ULTRA, PREMIUM], selectedCompany.billing?.package).isAllowed ?
                              <Nav.Item as="li">
                                <p onClick={() => { navigate('/webapp-rating', { state: "users" }); }} >  Web & Apps Rating </p>
                              </Nav.Item>
                              : <Nav.Item as="li" className='d-flex'>
                                <p className='' onClick={() => { navigate('/upgrade-please', { state: { title: "Web & Apps Rating", description: "Rate websites and apps based on productivity for your organization.", imgSrc: "images/login-black.svg", minPackage: "Premium" } }) }} >  Web & Apps Rating
                                  <img src='images/padlock.svg' className='ms-2' />
                                </p>
                              </Nav.Item>
                            }

                            {showAllowed && <Nav.Item as="li">
                              <p onClick={() => { navigate('/user-management', { state: "users" }); }} >  Users</p>
                            </Nav.Item>}

                            {showAllowed && allowedBasedOnPackage([ULTRA], selectedCompany.billing?.package).isAllowed ?
                              <Nav.Item as="li">
                                <p>  Clients</p>
                              </Nav.Item> 
                              :
                              <Nav.Item as="li" className='d-flex'>
                                <p className='' onClick={() => { navigate('/upgrade-please', { state: { title: "Clients", description: "Manage your clients.", imgSrc: "images/login-black.svg", minPackage: "Ultra" } }) }} >  Client
                                  <img src='images/padlock.svg' className='ms-2' />
                                </p>
                              </Nav.Item>}

                            {showAllowed && allowedBasedOnPackage([ULTRA, PREMIUM], selectedCompany.billing?.package).isAllowed ?
                              <Nav.Item as="li">
                                <p onClick={() => { navigate('/email-notification', { state: "users" }); }} >  Email Notification</p>
                              </Nav.Item>
                              :
                              <Nav.Item as="li" className='d-flex'>
                                <p className='' onClick={() => { navigate('/upgrade-please', { state: { title: "Email Notification", description: "Customize report preferences, receive timely updates in your inbox.", imgSrc: "images/login-black.svg", minPackage: "Premium" } }) }} >  Email Notification
                                  <img src='images/padlock.svg' className='ms-2' />
                                </p>
                              </Nav.Item>
                            }
                          </Nav>
                        </div>


                        <div className='d-flex align-items-center align-items-baseline dropdown-menuu'>
                          <img width={15} src={"images/setting.svg"} />
                          <NavDropdown title="Settings" id="collapsible-nav-dropdown" show={showSettings} onClick={() => { setShowSettings(!showSettings); }} className='arrow '>
                            <ul className='p-0 '>
                              {allowedBasedOnRole([ADMIN, OWNER, CLIENT], selectedCompany.userRole) && <Nav.Item as="li">
                                <p className={(leftNavSelectedItem.selectedItem === "projects" ? " selected " : "")} onClick={() => { navigate('/projects', { state: "users" }); }} >  Projects & Tasks</p>
                              </Nav.Item>}

                              {allowedBasedOnRole([ADMIN, OWNER, CLIENT], selectedCompany.userRole) &&
                                allowedBasedOnPackage([ULTRA, PREMIUM], selectedCompany.billing?.package).isAllowed ?
                                <Nav.Item as="li">
                                  <p className={(leftNavSelectedItem.selectedItem === "groups" ? " selected " : "")} onClick={() => { navigate('/groups', { state: "users" }); }} > Groups </p>
                                </Nav.Item>
                                :
                                <Nav.Item as="li" className=''>
                                  <img src='images/padlock.svg' />
                                  <h6 className='ms-1' onClick={() => { navigate('/upgrade-please', { state: { title: "Groups", description: "Create, edit, or archive groups for your organization", imgSrc: "images/login-black.svg", minPackage: "Premium" } }) }} > Groups </h6>
                                </Nav.Item>
                              }

                              {showAllowed &&
                                allowedBasedOnPackage([ULTRA, PREMIUM], selectedCompany.billing?.package).isAllowed ?
                                <Nav.Item as="li">
                                  <p className={(leftNavSelectedItem.selectedItem === "webapp-rating" ? " selected " : "")} onClick={() => { navigate('/webapp-rating', { state: "users" }); }} > Web & Apps Rating </p>
                                </Nav.Item>
                                :
                                <Nav.Item as="li" className=''>
                                  <img src='images/padlock.svg' />
                                  <h6 className='ms-1' onClick={() => { navigate('/upgrade-please', { state: { title: "Web & Apps Rating", description: "Rate websites and apps based on productivity for your organization.", imgSrc: "images/login-black.svg", minPackage: "Premium" } }) }} >  Web & Apps Rating </h6>
                                </Nav.Item>
                              }

                              {showAllowed && <Nav.Item as="li">
                                <p className={(leftNavSelectedItem.selectedItem === "user-management" ? " selected " : "")} onClick={() => { navigate('/user-management', { state: "users" }); }} > Users </p>
                              </Nav.Item>
                              }
                            <ul className='not-allowed p-0'>
                              {allowedBasedOnRole([ADMIN, OWNER], selectedCompany.userRole) &&
                                allowedBasedOnPackage([ULTRA], selectedCompany.billing?.package).isAllowed ?
                                <Nav.Item as="li">
                                  <p className={(leftNavSelectedItem.selectedItem === "clients" ? " selected " : "")}> Clients </p>
                                </Nav.Item>
                                :
                                <Nav.Item as="li" className=''>
                                  <img src='images/padlock.svg' />
                                  <h6 className='ms-1' onClick={() => { navigate('/upgrade-please', { state: { title: "Clients", description: "Manage your clients", imgSrc: "images/login-black.svg", minPackage: "Ultra" } }) }} > Clients  </h6>
                                </Nav.Item>
                                
                              }
                             
                            </ul>
                              {allowedBasedOnRole([CLIENT], selectedCompany.userRole) &&
                                <Nav.Item as="li" className='mb-2 '>
                                  <p className={(leftNavSelectedItem.selectedItem === "my-team" ? " selected " : "")} onClick={() => { navigate('/my-team', { state: "clients" }); }}> My Team </p>
                                </Nav.Item>
                              }

                              <ul className='not-allowed p-0'>
                              {showAllowed &&
                                allowedBasedOnPackage([ULTRA, PREMIUM], selectedCompany.billing?.package).isAllowed ?
                                <Nav.Item as="li" className='mb-2 '>
                                  <p className={(leftNavSelectedItem.selectedItem === "email-notification" ? " selected " : "")} onClick={() => { navigate('/email-notification', { state: "clients" }); }}> Email Notification </p>
                                </Nav.Item>
                                :
                                <Nav.Item as="li" className=''>
                                  <img src='images/padlock.svg' />
                                  <h6 className='ms-1' onClick={() => { navigate('/upgrade-please', { state: { title: "Email Notification", description: "Customize report preferences, receive timely updates in your inbox.", imgSrc: "images/login-black.svg", minPackage: "Premium" } }) }} >  Email Notification  </h6>
                                </Nav.Item>
                              }
                              </ul>
                            </ul>
                          </NavDropdown>
                        </div>
                      </Nav.Item>
                      }


                    {showAllowed && <Nav.Item as="li" className='mb-2 arrow'>
                      <div className='dashbaord-li'>
                        <Nav.Link className='d-flex align-items-center'>
                          <img src={"images/billing-details.svg"} />
                          <span>Billings</span></Nav.Link>
                        <Nav as="ul" className='hover-menu'>
                          <Nav.Item as="li">
                            <p onClick={() => { navigate('/payroll', { state: "users" }); }} >  Payroll </p>
                          </Nav.Item>
                          <Nav.Item as="li">
                            <p onClick={() => { navigate('/billing-details', { state: "users" }); }} > Billing Details </p>
                          </Nav.Item>

                        </Nav>
                      </div>



                      <div className='d-flex align-items-center align-items-baseline dropdown-menuu'>
                        <img width={15} src={"images/billing-details.svg"} />
                        <NavDropdown title="Billings" id="collapsible-nav-dropdown" show={showBillings} onClick={() => { setShowBillings(!showBillings); }} className='arrow'>
                          <ul className='p-0'>
                            <Nav.Item as="li">
                              <p className={(leftNavSelectedItem.selectedItem === "billing-details" ? " selected " : "")} onClick={() => { navigate('/billing-details', { state: "users" }); }} >  Billing Details</p>
                            </Nav.Item>
                            <Nav.Item as="li">
                              <p className={(leftNavSelectedItem.selectedItem === "payroll" ? " selected " : "")} onClick={() => { navigate('/payroll', { state: "users" }); }} > Payroll </p>
                            </Nav.Item>

                          </ul>
                        </NavDropdown>
                      </div>
                    </Nav.Item>}




                    <Nav.Item as="li" className='mb-2'>
                      <Link className={"d-flex align-items-center " + (leftNavSelectedItem.selectedItem === "screenshots" ? " selected " : "")} to="/screenshots" >
                        <img src={"images/screenshot.svg"} />
                        <span>Screenshots</span></Link>
                      <Nav as="ul" className='hover-menu'>
                        <p>Screenshots</p>
                      </Nav>
                    </Nav.Item>

                    <Nav.Item as="li" className='mb-2'>
                      <Link className={"d-flex align-items-center " + (leftNavSelectedItem.selectedItem === "downloads" ? " selected " : "")} to="/downloads" >
                        <img src={"images/download.svg"} />
                        <span>Downloads</span></Link>
                      <Nav as="ul" className='hover-menu'>
                        <p>Downloads</p>
                      </Nav>
                    </Nav.Item>

                    {/* <Nav.Item as="li" className='mb-2'>
                      <Link className={"d-flex align-items-center " + (leftNavSelectedItem.selectedItem === "organization" ? " selected " : "")} to="/organization" >
                        <img src={"images/download.svg"} />
                        <span>Organization Profile New</span></Link>
                      <Nav as="ul" className='hover-menu'>
                        <p>Organization Profile New</p>
                      </Nav>
                    </Nav.Item>
                    <Nav.Item as="li" className='mb-2'>
                      <Link className={"d-flex align-items-center " + (leftNavSelectedItem.selectedItem === "profile" ? " selected " : "")} to="/profile" >
                        <img src={"images/download.svg"} />
                        <span>My Profile New</span></Link>
                      <Nav as="ul" className='hover-menu'>
                        <p>My Profile New</p>
                      </Nav>
                    </Nav.Item> */}
                  </Nav>
                </div>
              </div>
            </Navbar.Offcanvas>
          </Container>
        </Navbar >

      ))
      }

      {!internetAvailable && <NoInternet />}
      {/* {new Date(selectedCompany.billing?.endDate) < new Date() && <PackageExpire expDate={selectedCompany.billing?.endDate} />} */}
    </>
  );
}

const mapStateToProps = state => ({
  selectedCompany: state.UserReducer.selectedCompany,
  leftNavExpand: state.SupportReducer.leftNavExpand,
  internetAvailable: state.SupportReducer.internetAvailable,
  leftNavSelectedItem: state.LeftNavReducer.leftNavSelectedItem,
});

export default connect(mapStateToProps)(LeftNav);
