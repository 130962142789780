import React, { useState, useRef, useEffect } from "react";
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector, connect } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import './style.css';
import { login, SocialLogin } from "../../redux/actions/AuthAction";
import { fetchLogedInUserProfile } from "../../redux/actions/UserAction";
import { CLEAR_MESSAGE } from "../../redux/actions/Types";
// import { profile } from "../../utils/Constants";
import { fetchUserCompanies } from "../../redux/actions/UserAction";
import { allowedBasedOnRole } from "../../common/GlobalFunctions";
import { ADMIN, GROUPADMIN, OWNER } from '../../utils/Constants';
// import GoogleLoginButton from './GoogleLoginButton';
// import { GoogleOAuthProvider } from '@react-oauth/google';
// import ResultMessage from "../../components/ResultMessage";

const LOGIN = (props) => {
  const { selectedCompany } = props;
  const formRef = useRef(null);
  // const checkBtn = useRef();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [authType, setAuthType] = useState('web')
  const [loading, setLoading] = useState(false);
  const [compChk, setcompChk] = useState(false);
  const { isLoggedIn } = useSelector(state => state.AuthReducer);
  const { message } = useSelector(state => state.MessageReducer);
  const [msg, setMsg] = useState(message);
  const [singIn, setSignIn] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const [showMessage, setShowMessage] = useState(false);
  // const [resultClass, setResultClass] = useState("");


  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };


  useEffect(() => {
    setShowMessage(false);
    dispatch({
      type: CLEAR_MESSAGE,
    });
    if (isLoggedIn) {
      navigate(allowedBasedOnRole([ADMIN, OWNER, GROUPADMIN], selectedCompany.userRole) === true ? "/team-dashboard" : "/user-dashboard");
    }
  }, [])

  useEffect(() => {
    if (message === "Wrong credentials") {
      setMsg("The password / email combination is incorrect!")
      // setResultClass('error');
      setShowMessage(true);
    }
    else {
      setMsg(message);
      // setResultClass('success');
    }

  }, [message])

  const searchParams = new URLSearchParams(window.location.search);
  useEffect(() => {
    const status = searchParams.get("status");
    if (status === 'success') {
      const token = searchParams.get("token");
      handleSocialLogin(token)
      // console.log(status)
    }
  }, [searchParams.get('status')])
  // console.log(searchParams)


  const onChangeUsername = (e) => {
    const username_inp = e.target.value;
    setUsername(username_inp.toLowerCase());
  };

  const onChangePassword = (e) => {
    const password_inp = e.target.value;
    setPassword(password_inp);
  };

  const handleLogin = (e) => {
    e.preventDefault();

    if (password.length < 6) {
      setMsg("The password / email combination is incorrect!")
      setShowMessage(true);
      return;
    }



    setMsg("");
    setLoading(true);
    setShowMessage(false);
    dispatch({
      type: CLEAR_MESSAGE,
    });

    dispatch(login(username, password, authType))
      .then(() => {
        dispatch(fetchLogedInUserProfile()).then(() => {
          dispatch(fetchUserCompanies())
            .then(() => {
              setLoading(false);
              setSignIn(true);
              setcompChk(true);
              setShowMessage(false);
            })
        })
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleSocialLogin = (eToken) => {
    setMsg("");
    setLoading(true);
    setShowMessage(false);
    dispatch({
      type: CLEAR_MESSAGE,
    });

    dispatch(SocialLogin(eToken))
      .then(() => {
        // console.log('then')
        dispatch(fetchLogedInUserProfile()).then(() => {
          dispatch(fetchUserCompanies())
            .then(() => {
              setLoading(false);
              setSignIn(true);
              setcompChk(true);
              setShowMessage(false);
            })
        })
      })
      .catch(() => {
        // setLoading(false);
      });
  };

  const handleLoginGoogle = (usrEmail, usrCode, UsruthType) => {
    setMsg("");
    setLoading(true);

    dispatch(login(usrEmail, usrCode, UsruthType))
      .then(() => {
        dispatch(fetchLogedInUserProfile()).then(() => {
          dispatch(fetchUserCompanies())
            .then(() => {
              setLoading(false);
              setSignIn(true);
              setcompChk(true);
            })
        })
      })
      .catch(() => {
        setLoading(false);
      });
  };

  if (isLoggedIn && singIn && compChk) {

    // if (userProfile.status != "Active" || userCompanies.length < 1) {
    //   clearLocalStorage();
    //   setMsg("Oops! You are not a part of any organization");
    //   return;
    // }

    // if (userCompanies.length > 1) 
    navigate("/company-selection")

    // else if (userCompanies.length === 1) {

    //   if (userCompanies[0].orgStatus === 'Active' && userCompanies[0].userStatus === 'Active') {
    //     dispatch({
    //       type: SELECTED_COMPANY_SELECTION_SUCCESS,
    //       payload: { selectedCompany: userCompanies[0] },
    //     });
    //     // check user condition here weather it is admin or admin of any group
    //     navigate(allowedBasedOnRole([ADMIN, OWNER, GROUPADMIN], userCompanies[0].userRole) === true ? "/team-dashboard" : "/");
    //   }

    //   else {
    //     clearLocalStorage();
    //     setMsg("Oops! Either you are no more active member of an organization or your company is inactive");
    //   }
    // } setcompChk(false);
  }
  // for google authentication
  // function googleAuth(uEmail, gCode, aType){
  //   // console.log('fEmail', uEmail)
  //   // console.log('fpassword', gCode)
  //   // console.log('fauthType', aType)
  //   handleLoginGoogle(uEmail, gCode, aType)
  // }


  return (
    <>
      <div className='login login-pagee'>
        <Row className='m-0'>
          <Col xs={12} md={12} className='box padding-left pt-3 pb-5 d-flex align-items-center'>
            <div className='max-width-login px-1'>
              <div className='logo pt-0 mb-3'>
                <img src="images/login-black.svg" />
              </div>
              {/* <p className='free-trial'>start your 14 days free trial</p> */}
              <div className="mt-2">
                <div className=" d-flex justify-content-center google-facebook-icons">
                  <Link className="btn btn-primary" to={'https://wapi.timebee.app/auth/google'}><img src={"images/google-icon.svg"} /></Link>
                  <Link className="btn btn-primary" to={'https://wapi.timebee.app/auth/facebook'}><img src={"images/facebook-icon.svg"} /></Link>
                </div>
              </div>
              <div className="or d-flex align-items-center justify-content-center mb-0"><span className="line"></span><p className="m-0 mx-3">or</p><span className="line"></span></div>
              <Form onSubmit={handleLogin} ref={formRef}>
                <Form.Group className="mb-2 mb-xxl-3" controlId="formBasicEmail">
                  <Form.Label className='fs-16 font-medium m-0 mb-2'>Your Email</Form.Label>
                  <Form.Control type="email" placeholder="Email Address" onChange={onChangeUsername} autoComplete="off" required />
                </Form.Group>

                <Form.Group className="mb-2 mb-xxl-3" controlId="formBasicPassword">
                  <Form.Label className='fs-16 font-medium m-0 mb-2'>Password</Form.Label>

                  <div className="password-box">
                    <Form.Control type={isPasswordVisible ? 'text' : 'password'} onChange={onChangePassword} autoComplete="off" placeholder="Password" required />

                    <span
                      className="show-hide-password"
                      onClick={togglePasswordVisibility}
                    >
                      {isPasswordVisible ? <img src="images/openEye.svg" alte="Show" /> : <img src="images/closeEye.svg" alte="Hide" />}
                    </span>
                  </div>
                </Form.Group>

                <Form.Group className="mb-2 mb-xxl-3 d-flex justify-content-between" controlId="formBasicCheckbox">
                  <p >
                    {/* <div className="mb-3">
                    <Form.Check>
                      <Form.Check.Input />
                      <Form.Check.Label>Keep me logged in</Form.Check.Label>
                    </Form.Check>
                  </div> */}
                  </p>
                  <Link className='forgot-password mb-2 mb-lg-4' to={'/forget-password'}>forgot password</Link>
                </Form.Group>

                {showMessage && <p className="text-center text-danger m-0">{msg} </p>}

                <Button className='fs-16 text-white font-regular py-1 my-2' type="submit" disabled={loading}>
                  {loading && (
                    <span className="spinner-border spinner-border-sm">&nbsp;</span>
                  )}
                  <span>Login</span>
                </Button>
                <p className='text-center'>Don't have an account?<Link to="/signup" className='ms-2 sign-in'>Sign up</Link></p>
              </Form>
            </div>
            <div className="loginlinks d-flex justify-content-center footer-btns mt-1">
              <a target="_blank" href="https://timebee.app/contact-us/">Contact Support</a>
              <span className="mx-2">|</span>
              <a target="_blank" href="https://timebee.app/privacy-policy/" className="b2">Privacy Policy</a>
              <span className="mx-2 hide-border">|</span>
              <a target="_blank" href="https://timebee.app/terms-of-service/">Terms of Services</a>
            </div>
          </Col>
          <Col xs={12} md={6} className='bg-colore box pt-5 d-none align-items-center'>
            <div className="text-box">
              <div className="text pt-2">
                <h4 className='mb-3 text-white'>Productivity Awaits!</h4>
                <p className='text-white paragraph pb-4'>Incorporate technology in your business like never before! With TimeBee, boost productivity, reduce costs, and empower better decision-making through robust time and workforce management! </p>
                {/* <Button className='try-it-out'>Try It Out</Button> */}
              </div>
              <div className='img loginImg'>
                <img src={"images/login-img.png"} />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>

  )
}
const mapStateToProps = state => ({
  userCompanies: state.UserReducer.userCompanies,
  selectedCompany: state.UserReducer.selectedCompany,
  //  message: state.MessageReducer.message,
  isLoggedIn: state.AuthReducer.isLoggedIn,
  userProfile: state.UserReducer.userProfile,
});

export default connect(mapStateToProps)(LOGIN);