const dev = {
  API: {
    API_URL_DEVICE_PYTHON: "https://localhost:8080/",
    // API_URL_WEB_PHP: "http://localhost:8000/",
    API_URL_WEB_PHP: "https://demowapi.timebee.app/",
    // API_URL_WEB_PHP: "https://qa-wapi.timebee.app/",
  },
  profile: {
    name: "dev"
  },
};

const prod = {
  API: {
    API_URL_DEVICE_PYTHON: "https://demodapi.timebee.app/",
    API_URL_WEB_PHP: "https://demowapi.timebee.app/",
  },
  profile: {
    name: "prod",
  },
};

const qa = {
  API: {
    API_URL_DEVICE_PYTHON: "https://demodapi.timebee.app/",
    API_URL_WEB_PHP: "https://demowapi.timebee.app/",
  },
  profile: {
    name: "qa",
  },
};

function getConfig() {
  if (
    process.env.REACT_APP_STAGE === undefined ||
    process.env.REACT_APP_STAGE === null
  ) {
    return dev;
  } else if (
    process.env.REACT_APP_STAGE === "prod" ||
    process.env.REACT_APP_STAGE === "production"
  ) {
    return prod;
  } else if (process.env.REACT_APP_STAGE === "dev") {
    return dev;
  } else if (process.env.REACT_APP_STAGE === "qa") {
    return qa;
  } else {
    return dev;
  }
}
const config = getConfig();

export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config,
};
