import React from 'react'
import { timeFormat, reverse, timeFormatInHMorMS, noDataGraph } from '../../../common/GlobalFunctions'
import { PieChart, Pie, Sector, Cell, Label} from "recharts";
import './style.css';
// const data = [
//   { value: 400 },
//   { value: 300 },
//   { value: 300 },
//   { value: 200 }
// ];
const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];



const PieChartProjectTask = (props) => {
  const { totalTime, data } = props
  return (
    <>
    <PieChart  width={300} height={300}>
        <Pie
            data={data}
            cx={120}
            cy={200}
            innerRadius={60}
            outerRadius={80}
            fill="#8884d8"
            paddingAngle={0}
            dataKey="value"
        >
          <Label 
          value={totalTime} position="centerBottom"  className='label-top' fontSize='27px'
          />
          <Label 
          value="Total Time" position="centerTop" className='label-bottom mt-2'
          />
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </>
  )
}
export default PieChartProjectTask