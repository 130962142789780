
import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import TimezoneSelect from 'react-timezone-select'
import { Form } from 'react-bootstrap';
import './style.css';
import { allowedBasedOnRole } from '../../common/GlobalFunctions';
import { ADMIN, OWNER } from '../../utils/Constants';


const CompanyTimeZone = (props) => {
  const { defaultTimeZone, currentUserRole, timeZoneRef, setTimeZoneValue, setOffSetValue } = props

  const [selectedTimezone, setSelectedTimezone] = useState(defaultTimeZone.length === 0 || defaultTimeZone === 'UTC' ? 'Etc/GMT' : defaultTimeZone)

  return (
    <>
      <TimezoneSelect
        value={selectedTimezone}
        displayValue={'UTC'}
        className='custom-timezone-class'
        isDisabled
      />
    </>
  );
}
export default CompanyTimeZone;
