import {
    SET_MESSAGE,
    FETCH_USER_DASHBOARD_USERS_DROPDOWN_SUCCESS,
    FETCH_USER_DASHBOARD_USERS_DROPDOWN_FAILED,
    FETCH_USER_DASHBOARD_COMULATIVETIME_SUCCESS,
    FETCH_USER_DASHBOARD_COMULATIVETIME_FAILED,
    FETCH_USER_DASHBOARD_USERS_TIMELINE_SUCCESS,
    FETCH_USER_DASHBOARD_USERS_TIMELINE_FAILED,
    FETCH_USER_DASHBOARD_HOURS_TRACKED_PRODUCTIVE_SUCCESS,
    FETCH_USER_DASHBOARD_HOURS_TRACKED_PRODUCTIVE_FAILED,
    FETCH_USER_DASHBOARD_HOURS_TRACKED_UNPRODUCTIVE_SUCCESS,
    FETCH_USER_DASHBOARD_HOURS_TRACKED_UNPRODUCTIVE_FAILED,
    FETCH_USER_DASHBOARD_PRODUCTIVITY_PERCENTAGE_SUCCESS,
    FETCH_USER_DASHBOARD_PRODUCTIVITY_PERCENTAGE_FAILED,
    FETCH_USER_DASHBOARD_HOURLY_CATALOG_TRACK_FAILED,
    FETCH_USER_DASHBOARD_HOURLY_CATALOG_TRACK_SUCCESS,
    FETCH_USER_DASHBOARD_HOURLY_SCREENSHOT_SUCCESS,
    FETCH_USER_DASHBOARD_HOURLY_SCREENSHOT_FAILED,
    FETCH_MORE_USER_DASHBOARD_HOURLY_SCREENSHOT_SUCCESS,
    FETCH_MORE_USER_DASHBOARD_HOURLY_SCREENSHOT_FAILED,
    FETCH_MORE_USER_WEB_APP_USAGE_SUCCESS,
    FETCH_MORE_USER_WEB_APP_USAGE_FAILED,
    FETCH_USER_WEB_APP_USAGE_SUCCESS,
    FETCH_USER_WEB_APP_USAGE_FAILED,
    FETCH_USER_WPRKED_PROJECT_TASK_SUCCESS,
    FETCH_USER_WPRKED_PROJECT_TASK_FAILED,
    FETCH_CATALOG_TITLE_DETAIL_SUCCESS,
    FETCH_CATALOG_TITLE_DETAIL_FAILED,
    FETCH_MORE_CATALOG_TITLE_DETAIL_SUCCESS,
    FETCH_MORE_CATALOG_TITLE_DETAIL_FAILED,
    INTERNET_AVAILABILITY
} from "./Types";

import UserDashboardService from "../../services/UserDashboardService";
import { getActiveTimezone, toIsoDate } from "../../common/GlobalFunctions";

export const fetchUserDashboardComulativeTime = (orgId, dateRange, user) => (dispatch) => {

    var data = {
        "organizationId": orgId,
        "startTime": toIsoDate(dateRange.dateRange[0], 0),
        "endTime": toIsoDate(dateRange.dateRange[1], 1),
        "users": user,
        "timezone": getActiveTimezone().timeZoneOffset
    }
    return UserDashboardService.fetchUserDashboardComulativeTime(data).then(
        (data) => {
            if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
                dispatch({
                    type: FETCH_USER_DASHBOARD_COMULATIVETIME_SUCCESS,
                    payload: { userDashboardComulativeTime: data },
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: true },
                });
                return Promise.resolve();
            }
            else if (data === "ERR_NETWORK") {
                dispatch({
                    type: FETCH_USER_DASHBOARD_COMULATIVETIME_FAILED,
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: false },
                });
                return Promise.reject();
            }
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: FETCH_USER_DASHBOARD_COMULATIVETIME_FAILED,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};


export const fetchUserDashboardUsersDropDown = (orgId, searchKeyword, skip, limit) => (dispatch) => {
    var data = {
        "organizationId": orgId,
        "search": searchKeyword,
        "skip": skip,
        "limit": limit
    }

    return UserDashboardService.fetchUserDashboardUsersDropDown(data).then(
        (data) => {
            if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
                dispatch({
                    type: FETCH_USER_DASHBOARD_USERS_DROPDOWN_SUCCESS,
                    payload: { userDashboardUsersDropDown: data },
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: true },
                });
                return Promise.resolve();
            }
            else if (data === "ERR_NETWORK") {
                dispatch({
                    type: FETCH_USER_DASHBOARD_USERS_DROPDOWN_FAILED,
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: false },
                });
                return Promise.reject();
            }
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: FETCH_USER_DASHBOARD_USERS_DROPDOWN_FAILED,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};

export const fetchUserDashboardUsersTimeline = (orgId, dateRange, user) => (dispatch) => {

    var data = {
        "organizationId": orgId,
        "startTime": toIsoDate(dateRange.dateRange[0], 0),
        "endTime": toIsoDate(dateRange.dateRange[1], 1),
        "users": user,
        "timezone": getActiveTimezone().timeZoneOffset
    }

    return UserDashboardService.fetchUserDashboardUsersTimeline(data).then(
        (data) => {
            if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
                data['endDate'] = dateRange.dateRange[1];
                dispatch({
                    type: FETCH_USER_DASHBOARD_USERS_TIMELINE_SUCCESS,
                    payload: { userDashboardUserTimeline: data },
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: true },
                });
                return Promise.resolve();
            }
            else if (data === "ERR_NETWORK") {
                dispatch({
                    type: FETCH_USER_DASHBOARD_USERS_TIMELINE_FAILED,
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: false },
                });
                return Promise.reject();
            }
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: FETCH_USER_DASHBOARD_USERS_TIMELINE_FAILED,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};

export const fetchUserDashboardHoursTrackedProductive = (orgId, dateRange, scope, user) => (dispatch) => {

    var data = {
        "organizationId": orgId,
        "startTime": toIsoDate(dateRange.dateRange[0], 0),
        "endTime": toIsoDate(dateRange.dateRange[1], 1),
        "users": user,
        "ratingScope": scope,
        "orderBy": "Productive",
        "timezone": getActiveTimezone().timeZoneOffset
    }
    return UserDashboardService.fetchUserDashboardHoursTrackedProductive(data).then(
        (data) => {
            if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
                data['endDate'] = dateRange.dateRange[1];
                dispatch({
                    type: FETCH_USER_DASHBOARD_HOURS_TRACKED_PRODUCTIVE_SUCCESS,
                    payload: { UserDashboardHoursTrackedProductive: data },
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: true },
                });
                return Promise.resolve();
            }
            else if (data === "ERR_NETWORK") {
                dispatch({
                    type: FETCH_USER_DASHBOARD_HOURS_TRACKED_PRODUCTIVE_FAILED,
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: false },
                });
                return Promise.reject();
            }
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: FETCH_USER_DASHBOARD_HOURS_TRACKED_PRODUCTIVE_FAILED,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};

export const fetchUserDashboardHoursTrackedUnProductive = (orgId, dateRange, scope, user) => (dispatch) => {

    var data = {
        "organizationId": orgId,
        "startTime": toIsoDate(dateRange.dateRange[0], 0),
        "endTime": toIsoDate(dateRange.dateRange[1], 1),
        "users": user,
        "ratingScope": scope,
        "orderBy": "UnProductive",
        "timezone": getActiveTimezone().timeZoneOffset
    }
    return UserDashboardService.fetchUserDashboardHoursTrackedUnProductive(data).then(
        (data) => {
            if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
                data['endDate'] = dateRange.dateRange[1];
                dispatch({
                    type: FETCH_USER_DASHBOARD_HOURS_TRACKED_UNPRODUCTIVE_SUCCESS,
                    payload: { UserDashboardHoursTrackedUnProductive: data },
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: true },
                });
                return Promise.resolve();
            }
            else if (data === "ERR_NETWORK") {
                dispatch({
                    type: FETCH_USER_DASHBOARD_HOURS_TRACKED_UNPRODUCTIVE_FAILED,
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: false },
                });
                return Promise.reject();
            }
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: FETCH_USER_DASHBOARD_HOURS_TRACKED_UNPRODUCTIVE_FAILED,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};


export const fetchUserDashboardProductivityPercentage = (orgId, dateRange, scope, user) => (dispatch) => {

    var data = {
        "organizationId": orgId,
        "startTime": toIsoDate(dateRange.dateRange[0], 0),
        "endTime": toIsoDate(dateRange.dateRange[1], 1),
        "ratingScope": scope,
        "users": user,
        "timezone": getActiveTimezone().timeZoneOffset
    }
    return UserDashboardService.fetchUserDashboardProductivityPercentage(data).then(
        (data) => {
            if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
                data['endDate'] = dateRange.dateRange[1];
                dispatch({
                    type: FETCH_USER_DASHBOARD_PRODUCTIVITY_PERCENTAGE_SUCCESS,
                    payload: { UserDashboardProductivityPercentage: data },
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: true },
                });
                return Promise.resolve();
            }
            else if (data === "ERR_NETWORK") {
                dispatch({
                    type: FETCH_USER_DASHBOARD_PRODUCTIVITY_PERCENTAGE_FAILED,
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: false },
                });
                return Promise.reject();
            }
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: FETCH_USER_DASHBOARD_PRODUCTIVITY_PERCENTAGE_FAILED,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};

export const fetchUserDashboardHourlyCatalogTrack = (orgId, dateRange, user, taskId) => (dispatch) => {

    var data = {
        "organizationId": orgId,
        "startTime": dateRange[0],
        "endTime": dateRange[1],
        "users": user,
        "taskId": taskId,
        "timezone": getActiveTimezone().timeZoneOffset
    }
    return UserDashboardService.fetchUserDashboardHourlyCatalogTrack(data).then(
        (data) => {
            if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
                dispatch({
                    type: FETCH_USER_DASHBOARD_HOURLY_CATALOG_TRACK_SUCCESS,
                    payload: { UserDashboardHourlyCatalogTrack: data },
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: true },
                });
                return Promise.resolve();
            }
            else if (data === "ERR_NETWORK") {
                dispatch({
                    type: FETCH_USER_DASHBOARD_HOURLY_CATALOG_TRACK_FAILED,
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: false },
                });
                return Promise.reject();
            }
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: FETCH_USER_DASHBOARD_HOURLY_CATALOG_TRACK_FAILED,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};

// export const fetchUserDashboardHourlyScreenshot = (orgId, dateRange, user, startIndex, limit) => (dispatch) => {

//     var data = {
//         "organizationId": orgId,
//         "startTime": dateRange[0],
//         "endTime": dateRange[1],
//         "users": user,
//         "skip": startIndex,
//         "limit": limit
//     }
export const fetchUserDashboardHourlyScreenshot = (orgId, dateRange, user, projectId, taskId, startIndex, limit, sort) => (dispatch) => {

    var data = {
        "organizationId": orgId,
        "startTime": dateRange[0],
        "endTime": dateRange[1],
        "users": [user],
        "projectId": projectId,
        "taskId": taskId,
        "skip": startIndex,
        "limit": limit,
        "sort": sort,
        "timezone": getActiveTimezone().timeZoneOffset
    }

    return UserDashboardService.fetchUserDashboardHourlyScreenshot(data).then(
        (data) => {
            if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
                let loadMore = true;
                if (data.screenshots.length >= limit) {
                    loadMore = true;
                } else {
                    loadMore = false;
                }
                dispatch({
                    type: FETCH_USER_DASHBOARD_HOURLY_SCREENSHOT_SUCCESS,
                    payload: { UserDashboardHourlyScreenshot: data, loadMore: loadMore },
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: true },
                });
                return Promise.resolve();
            }
            else if (data === "ERR_NETWORK") {
                dispatch({
                    type: FETCH_USER_DASHBOARD_HOURLY_SCREENSHOT_FAILED,
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: false },
                });
                return Promise.reject();
            }
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: FETCH_USER_DASHBOARD_HOURLY_SCREENSHOT_FAILED,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};

export const fetchMoreUserDashboardHourlyScreenshot = (orgId, dateRange, user, projectId, taskId, startIndex, limit, sort) => (dispatch) => {

    var data = {
        "organizationId": orgId,
        "startTime": dateRange[0],
        "endTime": dateRange[1],
        "users": [user],
        "projectId": projectId,
        "taskId": taskId,
        "skip": startIndex,
        "limit": limit,
        "sort": sort,
        "timezone": getActiveTimezone().timeZoneOffset
    }

    return UserDashboardService.fetchUserDashboardHourlyScreenshot(data).then(
        (data) => {
            if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
                let loadMore = true;
                if (data.screenshots.length >= limit) {
                    loadMore = true;
                } else {
                    loadMore = false;
                }
                dispatch({
                    type: FETCH_MORE_USER_DASHBOARD_HOURLY_SCREENSHOT_SUCCESS,
                    payload: { UserDashboardHourlyScreenshot: data, loadMore: loadMore },
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: true },
                });
                return Promise.resolve();
            }
            else if (data === "ERR_NETWORK") {
                dispatch({
                    type: FETCH_MORE_USER_DASHBOARD_HOURLY_SCREENSHOT_FAILED,
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: false },
                });
                return Promise.reject();
            }
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: FETCH_MORE_USER_DASHBOARD_HOURLY_SCREENSHOT_FAILED,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};

export const fetchSingleUserCatalogDetails = (orgId, dateRange, user, sortBy, scope, ratingType, skip, limit) => (dispatch) => {

    var data = {
        "organizationId": orgId,
        "startTime": toIsoDate(dateRange.dateRange[0], 0),
        "endTime": toIsoDate(dateRange.dateRange[1], 1),
        "users": [user],
        "timetrack_sort": sortBy,
        "ratingScope": scope,
        "ratingType": ratingType,
        "skip": skip,
        "limit": limit,
        "timezone": getActiveTimezone().timeZoneOffset
    }
    return UserDashboardService.fetchSingleUserCatalogDetails(data).then(
        (data) => {
            if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
                let loadMore = true;
                if (data.catalogUsage.length >= limit) {
                    loadMore = true;
                } else {
                    loadMore = false;
                }
                dispatch({
                    type: FETCH_USER_WEB_APP_USAGE_SUCCESS,
                    payload: { userWebAppUsage: data, loadMore: loadMore },
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: true },
                });
                return Promise.resolve();
            }
            else if (data === "ERR_NETWORK") {
                dispatch({
                    type: FETCH_USER_WEB_APP_USAGE_FAILED,
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: false },
                });
                return Promise.reject();
            }
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: FETCH_USER_WEB_APP_USAGE_FAILED,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};
export const fetchMoreSingleUserCatalogDetails = (orgId, dateRange, user, sortBy, scope, ratingType, skip, limit) => (dispatch) => {

    var data = {
        "organizationId": orgId,
        "startTime": toIsoDate(dateRange.dateRange[0], 0),
        "endTime": toIsoDate(dateRange.dateRange[1], 1),
        "users": [user],
        "timetrack_sort": sortBy,
        "ratingScope": scope,
        "ratingType": ratingType,
        "skip": skip,
        "limit": limit,
        "timezone": getActiveTimezone().timeZoneOffset
    }

    return UserDashboardService.fetchSingleUserCatalogDetails(data).then(
        (data) => {
            if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
                let loadMore = true;
                if (data.catalogUsage.length >= limit) {
                    loadMore = true;
                } else {
                    loadMore = false;
                }

                dispatch({
                    type: FETCH_MORE_USER_WEB_APP_USAGE_SUCCESS,
                    payload: { userWebAppUsage: data, loadMore: loadMore },
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: true },
                });
                return Promise.resolve();
            }
            else if (data === "ERR_NETWORK") {
                dispatch({
                    type: FETCH_MORE_USER_WEB_APP_USAGE_FAILED,
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: false },
                });
                return Promise.reject();
            }
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: FETCH_MORE_USER_WEB_APP_USAGE_FAILED,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};

export const fetchSingleUserProjectTask = (orgId, dateRange, user) => (dispatch) => {

    var data = {
        "organizationId": orgId,
        "startTime": toIsoDate(dateRange.dateRange[0], 0),
        "endTime": toIsoDate(dateRange.dateRange[1], 1),
        "user": user,
        "timezone": getActiveTimezone().timeZoneOffset
    }
    return UserDashboardService.fetchSingleUserProjectTaskServ(data).then(
        (data) => {
            if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
                dispatch({
                    type: FETCH_USER_WPRKED_PROJECT_TASK_SUCCESS,
                    payload: { workProjectTask: data },
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: true },
                });
                return Promise.resolve();
            }
            else if (data === "ERR_NETWORK") {
                dispatch({
                    type: FETCH_USER_WPRKED_PROJECT_TASK_FAILED,
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: false },
                });
                return Promise.reject();
            }
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: FETCH_USER_WPRKED_PROJECT_TASK_FAILED,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};

export const fetchCatalogTitle = (orgId, user, dateRange, catalogId, skip, limit) => (dispatch) => {

    var data = {
        "organizationId": orgId,
        "startTime": dateRange.dateRange[0],
        "endTime": dateRange.dateRange[1],
        "users": user,
        "catalogId": catalogId,
        "skip": skip,
        "limit": limit,
        "timezone": getActiveTimezone().timeZoneOffset

    }
    return UserDashboardService.fetchSingleCatalogTitle(data).then(
        (data) => {
            if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
                let loadMore = true;
                if (data.catalog.length >= limit) {
                    loadMore = true;
                } else {
                    loadMore = false;
                }

                dispatch({
                    type: FETCH_CATALOG_TITLE_DETAIL_SUCCESS,
                    payload: { catalogTitle: data.catalog, loadMore: loadMore },
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: true },
                });
                return Promise.resolve();
            }
            else if (data === "ERR_NETWORK") {
                dispatch({
                    type: FETCH_CATALOG_TITLE_DETAIL_FAILED,
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: false },
                });
                return Promise.reject();
            }
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: FETCH_CATALOG_TITLE_DETAIL_FAILED,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};

export const fetchMoreCatalogTitle = (orgId, user, dateRange, catalogId, skip, limit) => (dispatch) => {

    var data = {
        "organizationId": orgId,
        "startTime": dateRange.dateRange[0],
        "endTime": dateRange.dateRange[1],
        "users": user,
        "catalogId": catalogId,
        "skip": skip,
        "limit": limit,
        "timezone": getActiveTimezone().timeZoneOffset

    }
    return UserDashboardService.fetchSingleCatalogTitle(data).then(
        (data) => {
            if (data !== undefined && data !== null && data !== "ERR_NETWORK") {
                let loadMore = true;
                if (data.catalog.length >= limit) {
                    loadMore = true;
                } else {
                    loadMore = false;
                }

                dispatch({
                    type: FETCH_MORE_CATALOG_TITLE_DETAIL_SUCCESS,
                    payload: { moreCatalogTitle: data.catalog, loadMore: loadMore },
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: true },
                });
                return Promise.resolve();
            }
            else if (data === "ERR_NETWORK") {
                dispatch({
                    type: FETCH_MORE_CATALOG_TITLE_DETAIL_FAILED,
                });
                dispatch({
                    type: INTERNET_AVAILABILITY,
                    payload: { internetAvailable: false },
                });
                return Promise.reject();
            }
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: FETCH_MORE_CATALOG_TITLE_DETAIL_FAILED,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};
